import React from 'react';
import styled from 'styled-components';

const StyledPageContent = styled.div`
  min-width: 300px;
  max-width: 1400px;
  margin: 0 auto;
  padding: 32px 16px;
  height: calc(100vh - 64px);
`;

const PageContent = ({ children }) => (
  <StyledPageContent>{children}</StyledPageContent>
);

export default PageContent;

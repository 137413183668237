import { combineReducers } from 'redux';
import { toolsSlice, TOOLS_FEATURE_KEY } from './toolConfig.slice';

export const PLATFORM_FEATURE_KEY = 'platform';

export { selectAllTools, fetchToolsConfig } from './toolConfig.slice';
export { default as requestStatus } from './requestStatus';

export const platformReducers = combineReducers({
  [TOOLS_FEATURE_KEY]: toolsSlice.reducer,
});

export const platformActions = {
  [TOOLS_FEATURE_KEY]: toolsSlice.actions,
};

import { assignIn } from 'lodash';

export const primaryColor = '#257789';
export const secondaryColor = '#ee8b4f';

export const textColor = '#454b49';

export const defaultBorderColor = '#d2d2d2';
export const defaultBorderRadius = '4px';

// export const linkColor = '#257789';
// export const linkHoverColor = '#454b49';
export const linkColor = '#454b49';
export const linkHoverColor = '#257789';

export const inputFontSize = '14px';
export const inputHeight = '40px';
export const inputBorderColor = '#d2d2d2';
export const inputActiveBorderColor = '#257789';

const defaultTheme = {
  body: {
    backgroundColor: '#f4f7fc',
    fontFamily: 'Arial, sans-serif',
  },
  defaultBorderRadius: defaultBorderRadius,
  pageHeader: {
    height: '64px',
    color: '#454b49',
    backgroundColor: '#fff',
    borderBottom: '1px solid #333',
    logo: {
      height: '64px',
    },
    separator: {
      color: '#000',
      height: '32px',
    },
  },
  palette: {
    primaryColor: primaryColor,
    secondaryColor: secondaryColor,
    error: '#fe615a',
    grey: '#ececec',
    darkGrey: '#8d8d8d',
  },
  sectionHeader: {
    color: '#454b49',
  },
  heroBanner: {
    backgroundColor: '#fff',
    gradient: 'linear-gradient(-110deg, transparent 35%, white 70%)',
    details: {
      header: {
        color: '#454b49',
      },
      text: {
        color: '#909090',
        fontSize: '20px',
        fontWeight: '600',
      },
      backgroundColor: 'transparent',
      padding: '0',
    },
  },
  videoGallery: {
    sectionBackgroundColor: '#f4f7fc',
    item: {
      title: {
        fontSize: '14px',
        color: '#454b49',
      },
    },
  },
  featuredResources: {
    padding: '16px',
    vertical: {
      backgroundColor: '#efefef',
      padding: '16px 0',
    },
    header: {
      color: '',
      fontFamily: '',
      fontSize: '',
    },
    item: {
      color: '#000',
      fontSize: '19px',
      separator: '1px solid #666',
    },
  },
  footerBrand: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    width: '82.5%',
    minWidth: '320px',
    maxWidth: '1280px',
    margin: '0 auto',

    mainBio: {
      backgroundColor: 'transparent',
      width: '100%',
      content: {
        width: '100%',
        minWidth: '100%',
        maxWidth: '100%',
        margin: '0 auto',
      },
    },
    brandLogo: {
      backgroundColor: 'transparent',
      alignSelf: 'inherit',
      width: '100%',
      content: {
        width: '100%',
        minWidth: '100%',
        maxWidth: '100%',
        margin: '0 auto',
      },
    },
  },
};

export const themes = {
  default: defaultTheme,
  blue: assignIn({}, defaultTheme, {
    bannerHeader: {
      color: '#005a8b',
    },
    sectionHeader: {
      color: '#0093d1',
    },
  }),
  uhc: assignIn({}, defaultTheme, {
    body: {
      fontFamily: 'UHCSans, Helvetica, Arial, sans-serif',
      color: '#333',
    },
    pageHeader: {
      backgroundColor: '#fff',
      borderBottom: 'none',
      logo: {
        height: '48px',
      },
      separator: {
        color: '#8092bb',
        height: '48px',
      },
    },
    sectionHeader: {
      color: '#002677',
      fontFamily: 'UHCSerifHeadline',
    },
    heroBanner: {
      backgroundColor: '#CBF2F7',
      gradient: '',
      details: {
        backgroundColor: '#fff',
        padding: '24px',
        header: {
          color: '#002677',
          fontFamily: 'UHCSerifHeadline',
        },
        text: {
          color: '#333',
          fontSize: '14px',
          fontWeight: '400',
        },
      },
    },
    videoGallery: {
      sectionBackgroundColor: '#fff',
      item: {
        title: {
          fontSize: '14px',
          color: '#002677',
        },
      },
    },
    featuredResources: {
      padding: '24px',
      vertical: {
        backgroundColor: '#f4f4f4',
        padding: '16px 24px 0',
      },
      sectionBackgroundColor: '#fff',
      header: {
        color: '',
        fontFamily: '',
        fontSize: '',
      },
      item: {
        color: '#196ecf',
        fontSize: '16px',
        separator: 'none',
      },
    },
    footerBrand: {
      backgroundColor: '#E4F7FA',
    },
  }),
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../Button/Button';

const StyledLandingPage = styled.div`
  padding: 24px;
  min-height: 100vh;
  display: grid;
  justify-content: center;
  align-items: center;

  .landing-page-box {
    max-width: 1280px;
    display: grid;
    grid-template-areas:
      'image'
      'details';
    background-color: #fff;

    .image {
      min-height: 200px;
      grid-area: image;
      background-size: cover;
      background-position: center center;
    }

    .details {
      padding: 32px;
      grid-area: details;

      h1 {
        font-size: 24px;
        font-weight: 700;
        color: #353535;
        padding: 24px 0;
      }

      p {
        padding: 16px 0;
        font-size: 12px;
        font-weight: 400;
        color: #636363;
      }

      a {
        color: #636363;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      .logo {
        height: 80px;
        width: auto;
      }
    }
  }

  @media (min-width: 768px) {
    .landing-page-box {
      display: grid;
      grid-template-areas: 'image details';
      grid-template-columns: 5fr 4fr;

      .details {
        padding: 64px 48px;

        .logo {
          margin-top: 16px;
          margin-bottom: 16px;
        }

        h1 {
          padding: 48px 0;
          font-size: 32px;
        }
      }
    }
  }
`;

const LandingPage = ({ logoUrl, backgroundImageUrl, successfulLoginRedirectUrl,  }) => {
  const history = useHistory();

  const handleLoginClick = () => history.push(successfulLoginRedirectUrl);

  return (
    <StyledLandingPage>
      <div className="landing-page-box">
        <div
          className="image"
          style={{
            backgroundImage:
              `url(${backgroundImageUrl})`,
          }}
        />
        <div className="details">
          {logoUrl && <img className="logo" src={logoUrl} alt="logo" />}
          <h1>Create Sales and Marketing Collateral in Minutes</h1>
          <Button fullWidth onClick={handleLoginClick}>
            Log In
          </Button>
          <p>
            Questions? Please contact <a href="#">support@clatter.io</a>
          </p>
        </div>
      </div>
    </StyledLandingPage>
  );
};

export default LandingPage;

import PropTypes from 'prop-types';
import styled from 'styled-components';
import PageHeader from '../PageHeader/PageHeader';
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons';

const StyledImageGallery = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, 160px);
  grid-auto-rows: 160px;
  justify-content: space-between;
  padding: 16px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;

  > div {
    display: grid;
    justify-content: center;
    align-items: center;

    img {
      width: 160px;
      height: 160px;
      object-fit: contain;
    }

    .delete-action {
      cursor: pointer;
      font-size: 16px;
      fill: #666;
      margin-left: 8px;
    }
  }
`;

const ImageGallery = ({
  images,
  imageUrlProperty = 'url',
  titleProperty = 'name',
  handleImageDelete,
}) => {
  const renderImages = () => {
    if (!images?.length) {
      return <p>No images</p>;
    }

    return images.map((image) => (
      <div key={image._id}>
        <img src={image[imageUrlProperty]} alt={image[titleProperty]} />
        <DeleteOutlineIcon
          className="delete-action"
          onClick={handleImageDelete}
          data-item-id={image._id}
        />
      </div>
    ));
  };

  return <StyledImageGallery>{renderImages()}</StyledImageGallery>;
};

PageHeader.propTypes = {
  images: PropTypes.array,
  imageUrlProperty: PropTypes.string,
  titleProperty: PropTypes.string,
  handleImageDelete: PropTypes.func,
};

export default ImageGallery;

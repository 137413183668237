import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DataTable, Dialog, IconButton, Loader } from '@clatter/ui';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {
  deleteBioGroup,
  fetchBioGroups,
  selectAllBioGroups,
} from '../../../store/bio-groups.slice';
import AdminContentHead from '../AdminContentHead';
import BioGroupForm from './BioGroupForm';
import { formatDate } from '../../../helpers';

const bioGroupColumns = [
  {
    label: 'Group name',
    name: 'groupName',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Date added',
    name: 'createdAt',
    customRender: (row) => formatDate(row.createdAt, true),
    align: 'left',
    sortable: true,
  },
];

const BioGroups = () => {
  const dispatch = useDispatch();
  const [editableItem, setEditableItem] = useState(null);

  useEffect(() => {
    dispatch(fetchBioGroups());
  }, [dispatch]);

  const bioGroups = useSelector(selectAllBioGroups);

  const loading = useSelector(
    (state) => state.bioGroups.loadingStatus === 'loading',
  );

  const handleEditClick = (event) => {
    const clickedItemId = event.currentTarget?.dataset?.id;

    setEditableItem(
      (clickedItemId && bioGroups.find((bio) => bio.id === clickedItemId)) ||
        {},
    );
  };

  const handleDismissEdit = () => {
    setEditableItem(null);
  };

  const handleDeleteClick = (event) => {
    if (window.confirm('Are you sure you want to remove this group?')) {
      dispatch(deleteBioGroup(event.currentTarget.dataset.id));
    }
  };

  const isEdit = editableItem && 'groupName' in editableItem;

  return (
    <>
      {loading && <Loader />}
      <AdminContentHead>
        <h1>Bio groups</h1>
        <Button onClick={handleEditClick}>Add group</Button>
      </AdminContentHead>
      <div>
        <DataTable
          autofit
          columns={bioGroupColumns}
          defaultSortField="groupName"
          filterColumns={['groupName']}
          name="adminBioGroups"
          rows={bioGroups}
          renderActionColumn={(row) => (
            <>
              <IconButton data-id={row.id} onClick={handleEditClick}>
                <EditOutlinedIcon />
              </IconButton>
              <IconButton data-id={row.id} onClick={handleDeleteClick}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </>
          )}
        />
      </div>
      <Dialog
        title={isEdit ? 'Edit bio group' : 'Add bio group'}
        onCloseDialog={handleDismissEdit}
        open={!!editableItem}
      >
        <BioGroupForm
          isEdit={isEdit}
          editableItem={editableItem}
          onSuccess={handleDismissEdit}
        />
      </Dialog>
    </>
  );
};

export default BioGroups;

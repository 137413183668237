import React from 'react';
import styled from 'styled-components';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';

const StyledPasswordValidatorStatus = styled.div`
  .validator-item {
    display: flex;
    align-items: center;

    .validator-item-icon {
      font-size: 20px;

      &.valid {
        fill: green;
      }

      &.invalid {
        fill: red;
      }
    }

    .validator-item-text {
      line-height: 20px;
      padding: 8px;
    }
  }
`;

const PasswordValidatorStatus = ({ password = '', validators = [] }) => {
  const validate = ({ pattern, ignoreCase }) =>
    password && new RegExp(pattern, ignoreCase ? 'i' : '').test(password);

  return (
    <StyledPasswordValidatorStatus>
      {validators.map((validator) => (
        <div className="validator-item" key={validator.text}>
          {validate(validator) ? (
            <CheckCircleOutlineIcon className="validator-item-icon valid" />
          ) : (
            <CancelIcon className="validator-item-icon invalid" />
          )}
          <div className="validator-item-text">{validator.text}</div>
        </div>
      ))}
    </StyledPasswordValidatorStatus>
  );
};

export default PasswordValidatorStatus;

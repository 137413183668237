import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { PageHeader, PageContent } from '@clatter/ui';
import { hasRole, selectAllTools } from '@clatter/platform';
import {
  SupervisorAccount as SupervisorAccountIcon,
  VerticalSplit as VerticalSplitIcon,
} from '@material-ui/icons';
import logoUrl from '../../../assets/Logo-OptumRx-PNG-e1590785075499.png';
import { userRolesMap } from '../../constants';

const getMenuItemsForUser = (user) => {
  if (hasRole(userRolesMap.admin, user)) {
    return [
      {
        label: 'Admin',
        url: '/admin',
        icon: SupervisorAccountIcon,
      },
      {
        label: 'Microsites',
        url: '/microsites',
        icon: VerticalSplitIcon,
      },
    ];
  }

  return [
    {
      label: 'Microsites',
      url: '/microsites',
      icon: VerticalSplitIcon,
    },
  ];
};

const Page = ({ children }) => {
  const { user } = useAuth0();
  const [menuItems, setMenuItems] = useState([]);
  const toolsList = useSelector(selectAllTools).map((tool) => ({
    ...tool,
    label: tool.name,
  }));

  useEffect(() => {
    setMenuItems(getMenuItemsForUser(user));
  }, [user]);

  return (
    <>
      <PageHeader
        logoUrl={logoUrl}
        homeUrl="/microsites"
        menuItems={menuItems}
        toolItems={toolsList}
      />
      <PageContent>{children}</PageContent>
    </>
  );
};

export default Page;

import axios from 'axios';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
export const BIOS_FEATURE_KEY = 'bios';
export const biosAdapter = createEntityAdapter();
const baseUrl = `${process.env.NX_CMS_HOST}/bios`;

export const fetchBios = createAsyncThunk(
  `${BIOS_FEATURE_KEY}/fetch`,
  async () => {
    const response = await axios.get(`${baseUrl}?_sort=displayName:ASC`);

    return Promise.resolve(response.data);
  },
);

export const createBio = createAsyncThunk(
  `${BIOS_FEATURE_KEY}/create`,
  async ({
    description,
    file,
    firstName,
    group,
    lastName,
    title,
    email,
    phone,
  }) => {
    const response = await axios.post(baseUrl, {
      description,
      displayName: `${firstName} ${lastName}`,
      firstName,
      group,
      lastName,
      title,
      email,
      phone,
      description,
    });
    const newUpload = new FormData();
    newUpload.append('files', file);
    newUpload.append('ref', 'bios');
    newUpload.append('refId', response.data.id);
    newUpload.append('field', 'profilePicture');

    const uploadResponse = await axios.post(
      `${process.env.NX_CMS_HOST}/upload`,
      newUpload,
    );

    const newBio = {
      ...response.data,
      profilePicture: {
        ...uploadResponse.data[0],
      },
    };

    return Promise.resolve(newBio);
  },
);

export const updateBio = createAsyncThunk(
  `${BIOS_FEATURE_KEY}/update`,
  async ({
    id,
    description,
    file,
    firstName,
    group,
    lastName,
    title,
    email,
    phone,
  }) => {
    const response = await axios.put(`${baseUrl}/${id}`, {
      description,
      displayName: `${firstName} ${lastName}`,
      firstName,
      group,
      lastName,
      title,
      email,
      phone,
      description,
    });

    const updatedBio = {
      ...response.data,
    };

    if (file) {
      const newUpload = new FormData();
      newUpload.append('files', file);
      newUpload.append('ref', 'bios');
      newUpload.append('refId', response.data.id);
      newUpload.append('field', 'profilePicture');

      const uploadResponse = await axios.post(
        `${process.env.NX_CMS_HOST}/upload`,
        newUpload,
      );

      updatedBio.profilePicture = {
        ...uploadResponse.data[0],
      };
    }

    return Promise.resolve(updatedBio);
  },
);

export const deleteBio = createAsyncThunk(
  `${BIOS_FEATURE_KEY}/delete`,
  async (id) => {
    const response = await axios.delete(`${baseUrl}/${id}`);

    return Promise.resolve(response.data);
  },
);

export const initialBiosState = biosAdapter.getInitialState({
  loadingStatus: 'loading',
  error: null,
});

export const biosSlice = createSlice({
  name: BIOS_FEATURE_KEY,
  initialState: initialBiosState,
  reducers: {
    add: biosAdapter.addOne,
    remove: biosAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBios.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchBios.fulfilled, (state, action) => {
        biosAdapter.setAll(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchBios.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(createBio.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(createBio.fulfilled, (state, action) => {
        biosAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(createBio.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(updateBio.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(updateBio.fulfilled, (state, action) => {
        biosAdapter.removeOne(state, action.payload.id);
        biosAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(updateBio.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(deleteBio.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(deleteBio.fulfilled, (state, action) => {
        biosAdapter.removeOne(state, action.payload.id);
        state.loadingStatus = 'loaded';
      })
      .addCase(deleteBio.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      });
  },
});

export const biosReducer = biosSlice.reducer;
export const biosActions = biosSlice.actions;

const { selectAll, selectEntities } = biosAdapter.getSelectors();
export const getBiosState = (rootState) => rootState[BIOS_FEATURE_KEY];
export const selectAllBios = createSelector(getBiosState, selectAll);
export const selectBiosEntities = createSelector(getBiosState, selectEntities);

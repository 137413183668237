import React, { useState } from 'react';
import styled from 'styled-components';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import {
  List,
  ListItem,
  ListItemActions,
  ListItemIcon,
  ListItemText,
} from '@clatter/ui';
import { IconButton, TextControl } from '@clatter/ui';
import { isDev } from '../../helpers';

const StyledListSelector = styled.div`
  .resource-picker-grid {
    display: grid;
    column-gap: 16px;
    grid-template-areas:
      'pickedResourcesHeader'
      'pickedResourcesContent'
      'availableResourcesHeader'
      'availableResourcesContent'
      'addResourceForm';
  }

  .available-resources-header {
    grid-area: availableResourcesHeader;
  }

  .available-resources-content {
    grid-area: availableResourcesContent;
  }

  .picked-resources-header {
    grid-area: pickedResourcesHeader;
  }

  .picked-resources-content {
    grid-area: pickedResourcesContent;
  }

  .available-resources-content,
  .picked-resources-content {
    max-height: 200px;
    overflow-y: auto;
  }

  .new-resource-form {
    grid-area: addResourceForm;
  }

  @media (min-width: 600px) {
    .resource-picker-grid {
      display: grid;
      column-gap: 32px;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'availableResourcesHeader pickedResourcesHeader'
        'availableResourcesContent pickedResourcesContent'
        'addResourceForm addResourceForm';
    }
  }

  .no-resources {
    padding: 16px;
  }
`;

const ListSelector = ({
  disabled,
  itemIcon: ItemIcon,
  onAdd,
  onDelete,
  onItemChange,
  options,
  value,
  assetDisplayName,
}) => {
  const [editedItem, setEditedItem] = useState(null);

  if (!options || !Object.keys(options).length) {
    return null;
  }

  const toggleEditedItem = (event) => {
    setEditedItem(value[event.currentTarget.dataset.index]);
  };

  const handleAddClick = (event) => {
    onAdd(event.currentTarget.dataset.template);
  };

  const handleDeleteClick = (event) => {
    onDelete(value[event.currentTarget.dataset.index]);
  };

  const handleNameChange = (event) => {
    const nextEditedItem = {
      ...editedItem,
      title: event.target.value,
    };

    setEditedItem(nextEditedItem);
  };

  const handleNameChangeConfim = () => {
    onItemChange(editedItem);
    setEditedItem(null);
  };

  const development = isDev();

  const renderOptions = () => (
    <List>
      {Object.keys(options)
        .filter(
          (templateName) =>
            development || (!development && !options[templateName].hidden),
        )
        .map((templateName) => (
          <ListItem
            disabled={disabled}
            hoverable
            tag="button"
            type="button"
            key={templateName}
            data-template={templateName}
            onClick={handleAddClick}
          >
            <ListItemIcon>
              <ItemIcon />
            </ListItemIcon>
            <ListItemText text={options[templateName].displayName} />
          </ListItem>
        ))}
    </List>
  );

  const renderValue = (item, index) => {
    if (item.id === editedItem?.id) {
      return (
        <>
          <TextControl value={editedItem.title} onChange={handleNameChange} />
          <ListItemActions>
            <IconButton onClick={handleNameChangeConfim} tooltip="Confirm">
              <CheckOutlinedIcon />
            </IconButton>
            <IconButton onClick={toggleEditedItem} tooltip="Dismiss">
              <CloseOutlinedIcon />
            </IconButton>
          </ListItemActions>
        </>
      );
    }

    return (
      <>
        <ListItemIcon>
          <ItemIcon />
        </ListItemIcon>
        <ListItemText text={item.title} />
        <ListItemActions>
          <IconButton
            data-index={index}
            onClick={toggleEditedItem}
            tooltip="Edit"
          >
            <EditOutlinedIcon />
          </IconButton>
          <IconButton
            data-index={index}
            onClick={handleDeleteClick}
            tooltip="Delete"
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </ListItemActions>
      </>
    );
  };

  const renderValues = () => {
    if (!value || !value.length) {
      return (
        <div className="no-resources">
          Choose a page template on the left to get started.
        </div>
      );
    }

    return (
      <List>
        {value.map((item, index) => (
          <ListItem key={`${item.id}-${index}`}>
            {renderValue(item, index)}
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <StyledListSelector>
      <div className="resource-picker-grid">
        <div className="available-resources-header">
          <h4>Available {assetDisplayName}</h4>
        </div>
        <div className="available-resources-content">{renderOptions()}</div>
        <div className="picked-resources-header">
          <h4>Pages</h4>
        </div>
        <div className="picked-resources-content">{renderValues()}</div>
      </div>
    </StyledListSelector>
  );
};

export default ListSelector;

import React from 'react';
import { Route, Switch, NavLink, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined';
import PanoramaOutlinedIcon from '@material-ui/icons/PanoramaOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { List, ListItem, ListItemIcon, ListItemText } from '@clatter/ui';
import Bios from './Bios/Bios';
import BioGroups from './BioGroups/BioGroups';
import Resources from './Resources/Resources';
import ResourceCategories from './ResourceCategories/ResourceCategories';
import HeroImages from './HeroImages/HeroImages';
import VideoCategories from './VideoCategories/VideoCategories';
import VideoEmbeds from './VideoEmbeds/VideoEmbeds';
import MicrositesSettings from './MicrositesSettings/MicrositesSettings';

const StyledAdmin = styled.div`
  display: grid;
  grid-template-areas: 'sidebar content';
  grid-template-columns: 250px 1fr;
  grid-auto-rows: auto;
  align-items: start;
  gap: 24px;
  height: 100%;

  .admin-nav {
    position: sticky;
    top: 80px;
  }

  .admin-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    > *:first-child + * {
      flex: 1;
      height: calc(100% - 82px);
    }
  }
`;

const Admin = () => {
  const match = useRouteMatch();

  return (
    <StyledAdmin>
      <div className="admin-nav">
        <List>
          <ListItem
            hoverable
            component={NavLink}
            componentProps={{ to: `${match.path}/bios` }}
          >
            <ListItemIcon>
              <AccountBoxOutlinedIcon />
            </ListItemIcon>
            <ListItemText text="Bios" />
          </ListItem>
          <ListItem
            hoverable
            component={NavLink}
            componentProps={{ to: `${match.path}/bio-groups` }}
          >
            <ListItemIcon>
              <ListOutlinedIcon />
            </ListItemIcon>
            <ListItemText text="Bio groups" />
          </ListItem>
          <ListItem
            hoverable
            component={NavLink}
            componentProps={{ to: `${match.path}/resources` }}
          >
            <ListItemIcon>
              <DescriptionOutlinedIcon />
            </ListItemIcon>
            <ListItemText text="Resources" />
          </ListItem>
          <ListItem
            hoverable
            component={NavLink}
            componentProps={{ to: `${match.path}/resource-categories` }}
          >
            <ListItemIcon>
              <ListOutlinedIcon />
            </ListItemIcon>
            <ListItemText text="Resource categories" />
          </ListItem>
          <ListItem
            hoverable
            component={NavLink}
            componentProps={{ to: `${match.path}/videos` }}
          >
            <ListItemIcon>
              <OndemandVideoOutlinedIcon />
            </ListItemIcon>
            <ListItemText text="Videos" />
          </ListItem>
          <ListItem
            hoverable
            component={NavLink}
            componentProps={{ to: `${match.path}/video-categories` }}
          >
            <ListItemIcon>
              <ListOutlinedIcon />
            </ListItemIcon>
            <ListItemText text="Video categories" />
          </ListItem>
          <ListItem
            hoverable
            component={NavLink}
            componentProps={{ to: `${match.path}/hero-images` }}
          >
            <ListItemIcon>
              <PanoramaOutlinedIcon />
            </ListItemIcon>
            <ListItemText text="Hero images" />
          </ListItem>
        </List>
      </div>
      <div className="admin-content">
        <Switch>
          <Route path={match.path} exact>
            <MicrositesSettings />
          </Route>
          <Route path={`${match.path}/bios`}>
            <Bios />
          </Route>
          <Route path={`${match.path}/bio-groups`}>
            <BioGroups />
          </Route>
          <Route path={`${match.path}/resources`}>
            <Resources />
          </Route>
          <Route path={`${match.path}/resource-categories`}>
            <ResourceCategories />
          </Route>
          <Route path={`${match.path}/videos`}>
            <VideoEmbeds />
          </Route>
          <Route path={`${match.path}/video-categories`}>
            <VideoCategories />
          </Route>
          <Route path={`${match.path}/hero-images`}>
            <HeroImages />
          </Route>
        </Switch>
      </div>
    </StyledAdmin>
  );
};

export default Admin;

import axios from 'axios';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
export const BLOCKTEMPLATES_FEATURE_KEY = 'blocktemplates';
export const BlockTemplatesAdapter = createEntityAdapter();
/**
 * Export an effect using createAsyncThunk from
 * the Redux Toolkit: https://redux-toolkit.js.org/api/createAsyncThunk
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(fetchBlockTemplates())
 * }, [dispatch]);
 * ```
 */
export const fetchBlockTemplates = createAsyncThunk(
  `blockTemplates/fetchStatus`,
  async (_, thunkAPI) => {
    let cms_host = process.env.NX_CMS_HOST;
    let response = await axios.get(`${cms_host}/block-templates`);
    // console.debug( { response });

    return Promise.resolve(response.data);
  },
);

export const initialBlockTemplatesState = BlockTemplatesAdapter.getInitialState(
  {
    loadingStatus: 'loading',
    error: null,
  },
);
export const BlockTemplatesSlice = createSlice({
  name: BLOCKTEMPLATES_FEATURE_KEY,
  initialState: initialBlockTemplatesState,
  reducers: {
    add: BlockTemplatesAdapter.addOne,
    remove: BlockTemplatesAdapter.removeOne,
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlockTemplates.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchBlockTemplates.fulfilled, (state, action) => {
        BlockTemplatesAdapter.setAll(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchBlockTemplates.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      });
  },
});
/*
 * Export reducer for store configuration.
 */
export const BlockTemplatesReducer = BlockTemplatesSlice.reducer;
/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(BlockTemplatesActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const BlockTemplatesActions = BlockTemplatesSlice.actions;
/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllBlockTemplates);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = BlockTemplatesAdapter.getSelectors();
export const getBlockTemplatesState = (rootState) =>
  rootState[BLOCKTEMPLATES_FEATURE_KEY];
export const selectAllBlockTemplates = createSelector(
  getBlockTemplatesState,
  selectAll,
);
export const selectBlockTemplatesEntities = createSelector(
  getBlockTemplatesState,
  selectEntities,
);

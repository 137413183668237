import React, { useState, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Loader } from '@clatter/ui';

export const withRoleBasedRedirect = (Component, options) => (props) => {
  const history = useHistory();
  const { getIdTokenClaims } = useAuth0();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    async function getRoles() {
      const claims = await getIdTokenClaims();
      return claims[`${process.env.NX_AUTH0_NAMESPACE}/roles`] || [];
    }
    async function hasAnyRole(rolesToCheck) {
      const roles = await getRoles();
      const isAuthorized = rolesToCheck.some((role) => roles.includes(role));

      if (isAuthorized) {
        return setIsAuthorized(true);
      }

      return history.push('/');
    }
    hasAnyRole(
      Array.isArray(options.accessRoles)
        ? options.accessRoles
        : [options.accessRoles],
    );
  }, [getIdTokenClaims, history]);

  return isAuthorized ? <Component {...props} /> : <Loader />;
};

const ProtectedRoute = ({
  component,
  render,
  children,
  accessRoles,
  ...rest
}) => {
  if (accessRoles) {
    const renderComponent = component
      ? component
      : () => (
          <Route render={render} {...rest}>
            {children}
          </Route>
        );
    return (
      <Route
        component={withAuthenticationRequired(
          withRoleBasedRedirect(renderComponent, { accessRoles }),
          {
            onRedirecting: () => <Loader />,
          },
        )}
        {...rest}
      />
    );
  }

  if (component) {
    // this case is the 'default' in the auth0 documentation, but we don't use it
    // so it's not tested...
    return (
      <Route
        component={withAuthenticationRequired(component, {
          onRedirecting: () => <Loader />,
        })}
        {...rest}
      />
    );
  }

  if (render) {
    const _component = () => (
      <Route render={render} {...rest}>
        {children}
      </Route>
    );
    return (
      <Route
        component={withAuthenticationRequired(_component, {
          onRedirecting: () => <Loader />,
        })}
        {...rest}
      />
    );
  }
  // if we're here, then neither of component-specified or render prop is used
  const _component = () => <Route {...rest}>{children}</Route>;
  return (
    <Route
      component={withAuthenticationRequired(_component, {
        onRedirecting: () => <Loader />,
      })}
      {...rest}
    />
  );
};

export default ProtectedRoute;

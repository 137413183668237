import axios from 'axios';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { createMessage, messagesActions } from './messages.slice';
import { messageTypes } from '@clatter/ui';

export const PAGES_FEATURE_KEY = 'pages';
export const pagesAdapter = createEntityAdapter();
const baseUrl = `${process.env.NX_CMS_HOST}/pages`;

export const fetchPages = createAsyncThunk(
  `${PAGES_FEATURE_KEY}/fetch`,
  async () => {
    const response = await axios.get(`${baseUrl}?_limit=-1`);

    return Promise.resolve(response.data);
  },
);

export const addPage = createAsyncThunk(
  `${PAGES_FEATURE_KEY}/add`,
  async (page) => {
    const response = await axios.post(baseUrl, page);

    return Promise.resolve(response.data);
  },
);

// @todo resolve the pluralization conventions
export const updatePage = createAsyncThunk(
  `${PAGES_FEATURE_KEY}/update`,
  async (page, { dispatch }) => {
    const response = await axios.put(`${baseUrl}/${page.id}`, page);

    // dispatch(
    //   messagesActions.setMessage({
    //     message: createMessage(
    //       messageTypes.success,
    //       'Page has been saved.',
    //       'pageSave',
    //     ),
    //   }),
    // );

    return Promise.resolve(response.data);
  },
);

export const initialPagesState = pagesAdapter.getInitialState({
  loadingStatus: 'loading',
  error: null,
});

export const pagesSlice = createSlice({
  name: PAGES_FEATURE_KEY,
  initialState: initialPagesState,
  reducers: {
    add: pagesAdapter.addOne,
    remove: pagesAdapter.removeOne,
    update: pagesAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPages.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchPages.fulfilled, (state, action) => {
        pagesAdapter.setAll(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchPages.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(addPage.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(addPage.fulfilled, (state, action) => {
        pagesAdapter.addOne(state, action);
        state.loadingStatus = 'loaded';
      })
      .addCase(updatePage.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(updatePage.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        pagesAdapter.updateOne(state, { id, changes });
        state.loadingStatus = 'loaded';
      });
  },
});

export const pagesReducer = pagesSlice.reducer;
export const pagesActions = pagesSlice.actions;

const { selectAll, selectEntities } = pagesAdapter.getSelectors();

export const getPagesState = (rootState) => rootState[PAGES_FEATURE_KEY];

export const selectAllPages = createSelector(getPagesState, selectAll);

export const selectPagesEntities = createSelector(
  getPagesState,
  selectEntities,
);

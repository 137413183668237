import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = (appState) =>
    history.push(appState?.returnTo || window.location.pathname);

  return (
    <Auth0Provider
      domain={process.env.NX_AUTH0_DOMAIN}
      clientId={process.env.NX_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      audience={process.env.NX_AUTH0_AUDIENCE}
      scope="openid profile email"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;

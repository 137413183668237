import React from 'react';
import styled from 'styled-components';
import { Dialog } from '@clatter/ui';
import CustomerBio from './CustomerBio';

const StyledBioDetailsDialog = styled.div`
  width: 480px;
`;

const BioDetailsDialog = ({ details, onCloseDialog, open }) => {
  if (!open) {
    return null;
  }

  return (
    <Dialog
      onCloseDialog={onCloseDialog}
      open={open}
      title={details.displayName}
    >
      <StyledBioDetailsDialog>
        <CustomerBio
          image={details.profilePicture?.formats?.thumbnail?.url}
          name={details.displayName}
          title={details.title}
          email={details.email}
          phone={details.phone}
          description={details.description}
        />
      </StyledBioDetailsDialog>
    </Dialog>
  );
};

export default BioDetailsDialog;

import React, { useState } from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import {
  Button,
  FormButtons,
  FormControl,
  IconButton,
  TextControl,
} from '@clatter/ui';
import FileUpload from '../../FileUpload/FileUpload';
import {
  createHeroImage,
  updateHeroImage,
} from '../../../store/hero-images.slice';

const StyledHeroImageForm = styled.form`
  width: 480px;

  .hero-image {
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }
  }
`;

const HeroImageForm = ({ editableItem, isEdit, onSuccess }) => {
  const dispatch = useDispatch();
  const [showUploadForm, setShowUploadForm] = useState(false);
  const {
    control,
    formState: { errors },
    formState,
    handleSubmit,
    register,
  } = useForm({
    mode: 'onChange',
    defaultValues: editableItem || {},
  });

  const onSubmit = async (formData) => {
    dispatch(
      isEdit
        ? updateHeroImage({ id: editableItem.id, formData })
        : createHeroImage(formData),
    ).then(() => {
      onSuccess();
    });
  };

  const handleShowUploadForm = () => {
    setShowUploadForm(true);
  };

  return (
    <StyledHeroImageForm onSubmit={handleSubmit(onSubmit)}>
      {!showUploadForm && editableItem?.asset?.url ? (
        <div className="hero-image">
          <img src={editableItem.asset.url} alt="Preview" />
          <IconButton className="" onClick={handleShowUploadForm}>
            <CloseOutlinedIcon />
          </IconButton>
        </div>
      ) : (
        <FormControl error={errors.file}>
          <Controller
            name="file"
            rules={{ required: { value: true, message: 'Field is required' } }}
            render={({ field: { onChange, value } }) => (
              <FileUpload onChange={onChange} value={value} />
            )}
            control={control}
            defaultValue={null}
          />
        </FormControl>
      )}
      <FormControl label="Description" error={errors.description}>
        <TextControl
          {...register('description', {
            required: { value: true, message: 'Field is required' },
          })}
          placeholder="Enter description"
        />
      </FormControl>
      <FormButtons>
        <Button disabled={!formState.isValid} type="submit">
          Save
        </Button>
      </FormButtons>
    </StyledHeroImageForm>
  );
};

export default HeroImageForm;

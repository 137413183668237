import React from 'react';
import styled from 'styled-components';

const StyledSectionHeader = styled.h1`
  padding: 0 0 24px;
  font-size: 26px;
  font-weight: 700;
  margin: 0;
  color: ${({ theme }) => theme?.sectionHeader?.color};
  font-family: ${({ theme }) => theme?.sectionHeader?.fontFamily};

  &.no-padding {
    padding: 0;
  }
`;

const SectionHeader = ({ as = 'h1', children, noPadding = false }) => (
  <StyledSectionHeader as={as} className={`${noPadding ? 'no-padding' : ''}`}>
    {children}
  </StyledSectionHeader>
);

export default SectionHeader;

import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const ToggleButtonsGroup = ({
  size = 'small',
  value,
  onChange,
  options = [],
}) => {
  return (
    <ToggleButtonGroup
      size={size}
      value={value}
      exclusive
      onChange={(event, newValue) => {
        if (newValue !== null) {
          return onChange(event, newValue);
        }
      }}
    >
      {options.map((option) => (
        <ToggleButton key={option.value} value={option.value}>
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

ToggleButtonsGroup.propTypes = {
  size: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
};

export default ToggleButtonsGroup;

import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { compareIdentifiers } from '../../helpers';
import { secondaryColor } from '@clatter/ui';

const StyledSingleVideoPicker = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    ${(props) => `${props.itemSize.width}px`}
  );
  gap: 16px;
  padding-bottom: 16px;
  overflow-y: auto;
  max-height: 316px;

  .video-picker-item {
    cursor: pointer;
    position: relative;
    background-color: #fff;
    position: relative;

    iframe {
      width: 100%;
      height: 100%;
      display: block;
    }

    .video-picker-video-title {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      line-height: 32px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 16px;
      background-color: rgba(255, 255, 255, 0.9);
      color: #262626;
    }

    .video-picker-item-action {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      cursor: pointer;
      border: 4px solid transparent;
    }

    &.video-picker-item-selected {
      .video-picker-item-action {
        border-color: ${secondaryColor};
      }
    }
  }
`;

const SingleVideoPicker = ({
  options = [],
  onChange = () => {},
  value,
  itemWidth = 200,
  itemAspectRatio = '16/9',
}) => {
  const [itemSize, setVideoSize] = useState(null);
  useLayoutEffect(() => {
    const [widthRatio, heightRatio] = itemAspectRatio.split('/');
    const itemHeight = parseInt(
      (itemWidth / Number(widthRatio)) * Number(heightRatio),
    );
    setVideoSize({ width: itemWidth, height: itemHeight });
  }, [itemWidth, itemAspectRatio]);

  const isItemSelected = (itemId) => {
    if (!value) {
      return false;
    }

    if (compareIdentifiers(value.id, itemId)) {
      return true;
    }
  };

  const handleVideoClick = (event) => {
    const clickedItemId = event.currentTarget.dataset.id;
    let nextValue;

    if (value && compareIdentifiers(value.id, clickedItemId)) {
      nextValue = null;
    } else {
      nextValue = options.find((item) =>
        compareIdentifiers(item.id, clickedItemId),
      );
    }

    onChange(nextValue);
  };

  const renderVideos = () => {
    if (!options || !options.length) {
      return <p>No options to pick</p>;
    }

    return options.map((item) => (
      <div
        key={item.id}
        className={`video-picker-item ${
          isItemSelected(item.id) ? 'video-picker-item-selected' : ''
        }`}
      >
        <iframe
          width="640"
          height="360"
          src={`${item.link}?controls=0&transparent=0`}
          frameBorder="0"
        />
        <div className="video-picker-video-title">{item.title}</div>
        <div
          data-id={item.id}
          onClick={handleVideoClick}
          className="video-picker-item-action"
        />
      </div>
    ));
  };

  if (!itemSize) {
    return null;
  }

  return (
    <StyledSingleVideoPicker itemSize={itemSize}>
      {renderVideos()}
    </StyledSingleVideoPicker>
  );
};

export default SingleVideoPicker;

import React, { useState } from 'react';
import styled from 'styled-components';

const StyledContentExpander = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  button {
    cursor: pointer;
    padding: 12px 24px;
    color: #fff;
    background-color: #316bbe;
    border: 1px solid #316bbe;
    border-radius: 50px;
  }
`;

const ContentExpander = ({
  children,
  items = [],
  limit = 0,
  moreText = 'More',
  lessText = 'Less',
}) => {
  const [showAll, setShowAll] = useState(limit ? false : true);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <>
      {children(showAll ? items : items.slice(0, limit))}
      {!!limit && items && Array.isArray(items) && items.length > limit && (
        <StyledContentExpander>
          <button type="button" onClick={toggleShowAll}>
            {showAll ? lessText : moreText}
          </button>
        </StyledContentExpander>
      )}
    </>
  );
};

export default ContentExpander;

import React from 'react';
import styled from 'styled-components';
import FooterBrand from './FooterBrand';
import Section from './Section';

const StyledFooter = styled.footer`
  display: initial;

  .notice {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 20px 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;

    a {
      margin: 0 8px;
    }
  }

  .brand-footer {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .copyrights {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    background: #000;
    color: #fff;
    padding: 8px 0;
    line-height: 20px;

    a {
      margin: 0 8px;
      color: #fff;
    }
  }
`;

const Footer = ({ bio, logo }) => (
  <StyledFooter>
    {logo && (
      <div className="brand-footer">
        <FooterBrand bio={bio} logo={logo} />
      </div>
    )}
    <Section backgroundColor="#fff" noPadding>
      <div className="notice">
        <a
          href="https://www.optum.com/nondiscrimination.html"
          target="_blank"
          rel="noreferrer"
          title="Language Assistance / Non-Discrimination Notice"
        >
          Language Assistance / Non-Discrimination Notice
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.optum.com/nondiscrimination.html"
          title="Assistencia de ldiomas / Aviso de no Discriminacion"
        >
          Assistencia de ldiomas / Aviso de no Discriminacion
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.optum.com/nondiscrimination.html"
          title="語言協助 / 不歧視通知"
        >
          語言協助 / 不歧視通知
        </a>
      </div>
    </Section>
    <Section backgroundColor="#000" noPadding>
      <div className="copyrights">
        <a
          href="https://www.optum.com/"
          title="&#169; Optum, Inc. All rights reserved"
        >
          &#169; Optum, Inc. All rights reserved.
        </a>
        <a href="https://www.optum.com/terms-of-use.html" title="Terms Of Use">
          TERMS OF USE
        </a>
        <a
          href="https://www.optum.com/accessibility1.html"
          title="Accessibility"
        >
          ACCESSIBILITY
        </a>
        <a href="https://www.optum.com/privacy-policy.html" title="Privacy">
          PRIVACY
        </a>
      </div>
    </Section>
  </StyledFooter>
);

export default Footer;

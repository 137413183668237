import axios from 'axios';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
export const COBRANDLOGOS_FEATURE_KEY = 'cobrandLogos';
export const cobrandLogosAdapter = createEntityAdapter();
const apiBaseUrl = process.env.NX_CMS_HOST;

export const fetchCobrandLogos = createAsyncThunk(
  `${COBRANDLOGOS_FEATURE_KEY}/fetch`,
  async (email = '', thunkAPI) => {
    const url =
      email === ''
        ? `${apiBaseUrl}/cobrand-logos?_sort=updatedAt:DESC`
        : `${apiBaseUrl}/cobrand-logos?_where%5Bowner.email%5D=${email}`;

    const response = await axios.get(url);

    return Promise.resolve(response.data);
  },
);

// @todo decide a convention for 'helpers' like this, and factor it out
const getUserByEmail = async (email) => {
  const response = await axios.get(
    `${apiBaseUrl}/users?_where[email]=${email}`,
  );
  const user = response.data[0];
  return user;
};

export const addCobrandLogo = createAsyncThunk(
  `${COBRANDLOGOS_FEATURE_KEY}/add`,
  async ({ company, file, email }, thunkAPI) => {
    const response = await axios.post(`${apiBaseUrl}/cobrand-logos`, {
      // owner: '608a512680ce4b7206eb8bf4',
      owner: email === '' ? null : (await getUserByEmail(email)).id,
      company,
    });

    const newUpload = new FormData();
    newUpload.append('files', file);
    newUpload.append('ref', 'cobrand-logos');
    newUpload.append('refId', response.data.id);
    newUpload.append('field', 'asset');

    const uploadResponse = await axios.post(`${apiBaseUrl}/upload`, newUpload);

    const newLogo = {
      ...response.data,
      asset: {
        ...uploadResponse.data[0],
      },
    };

    return Promise.resolve(newLogo);
  },
);

export const deleteCobrandLogo = createAsyncThunk(
  `${COBRANDLOGOS_FEATURE_KEY}/delete`,
  async (logoId) => {
    const response = await axios.delete(
      `${apiBaseUrl}/cobrand-logos/${logoId}`,
    );

    return Promise.resolve(response.data);
  },
);

export const initialCobrandLogosState = cobrandLogosAdapter.getInitialState({
  loadingStatus: 'loading',
  error: null,
});

export const cobrandLogosSlice = createSlice({
  name: COBRANDLOGOS_FEATURE_KEY,
  initialState: initialCobrandLogosState,
  reducers: {
    add: cobrandLogosAdapter.addOne,
    remove: cobrandLogosAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCobrandLogos.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchCobrandLogos.fulfilled, (state, action) => {
        cobrandLogosAdapter.setAll(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchCobrandLogos.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(addCobrandLogo.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(addCobrandLogo.fulfilled, (state, action) => {
        cobrandLogosAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(deleteCobrandLogo.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(deleteCobrandLogo.fulfilled, (state, action) => {
        cobrandLogosAdapter.removeOne(state, action.payload.id);
        state.loadingStatus = 'loaded';
      });
  },
});

export const cobrandLogosReducer = cobrandLogosSlice.reducer;
export const cobrandLogosActions = cobrandLogosSlice.actions;

const { selectAll, selectEntities } = cobrandLogosAdapter.getSelectors();

export const getCobrandLogosState = (rootState) =>
  rootState[COBRANDLOGOS_FEATURE_KEY];

export const selectAllCobrandLogos = createSelector(
  getCobrandLogosState,
  selectAll,
);

export const selectCobrandLogosEntities = createSelector(
  getCobrandLogosState,
  selectEntities,
);

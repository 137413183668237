import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Button, DataTable, Dialog, IconButton, Loader } from '@clatter/ui';
import { BioDetailsDialog } from '@clatter/templates';
import { bioFromStore } from '../../../pages/fromStore';
import AdminContentHead from '../AdminContentHead';
import CreateBioForm from './CreateBioForm';
import EditBioForm from './EditBioForm';
import { deleteBio, fetchBios } from '../../../store/bios.slice';
import { formatDate } from '../../../helpers';

const bioColumns = [
  {
    label: 'Name',
    name: 'name',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Group',
    name: 'groupName',
    align: 'left',
  },
  {
    label: 'Date added',
    name: 'createdAt',
    customRender: (row) => formatDate(row.createdAt, true),
    align: 'left',
    sortable: true,
  },
];

const Bios = () => {
  const [previewBio, setPreviewBio] = useState(null);
  const [showCreateBioDialog, setShowCreateBioDialog] = useState(false);
  const [editableItem, setEditableItem] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBios());
  }, [dispatch]);

  const bios = useSelector((state) =>
    Object.values(state.bios.entities).map(bioFromStore),
  );

  const loading = useSelector(
    (state) => state.bios.loadingStatus === 'loading',
  );

  const toggleShowCreateBioDialog = () => {
    setShowCreateBioDialog(!showCreateBioDialog);
  };

  const handleShowPreview = (event) => {
    const nextPreviewBio = bios.find(
      (bio) => bio.id === event.currentTarget.dataset.id,
    );

    setPreviewBio(nextPreviewBio);
  };

  const handleHidePreview = () => {
    setPreviewBio(null);
  };

  const handleEditClick = (event) => {
    const item = bios.find((bio) => bio.id === event.currentTarget.dataset.id);

    if (!item) {
      return;
    }

    setEditableItem(item);
  };

  const handleDismissEdit = () => {
    setEditableItem(null);
  };

  const handleDeleteClick = (event) => {
    if (window.confirm('Are you sure you want to remove this bio?')) {
      dispatch(deleteBio(event.currentTarget.dataset.id));
    }
  };

  return (
    <>
      {loading && <Loader />}
      <AdminContentHead>
        <h1>Bios</h1>
        <Button onClick={toggleShowCreateBioDialog}>Add bio</Button>
      </AdminContentHead>
      <div>
        <DataTable
          autofit
          columns={bioColumns}
          defaultSortField="name"
          filterColumns={['name']}
          name="adminBios"
          rows={bios}
          renderActionColumn={(row) => (
            <>
              <IconButton data-id={row.id} onClick={handleShowPreview}>
                <VisibilityOutlinedIcon />
              </IconButton>
              <IconButton data-id={row.id} onClick={handleEditClick}>
                <EditOutlinedIcon />
              </IconButton>
              <IconButton data-id={row.id} onClick={handleDeleteClick}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </>
          )}
        />
      </div>
      <BioDetailsDialog
        onCloseDialog={handleHidePreview}
        details={previewBio}
        open={!!previewBio}
      />
      <Dialog
        title="Add bio"
        onCloseDialog={toggleShowCreateBioDialog}
        open={showCreateBioDialog}
      >
        <CreateBioForm onSuccess={toggleShowCreateBioDialog} />
      </Dialog>
      <Dialog
        title="Edit bio"
        onCloseDialog={handleDismissEdit}
        open={!!editableItem}
      >
        <EditBioForm
          editableItem={editableItem}
          onSuccess={handleDismissEdit}
        />
      </Dialog>
    </>
  );
};

export default Bios;

import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const StyledTemplateSelector = styled.div`
  .template-selector {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    gap: 5px;
    overflow-x: auto;
  }

  .template-name {
    margin: 8px 4px;
  }

  .template-header {
    color: ${({ theme }) => theme.sectionHeader.color};
    font-weight: 500;

    &.has-error {
      color: ${({ theme }) => theme.palette.error};
    }
  }

  .template {
    height: ${({ height }) => height};
    width: ${({ width }) => width};
    min-height: ${({ height }) => height};
    min-width: ${({ width }) => width};
    color: #fff;
    margin: 6px;
    cursor: pointer;

    background-color: #111564;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    &.active {
      outline: 4px solid #111564;
      outline-offset: 2px;
    }

    &:active {
      outline: 4px solid #182098;
      outline-offset: 2px;
    }

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
    }

    &.disabled:not(.active) {
      opacity: 0.5;

      + .template-name {
        opacity: 0.5;
      }
    }
  }
`;

const TemplateSelector = ({
  title = 'Please select a template',
  templateFallbackColor = '#111564',
  templates = [],
  onChange,
  value,
  error = false,
  thumbnailProps = {
    width: '100px',
    height: '100px',
  },
  disabled = false,
}) => {
  const onTemplateClick = (template) => {
    if (template.id !== value) {
      typeof onChange === 'function' && onChange(template);
    }
  };

  return (
    <StyledTemplateSelector {...thumbnailProps}>
      {title && (
        <h4 className={classNames('template-header', { 'has-error': error })}>
          {title}
        </h4>
      )}
      <div className="template-selector">
        {templates.map((template) => (
          <div key={template.id}>
            <div
              onClick={() => onTemplateClick(template)}
              style={{
                backgroundImage: `url(${template.thumbnail})`,
                backgroundColor: templateFallbackColor,
              }}
              className={classNames('template', {
                active: template.id === value,
                disabled,
              })}
            />
            {template.name && (
              <div className="template-name">{template.name}</div>
            )}
          </div>
        ))}
      </div>
    </StyledTemplateSelector>
  );
};

TemplateSelector.propTypes = {
  title: PropTypes.string,
  templateFallbackColor: PropTypes.string,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      thumbnail: PropTypes.string.isRequired,
    }),
  ),
  thumbnailProps: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default TemplateSelector;

const routes = {
  index: '/',
  microsites: '/microsites',
  siteSettings: '/:siteId/sitesettings',
  publishSite: '/:siteId/publish',
  pageSettings: '/:pageId/pagesettings',
  pageResources: '/:pageId/resources',
  pageVideos: '/:pageId/videos',
  pageVideosTable: '/:pageId/videos-table',
  pageBios: '/:pageId/bios',
  pageFooter: '/:pageId/footer',
  pagePreview: '/:pageId/preview',
  pageBuildPreview: '/:pageId/previewbuild',
  components: '/components',
  logout: '/logout',
};

export default routes;

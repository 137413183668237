import axios from 'axios';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

export const PAGETEMPLATES_FEATURE_KEY = 'pageTemplates';
export const pageTemplatesAdapter = createEntityAdapter();
/**
 * Export an effect using createAsyncThunk from
 * the Redux Toolkit: https://redux-toolkit.js.org/api/createAsyncThunk
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(fetchPageTemplates())
 * }, [dispatch]);
 * ```
 */

const collection = 'pageTemplates';

export const fetchPageTemplates = createAsyncThunk(
  `${collection}/fetchStatus`,
  async (_, thunkAPI) => {
    let cms_host = process.env.NX_CMS_HOST;
    let response = await axios.get(`${cms_host}/page-templates`);
    // console.debug( { response });

    return Promise.resolve(response.data);
  },
);

export const postPageTemplate = createAsyncThunk(
  'pageTemplates/postStatus',
  async (newTemplate, thunkAPI) => {
    let cms_host = process.env.NX_CMS_HOST;
    let addedTemplate = await axios.post(
      `${cms_host}/page-templates`,
      newTemplate,
    );

    // console.debug( { addedTemplate } );

    return Promise.resolve(addedTemplate);
  },
);

export const initialPageTemplatesState = pageTemplatesAdapter.getInitialState({
  loadingStatus: 'loading',
  error: null,
});
export const pageTemplatesSlice = createSlice({
  name: PAGETEMPLATES_FEATURE_KEY,
  initialState: initialPageTemplatesState,
  reducers: {
    add: pageTemplatesAdapter.addOne,
    remove: pageTemplatesAdapter.removeOne,
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPageTemplates.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchPageTemplates.fulfilled, (state, action) => {
        pageTemplatesAdapter.setAll(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchPageTemplates.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      });
  },
});
/*
 * Export reducer for store configuration.
 */
export const pageTemplatesReducer = pageTemplatesSlice.reducer;
/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(pageTemplatesActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const pageTemplatesActions = pageTemplatesSlice.actions;
/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllPageTemplates);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = pageTemplatesAdapter.getSelectors();
export const getPageTemplatesState = (rootState) =>
  rootState[PAGETEMPLATES_FEATURE_KEY];
export const selectAllPageTemplates = createSelector(
  getPageTemplatesState,
  selectAll,
);
export const selectPageTemplatesEntities = createSelector(
  getPageTemplatesState,
  selectEntities,
);

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '@clatter/ui';
import {
  fetchMicrosites,
  fetchPageTemplates,
  fetchPages,
  fetchHeroImages,
  fetchVideoEmbeds,
} from '../../store';
import { pageFromStore, heroImageFromStore } from '../fromStore';
import SiteMaker from '../../components/SiteMaker/SiteMaker';
import PageSettingsForm from '../../components/PageSettingsForm/PageSettingsForm';
import { selectAllVideoEmbeds } from '../../store/video-embeds.slice';
import { selectAllPageTemplates } from '../../store/page-templates.slice';

const PageSettings = () => {
  const { pageId } = useParams();
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }
  if (!isAuthenticated) {
    // shouldn't happen, as this component is only displayed in a ProtectedRoute
    return <div>Not Authorized.</div>;
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPages());
    dispatch(fetchMicrosites(user));
    dispatch(fetchPageTemplates());
    dispatch(fetchHeroImages());
    dispatch(fetchVideoEmbeds());
  }, [dispatch]);

  const loading = useSelector(
    (state) =>
      state.pageTemplates.loadingStatus === 'loading' ||
      state.microsites.loadingStatus === 'loading' ||
      state.pages.loadingStatus === 'loading' ||
      state.heroImages.loadingStatus === 'loading' ||
      state.videoEmbeds.loadingStatus === 'loading',
  );

  const pageTemplates = useSelector(selectAllPageTemplates);

  const heroImages = useSelector((state) =>
    Object.values(state.heroImages.entities).map(heroImageFromStore),
  );

  const videos = useSelector(selectAllVideoEmbeds);

  const currentMicrosite = useSelector((state) => {
    if (state.pages.ids.indexOf[pageId] === -1) {
      return;
    }

    const siteId = state.microsites.ids.find((micrositeId) =>
      state.microsites.entities[micrositeId].pages.some(
        (page) => page.id === pageId,
      ),
    );

    if (!siteId || !state.microsites.entities[siteId]) {
      return;
    }

    return {
      ...state.microsites.entities[siteId],
      pages: state.microsites.entities[siteId].pages?.map((page) =>
        pageFromStore(page, pageTemplates),
      ),
    };
  });

  const currentPage = currentMicrosite?.pages?.find(({ id }) => id === pageId);

  if (loading) {
    return <Loader />;
  }

  if (
    !loading &&
    (!currentMicrosite || !currentMicrosite.pages || !currentPage)
  ) {
    return <p>Couldn't load current page</p>;
  }

  return (
    <SiteMaker site={currentMicrosite}>
      <PageSettingsForm
        currentMicrosite={currentMicrosite}
        currentPage={currentPage}
        heroImages={heroImages}
        pageId={pageId}
        videos={videos}
      />
    </SiteMaker>
  );
};

export default PageSettings;

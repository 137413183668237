import axios from 'axios';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
export const HERO_IMAGES_FEATURE_KEY = 'heroImages';
export const heroImagesAdapter = createEntityAdapter();
const baseUrl = `${process.env.NX_CMS_HOST}/hero-images`;

export const fetchHeroImages = createAsyncThunk(
  `${HERO_IMAGES_FEATURE_KEY}/fetch`,
  async () => {
    const response = await axios.get(`${baseUrl}?_sort=updatedAt:DESC`);

    return Promise.resolve(response.data);
  },
);

export const createHeroImage = createAsyncThunk(
  `${HERO_IMAGES_FEATURE_KEY}/create`,
  async ({ description, file }) => {
    const response = await axios.post(baseUrl, { description });

    const newUpload = new FormData();
    newUpload.append('files', file);
    newUpload.append('ref', 'hero-images');
    newUpload.append('refId', response.data.id);
    newUpload.append('field', 'asset');

    const uploadResponse = await axios.post(
      `${process.env.NX_CMS_HOST}/upload`,
      newUpload,
    );

    const newHeroImage = {
      ...response.data,
      asset: {
        ...uploadResponse.data[0],
      },
    };

    return Promise.resolve(newHeroImage);
  },
);

export const updateHeroImage = createAsyncThunk(
  `${HERO_IMAGES_FEATURE_KEY}/update`,
  async ({ id, formData: { description, file } }) => {
    const response = await axios.put(`${baseUrl}/${id}`, {
      description,
    });

    const updatedHeroImage = {
      ...response.data,
    };

    if (file) {
      const newUpload = new FormData();
      newUpload.append('files', file);
      newUpload.append('ref', 'hero-images');
      newUpload.append('refId', response.data.id);
      newUpload.append('field', 'asset');

      const uploadResponse = await axios.post(
        `${process.env.NX_CMS_HOST}/upload`,
        newUpload,
      );

      updatedHeroImage.asset = uploadResponse.data[0];
    }

    return Promise.resolve(updatedHeroImage);
  },
);

export const deleteHeroImage = createAsyncThunk(
  `${HERO_IMAGES_FEATURE_KEY}/delete`,
  async (id) => {
    const response = await axios.delete(`${baseUrl}/${id}`);

    return Promise.resolve(response.data);
  },
);

export const initialHeroimagesState = heroImagesAdapter.getInitialState({
  loadingStatus: 'loading',
  error: null,
});

export const heroImagesSlice = createSlice({
  name: HERO_IMAGES_FEATURE_KEY,
  initialState: initialHeroimagesState,
  reducers: {
    add: heroImagesAdapter.addOne,
    remove: heroImagesAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHeroImages.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchHeroImages.fulfilled, (state, action) => {
        heroImagesAdapter.setAll(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchHeroImages.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(createHeroImage.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(createHeroImage.fulfilled, (state, action) => {
        heroImagesAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(createHeroImage.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(deleteHeroImage.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(deleteHeroImage.fulfilled, (state, action) => {
        heroImagesAdapter.removeOne(state, action.payload.id);
        state.loadingStatus = 'loaded';
      })
      .addCase(deleteHeroImage.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(updateHeroImage.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(updateHeroImage.fulfilled, (state, action) => {
        heroImagesAdapter.removeOne(state, action.payload.id);
        heroImagesAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(updateHeroImage.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      });
  },
});

export const heroImagesReducer = heroImagesSlice.reducer;
export const heroImagesActions = heroImagesSlice.actions;

const { selectAll, selectEntities } = heroImagesAdapter.getSelectors();

export const getHeroimagesState = (rootState) =>
  rootState[HERO_IMAGES_FEATURE_KEY];

export const selectAllHeroimages = createSelector(
  getHeroimagesState,
  selectAll,
);

export const selectHeroimagesEntities = createSelector(
  getHeroimagesState,
  selectEntities,
);

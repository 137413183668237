import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import {
  fetchPages,
  fetchPageTemplates,
  fetchCobrandLogos,
  fetchHeroImages,
  fetchMicrosites
} from '../../store';
import SiteMaker from '../../components/SiteMaker/SiteMaker';
import SiteMakerContentHead from '../../components/SiteMaker/SiteMakerContentHead';
import { heroImageFromStore, pageFromStore } from '../fromStore';
import SiteSettingsForm from '../../components/SiteSettingsForm/SiteSettingsForm';
import { Loader } from '@clatter/ui';
import { selectAllPageTemplates } from '../../store/page-templates.slice';

const SiteSettings = () => {
  const { siteId } = useParams();
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }

  if (!isAuthenticated) {
    // shouldn't happen, as this component is only displayed in a ProtectedRoute
    return <div>Not Authorized.</div>;
  }

  const dispatch = useDispatch();
  useEffect(() => {
    // @todo don't fetch all pages, just pages for current microsite
    dispatch(fetchPages());
    dispatch(fetchMicrosites(user));
    dispatch(fetchPageTemplates());
    dispatch(fetchHeroImages());
    dispatch(fetchCobrandLogos(user.email));
  }, [dispatch]);

  const loading = useSelector(
    (state) =>
      state.pageTemplates.loadingStatus === 'loading' ||
      state.microsites.loadingStatus === 'loading' ||
      state.pages.loadingStatus === 'loading' ||
      state.heroImages.loadingStatus === 'loading' ||
      state.cobrandLogos.loadingStatus === 'loading',
  );

  const cobrandLogos = useSelector((state) =>
    Object.values(state.cobrandLogos.entities).sort((a, b) =>
      a.updatedAt > b.updatedAt ? -1 : 1,
    ),
  );

  const pageTemplates = useSelector(selectAllPageTemplates);

  const heroImages = useSelector((state) =>
    Object.values(state.heroImages.entities).map(heroImageFromStore),
  );

  const currentMicrosite = useSelector((state) => {
    if (!siteId || !state.microsites.entities[siteId]) {
      return;
    }

    return {
      ...state.microsites.entities[siteId],
      pages: state.microsites.entities[siteId].pages?.map((page) =>
        pageFromStore(page, pageTemplates),
      ),
    };
  });

  if (!loading && (!currentMicrosite || !currentMicrosite.pages)) {
    return <p>Couldn't load current page</p>;
  }

  return (
    <SiteMaker site={currentMicrosite}>
      {loading && <Loader />}
      <SiteMakerContentHead
        title="Create a microsite"
        subhead="Please complete the following steps and click the “Save my selections and continue” button below."
        hideButtons={true}
      />
      <SiteSettingsForm
        cobrandLogos={cobrandLogos}
        heroImages={heroImages}
        microsite={currentMicrosite}
        pages={currentMicrosite?.pages || []}
        pageTemplates={pageTemplates}
      />
    </SiteMaker>
  );
};

export default SiteSettings;

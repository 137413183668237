import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';

const keyPrefix = '@@auth0spajs@@';
const defaultScope = 'openid profile email';
const clientId = process.env.NX_AUTH0_CLIENT_ID;
const audience = process.env.NX_AUTH0_AUDIENCE;

/**
 * Returns Auth0 AccessToken from the localStorage
 * @returns {string | undefined}
 */
const getAuth0Token = () => {
  const token = localStorage.getItem(
    `${keyPrefix}::${clientId}::${audience}::${defaultScope}`,
  );
  return JSON.parse(token || null)?.body?.access_token;
};

/**
 * Creates a new instance of axios with a custom config
 *
 * @type {AxiosInstance} client
 */
const client = axios.create({
  baseURL: `${process.env.NX_CLATTER_API_URL}`,
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      window.location = '/logout';
    } else {
      return Promise.reject(error);
    }
  },
);

/**
 * Axios instance that uses auth0 access token
 *
 * @param {AxiosRequestConfig} requestConfigOptions
 * @returns {Promise}
 */
const getAxiosWithAuthorization = (requestConfigOptions) => {
  const auth0Token = getAuth0Token();

  if (auth0Token) {
    client.defaults.headers.common.Authorization = `Bearer ${auth0Token}`;
  }

  const onSuccess = (response) => {
    return Promise.resolve(response.data);
  };

  return client(requestConfigOptions)
    .then(onSuccess);
};

export default getAxiosWithAuthorization;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CloseIcon from '@material-ui/icons/Close';
import {
  DataTable,
  FormControl,
  IconButton,
  SelectControl,
  TextControl,
} from '@clatter/ui';
import { arrayMove } from '../../helpers';
import VideoPreviewDialog from '../VideoPreviewDialog/VideoPreviewDialog';

const StyledVideoPicker = styled.div`
  .filters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
    gap: 16px;
  }

  h3 {
    margin: 24px 0 8px;
  }
`;

const VideoPicker = ({ max = 0, onChange, options, tableColumns, value }) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [previewVideo, setPreviewVideo] = useState(null);

  useEffect(() => {
    const nextAvailableCategories = [
      ...new Set(
        options.map((resource) => resource.video_category?.categoryName),
      ),
    ]
      .filter((resource) => !!resource)
      .map((category) => ({
        label: category,
        value: category,
      }));

    nextAvailableCategories.unshift({
      label: 'All',
      value: null,
    });

    setAvailableCategories(nextAvailableCategories);
  }, [value, options]);

  useEffect(() => {
    let nextFilteredOptions = options.filter(
      ({ id: optionId }) =>
        (value &&
          Array.isArray(value) &&
          !value.some(({ id: valueId }) => valueId === optionId)) ||
        !value,
    );

    if (categoryFilter && categoryFilter.value) {
      nextFilteredOptions = nextFilteredOptions.filter(
        (item) => item?.video_category?.categoryName === categoryFilter.value,
      );
    }

    if (searchTerm) {
      nextFilteredOptions = nextFilteredOptions.filter((video) =>
        video.title.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    setFilteredOptions(nextFilteredOptions);
  }, [value, searchTerm, categoryFilter, options]);

  if (!options || !options.length) {
    return <div>Error: no videos provided. Check the CMS.</div>;
  }

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCategoryFilterChange = (option) => {
    setCategoryFilter(option);
  };

  const handlePreviewClick = (event) => {
    const previewVideo = options.find(
      (item) => item.id === event.currentTarget.dataset.id,
    );

    setPreviewVideo(previewVideo);
  };

  const handleClosePreviewDialog = () => {
    setPreviewVideo(null);
  };

  const handleMoveItemUp = (event) => {
    const currentIndex = Number(event.currentTarget.dataset.index);
    const nextValue = arrayMove(value, currentIndex, currentIndex - 1);
    onChange(nextValue);
  };

  const handleMoveItemDown = (event) => {
    const currentIndex = Number(event.currentTarget.dataset.index);
    const nextValue = arrayMove(value, currentIndex, currentIndex + 1);
    onChange(nextValue);
  };

  const handleAdd = (event) => {
    const nextValue = cloneDeep(value);
    const clickedItemId = event.currentTarget.dataset.id;
    const newItem = cloneDeep(
      filteredOptions.find(({ id }) => id === clickedItemId),
    );
    nextValue.push(newItem);
    onChange(nextValue);
  };

  const handleRemove = (event) => {
    onChange(value.filter(({ id }) => id !== event.currentTarget.dataset.id));
  };

  return (
    <StyledVideoPicker>
      <h4>Available videos</h4>
      <div className="filters">
        <FormControl>
          <TextControl
            onChange={handleSearchTermChange}
            placeholder="Search videos"
            value={searchTerm}
          />
        </FormControl>
        <FormControl>
          <SelectControl
            onChange={handleCategoryFilterChange}
            options={availableCategories}
            placeholder="Select a category"
            value={categoryFilter}
          />
        </FormControl>
      </div>
      <DataTable
        columns={tableColumns}
        rows={filteredOptions}
        renderActionColumn={(row) => (
          <>
            <IconButton
              disabled={max && value?.length >= max}
              data-id={row.id}
              onClick={handleAdd}
              tooltip="Add"
            >
              <AddIcon />
            </IconButton>
            <IconButton
              data-id={row.id}
              onClick={handlePreviewClick}
              tooltip="Preview"
            >
              <PlayArrowIcon />
            </IconButton>
          </>
        )}
      />
      <h3>Your selected videos</h3>
      <DataTable
        columns={tableColumns}
        rows={(value || []).map(({ id }) =>
          options.find((resource) => resource.id === id),
        )}
        noRowsMessage="No videos selected"
        renderActionColumn={(row, index) => (
          <>
            <IconButton
              disabled={!index}
              data-index={index}
              onClick={handleMoveItemUp}
              tooltip="Move up"
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              disabled={value.length <= index + 1}
              data-index={index}
              onClick={handleMoveItemDown}
              tooltip="Move down"
            >
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton
              data-id={row.id}
              onClick={handleRemove}
              tooltip="Remove"
            >
              <CloseIcon />
            </IconButton>
          </>
        )}
      />
      <VideoPreviewDialog
        title={previewVideo?.title}
        videoUrl={previewVideo?.link}
        onCloseDialog={handleClosePreviewDialog}
      />
    </StyledVideoPicker>
  );
};

export default VideoPicker;

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { generatePath, useHistory } from 'react-router-dom';

import {
  Button,
  FormControl,
  StepCard,
  StepCardContent,
  StepCardHeader,
  StepCardTitle,
  themes,
} from '@clatter/ui';
import { FooterBrand } from '@clatter/templates';
import { updatePage } from '../../store';
import SiteMakerActions from '../SiteMaker/SiteMakerActions';
import PreviewButton from '../SiteMaker/PreviewButton';
import { getNextPage, isSiteComplete } from '../../helpers';
import BioPicker from '../BioPicker/BioPicker';
import routes from '../../routes/routes';

const FooterSettingsForm = ({
  micrositesSettings,
  currentMicrosite,
  currentPage,
  bios,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  let pageVariables = {
    headline: '[default headline]',
  };

  if (currentPage) {
    pageVariables = JSON.parse(currentPage.variables);
  }

  const {
    handleSubmit,
    formState: { errors },
    control,
    formState,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: currentPage
      ? {
          footerBio: pageVariables.footerBio,
        }
      : {},
  });

  const redirectToPublish = () => {
    history.push(generatePath(routes.publishSite, { siteId: currentMicrosite.id }));
  };

  const handleFormSubmit = async (formData) => {
    if (formState.isDirty) {
      await dispatch(
        updatePage({
          id: currentPage.id,
          variables: JSON.stringify({
            ...pageVariables,
            ...formData,
          }),
        }),
      );
      history.push(getNextPage(currentMicrosite, currentMicrosite.pages));
    } else {
      history.push(getNextPage(currentMicrosite, currentMicrosite.pages));
    }
  };

  const renderPreview = () => {
    const variables = JSON.parse(currentPage.variables);

    return (
      <ThemeProvider theme={themes[variables?.theme || 'default']}>
        <FooterBrand
          showLogo={micrositesSettings?.showBrandLogoInBrandFooter}
          bio={watch('footerBio')}
          logo={currentMicrosite?.cobrand_logo?.url}
        />
      </ThemeProvider>
    );
  };

  const renderButtons = () => (
    <>
      <Button disabled={!formState.isValid} type="submit">
        {formState.isDirty ? 'Save Footer and continue' : 'Continue'}
      </Button>
      <PreviewButton
        siteName={currentMicrosite.name}
        pageName={currentPage.name}
      />
      <Button
        disabled={!isSiteComplete(currentMicrosite, currentMicrosite.pages)}
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <StepCard>
        <StepCardHeader>
          <StepCardTitle text="Select a client lead bio" />
        </StepCardHeader>
        <StepCardContent>
          <FormControl noPadding error={errors.footerBio}>
            <Controller
              render={({ field: { onChange, value } }) => (
                <BioPicker
                  max={2}
                  onChange={onChange}
                  options={bios}
                  value={value}
                />
              )}
              control={control}
              name="footerBio"
              defaultValue={[]}
            />
          </FormControl>
        </StepCardContent>
      </StepCard>
      <SiteMakerActions
        renderPreview={renderPreview}
        renderButtons={renderButtons}
      />
    </form>
  );
};

export default FooterSettingsForm;

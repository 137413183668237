import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  EditorControl,
  FormButtons,
  FormControl,
  Loader,
  MaskedControl,
  SelectControl,
  TextControl,
} from '@clatter/ui';
import FileUpload from '../../FileUpload/FileUpload';
import { createBio } from '../../../store/bios.slice';
import {
  fetchBioGroups,
  selectAllBioGroups,
} from '../../../store/bio-groups.slice';
import { patterns } from '../../../helpers';
import { acceptedFileTypes } from './config';
import { validateUploadedPhoto } from './validators';

const StyledCreateBioForm = styled.form`
  width: 480px;
`;

const CreateBioForm = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    formState,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    dispatch(fetchBioGroups());
  }, [dispatch]);

  const bioGroups = useSelector(selectAllBioGroups);

  const onSubmit = async (formData) => {
    dispatch(createBio(formData));
    onSuccess();
  };

  if (!bioGroups || !bioGroups.length) {
    return <Loader />;
  }

  return (
    <StyledCreateBioForm onSubmit={handleSubmit(onSubmit)}>
      <FormControl error={errors.file}>
        <Controller
          name="file"
          rules={{
            validate: validateUploadedPhoto,
          }}
          render={({ field: { onChange, value } }) => (
            <FileUpload
              acceptedFileTypes={acceptedFileTypes}
              onChange={onChange}
              value={value}
            />
          )}
          control={control}
          defaultValue={null}
        />
      </FormControl>
      <FormControl label="First name" error={errors.firstName}>
        <TextControl
          {...register('firstName', {
            required: { value: true, message: 'Field is required' },
          })}
          placeholder="Enter first name"
        />
      </FormControl>
      <FormControl label="Last name" error={errors.lastName}>
        <TextControl
          {...register('lastName', {
            required: { value: true, message: 'Field is required' },
          })}
          placeholder="Enter last name"
        />
      </FormControl>
      <FormControl label="Title" error={errors.title}>
        <TextControl
          {...register('title', {
            required: { value: true, message: 'Field is required' },
          })}
          placeholder="Enter title"
        />
      </FormControl>
      <FormControl label="E-mail" error={errors.email}>
        <TextControl
          {...register('email', {
            pattern: {
              value: patterns.email,
              message: 'Invalid e-mail address',
            },
          })}
          placeholder="Enter e-mail address"
          type="email"
        />
      </FormControl>
      <FormControl label="Phone" error={errors.phone}>
        <Controller
          name="phone"
          rules={{
            validate: (value) => {
              if (value && value.length !== 10) {
                return 'Invalid phone numer';
              }
            },
          }}
          render={({ field: { onChange, value } }) => (
            <MaskedControl
              onChange={onChange}
              value={value}
              type="tel"
              placeholder="Enter phone number"
              mask="xxx-xxx-xxxx"
            />
          )}
          control={control}
          defaultValue=""
        />
      </FormControl>
      <FormControl label="Description" error={errors.description}>
        <Controller
          name="description"
          render={({ field: { onChange, value } }) => (
            <EditorControl onChange={onChange} value={value} />
          )}
          control={control}
          defaultValue=""
        />
      </FormControl>
      <FormControl label="Group">
        <Controller
          name="group"
          render={({ field: { onChange, value } }) => (
            <SelectControl
              onChange={onChange}
              options={bioGroups}
              value={value}
              getOptionLabel={(option) => option.groupName}
              getOptionValue={(option) => option.id}
            />
          )}
          control={control}
          defaultValue={null}
        />
      </FormControl>
      <FormButtons>
        <Button disabled={!formState.isValid} type="submit">
          Create
        </Button>
      </FormButtons>
    </StyledCreateBioForm>
  );
};

export default CreateBioForm;

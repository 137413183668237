import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';

const StyledSelectControl = styled(Select)`
  border: none;
  min-width: 200px;

  .react-select__menu-portal {
    z-index: 2;
  }

  .react-select__control {
    border-color: #ddd;
    min-height: 40px;

    &:hover,
    &--is-focused,
    &--menu-is-open {
      border-color: #1890ff;
      box-shadow: none;
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__option {
    &.react-select__option--is-focused:not(.react-select__option--is-selected) {
      background-color: #f4f4f4;
    }

    &.react-select__option--is-selected {
      color: #fff;
      background-color: #1890ff;
    }
  }
`;

const SelectControl = ({ menuPosition = 'fixed', ...props }) => (
  <StyledSelectControl
    menuPosition={menuPosition}
    classNamePrefix="react-select"
    {...props}
  />
);

export default SelectControl;

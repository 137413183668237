import { getImageSize } from '@clatter/platform';
import { acceptedFileTypesArray } from './config';

export const validateUploadedPhoto = async (value) => {
  if (!value) {
    return 'Field is required';
  }

  if (!acceptedFileTypesArray.includes(value?.type)) {
    return 'Invalid file type';
  }

  const imageSize = await getImageSize(value);

  if (imageSize.width < 246 && imageSize.height < 157) {
    return 'Image size cannot be smaller than 246px width and 157px height';
  }
};

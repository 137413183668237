import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CloseIcon from '@material-ui/icons/Close';
import {
  FormControl,
  IconButton,
  TextControl,
  SelectControl,
} from '@clatter/ui';
import BioCardList from '../BioCardList/BioCardList';
import { arrayMove } from '../../helpers';

const StyledBioPicker = styled.div`
  padding: 24px 0;

  .filters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
    gap: 16px;
  }

  .bio-container {
    max-height: 240px;
    overflow-y: auto;
  }

  h3 {
    margin: 24px 0 8px;
  }
`;

const BioPicker = ({ max, onChange, options, value }) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [groupFilter, setGroupFilter] = useState('');

  useEffect(() => {
    const nextAvailableGroups = [
      ...new Set(options.map((bio) => bio.groupName)),
    ]
      .filter((group) => !!group)
      .map((group) => ({
        label: group,
        value: group,
      }));

    setAvailableGroups(nextAvailableGroups);
  }, [options, value]);

  useEffect(() => {
    let nextFilteredOptions =
      value && Array.isArray(value)
        ? options.filter(
            ({ id: optionId }) =>
              !value.some(({ id: valueId }) => valueId === optionId),
          )
        : options;

    if (groupFilter) {
      nextFilteredOptions = nextFilteredOptions.filter(
        (bio) => bio.group.groupName === groupFilter.value,
      );
    }

    if (searchTerm) {
      nextFilteredOptions = nextFilteredOptions.filter((bio) =>
        bio.name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    setFilteredOptions(nextFilteredOptions);
  }, [value, searchTerm, groupFilter, options]);

  if (!options || !options.length) {
    return null;
  }

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleGroupFilterChange = (option) => {
    setGroupFilter(option);
  };

  const handleMoveItemUp = (event) => {
    const currentIndex = Number(event.currentTarget.dataset.index);
    const nextValue = arrayMove(value, currentIndex, currentIndex - 1);
    onChange(nextValue);
  };

  const handleMoveItemDown = (event) => {
    const currentIndex = Number(event.currentTarget.dataset.index);
    const nextValue = arrayMove(value, currentIndex, currentIndex + 1);
    onChange(nextValue);
  };

  const handleAdd = (event) => {
    const newOption = options.find(
      ({ id }) => id === event.currentTarget.dataset.id,
    );

    if (newOption) {
      const nextValue = value && Array.isArray(value) ? cloneDeep(value) : [];
      nextValue.push(newOption);
      onChange(nextValue);
    }
  };

  const handleRemove = (event) => {
    const nextValue = value.filter(
      (item) => item.id !== event.currentTarget.dataset.id,
    );
    onChange(nextValue);
  };

  return (
    <StyledBioPicker>
      <div className="filters">
        <FormControl>
          <TextControl
            onChange={handleSearchTermChange}
            placeholder="Search bios"
            value={searchTerm}
          />
        </FormControl>
        <FormControl>
          <SelectControl
            onChange={handleGroupFilterChange}
            options={availableGroups}
            placeholder="Select a group"
            value={groupFilter}
          />
        </FormControl>
      </div>
      <div className="bio-container">
        <BioCardList
          emptyRowsMessage="No bios to pick."
          items={filteredOptions}
          renderItemActions={(id) => (
            <IconButton
              disabled={value && Array.isArray(value) && value.length >= max}
              data-id={id}
              onClick={handleAdd}
              tooltip="Add"
            >
              <AddIcon />
            </IconButton>
          )}
        />
      </div>
      <h3>Selected bios</h3>
      <div className="bio-container">
        <BioCardList
          items={value}
          emptyRowsMessage="No bios selected"
          renderItemActions={(id, index) => (
            <>
              <IconButton
                disabled={!index}
                data-index={index}
                onClick={handleMoveItemUp}
                tooltip="Move up"
              >
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                disabled={value.length <= index + 1}
                data-index={index}
                onClick={handleMoveItemDown}
                tooltip="Move down"
              >
                <ArrowDownwardIcon />
              </IconButton>
              <IconButton data-id={id} onClick={handleRemove} tooltip="Remove">
                <CloseIcon />
              </IconButton>
            </>
          )}
        />
      </div>
    </StyledBioPicker>
  );
};

export default BioPicker;

import { cloneDeep } from 'lodash';
import { format } from 'date-fns';
import { generatePath } from 'react-router-dom'
import routes from '../routes/routes';

export const formatFileSize = (fileSize, format = 'KB') => {
  switch (format) {
    case 'KB':
      return `${Math.floor(fileSize / 1000)} KB`;
    case 'MB':
      return `${Math.floor(fileSize / 1000000)} MB`;
    default:
      return `${fileSize} B`;
  }
};

export const compareIdentifiers = (first, second) => {
  const firstType = typeof first;
  const secondType = typeof second;

  if (firstType === secondType) {
    return first === second;
  }

  if (firstType === 'string' && secondType === 'number') {
    return first === second.toString();
  }

  if (firstType === 'number' && secondType === 'string') {
    return first === Number(second);
  }

  return false;
};

export const getCopyName = (name) => {
  if (/ Copy( [0-9]+)?$/.test(name)) {
    const [copyName, copyNumber] = name.split(' Copy');
    const nextCopyNumber = copyNumber ? Number(copyNumber) + 1 : 2;
    return `${copyName} Copy ${nextCopyNumber}`;
  }

  return `${name} Copy`;
};

// temp: working out data structure for "page list" for use by getNextPage()
const blockConfigListForPage = (page) => {
  switch (page.templateName) {
    case 'Prospect Site':
    case 'Prospect Site UHC':
    case 'Prospect Site UHC - Optum':
      return [
        generatePath(routes.pageSettings, { pageId: page.id }),
        generatePath(routes.pageResources, { pageId: page.id }),
        generatePath(routes.pageVideos, { pageId: page.id }),
        generatePath(routes.pageFooter, { pageId: page.id }),
      ];

    case 'Partner Site':
      return [
        generatePath(routes.pageSettings, { pageId: page.id }),
        generatePath(routes.pageBios, { pageId: page.id }),
        generatePath(routes.pageResources, { pageId: page.id }),
      ];

    case 'Additional Resources with videos':
      return [
        generatePath(routes.pageVideos, { pageId: page.id }),
        generatePath(routes.pageResources, { pageId: page.id }),
      ]

    case 'Additional Resources':
      return [
        generatePath(routes.pageSettings, { pageId: page.id }),
        generatePath(routes.pageResources, { pageId: page.id }),
      ]

    case 'Additional Videos':
    case 'Video Template':
      return [
        generatePath(routes.pageVideosTable, { pageId: page.id }),
      ]
  }
};

const pageListFromPages = (pages) => pages.map(blockConfigListForPage);

const getPageList = (microsite, pages) =>
  [
    generatePath(routes.siteSettings, { siteId: microsite.id }),
    pageListFromPages(pages),
    generatePath(routes.publishSite, { siteId: microsite.id }),
  ].flat(2);

export const formatDate = (dateString, showTime = false) => {
  if (!dateString) {
    return null;
  }

  let dateFormat = 'MM/dd/yyyy';

  if (showTime) {
    dateFormat = `${dateFormat} hh:mm a`;
  }

  return format(new Date(dateString), dateFormat);
};

// assume both history and location are in scope
export const getNextPage = (microsite, pages) => {
  const pageList = getPageList(microsite, pages);
  const currentIdx = pageList.indexOf(window.location.pathname);
  const nextPage = pageList[currentIdx + 1];
  return nextPage;
};

export const arrayMove = (array, from, to) => {
  const clonedArray = cloneDeep(array);
  const startIndex = from < 0 ? clonedArray.length + from : from;

  if (startIndex >= 0 && startIndex < clonedArray.length) {
    const endIndex = to < 0 ? clonedArray.length + to : to;

    const [item] = clonedArray.splice(from, 1);
    clonedArray.splice(endIndex, 0, item);
  }

  return clonedArray;
};

export const nameResourceMap = {
  Bios: 'bios',
  Resources: 'resources',
  Videos: 'video_embeds',
  'Videos Table': 'video_embeds',
};

export const isBannerComplete = (page) => {
  if (!page.title || !page.variables) {
    return false;
  }

  const variables = JSON.parse(page.variables);
  return variables?.headline && variables?.introCopy;
};

export const isFooterComplete = (page) => {
  if (!page.variables) {
    return false;
  }

  const variables = JSON.parse(page.variables);

  return (
    'footerBio' in variables &&
    ('displayName' in variables.footerBio ||
      (Array.isArray(variables.footerBio) && variables.footerBio.length))
  );
};

export const isBlockComplete = (page, block) => {
  switch (block.name) {
    case 'Banner':
      return isBannerComplete(page);

    case 'Bios':
    case 'Resources':
    case 'Videos':
    case 'Videos Table':
      return (
        page[nameResourceMap[block.name]] &&
        page[nameResourceMap[block.name]].length
      );

    case 'Footer':
      return isFooterComplete(page);

    default:
      return false;
  }
};

export const isPageComplete = (page) =>
  page &&
  'blocks' in page &&
  Array.isArray(page.blocks) &&
  page.blocks.length > 0 &&
  page.blocks.every((block) => isBlockComplete(page, block));

// for the moment, we aren't checking any sites setings, just that all pages are complete
export const isSiteComplete = (site, pages) =>
  pages && Array.isArray(pages) && pages.length && pages.every(isPageComplete);

export const isDev = () =>
  window.location.search?.indexOf('dev') !== -1 &&
  (window.location.host?.indexOf('dev') !== -1 ||
    window.location.host?.indexOf('staging') !== -1 ||
    window.location.host?.indexOf('localhost') !== -1);

export const patterns = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/,
};

import React from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
  font-family: ${({ theme }) => theme.body.fontFamily};
  background-color: ${({ backgroundColor, name, theme }) =>
    (name && theme[name].sectionBackgroundColor) || backgroundColor};

  .section-content {
    width: 82.5%;
    min-width: 320px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 24px 0;
  }

  &.no-padding {
    .section-content {
      padding: 0;
    }
  }
`;

const Section = ({
  children,
  className,
  backgroundColor = 'transparent',
  name,
  noPadding = false,
}) => (
  <StyledSection
    name={name}
    backgroundColor={backgroundColor}
    className={`${className} ${noPadding ? 'no-padding' : ''}`}
  >
    <div className="section-content">{children}</div>
  </StyledSection>
);

export default Section;

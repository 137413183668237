import React from 'react';
import styled from 'styled-components';
import {
  HeroBannerImage,
  Header,
  Footer,
  FeaturedResources,
  VideoEmbedGallery,
} from '../../blocks';
import Section from '../../blocks/Section';
import SectionHeader from '../../blocks/SectionHeader';

const StyledProspectSite = styled.div`
  .resources-and-videos {
    padding: 32px 0;

    .resources-video-content {
      display: flex;
      flex-wrap: wrap;

      .resources-column {
        flex: 1;
        padding: 0 16px;
      }

      .videos-column {
        flex: 2;
      }
    }
  }
`;

export const ProspectSite = ({ data }) => {
  const page = data.pages.edges[0].node;
  const resources = page.resources.map((resource) => ({
    ...resource,
    href: resource.link,
    target: '#',
    download: '#',
  }));

  const microsites = data.microsites.edges.map((e) => e.node);
  const pageId = page.id;
  const microsite = microsites.find(
    (ms) => ms.pages.find((p) => p.id === pageId) !== undefined,
  );
  const customerName = microsite.client_name;
  const pageIds = microsite.pages.map((p) => p.id);
  const srcCustomerLogo = microsite.cobrand_logo.url;
  const {
    bannerVideo,
    footerBio,
    headline,
    heroImageUrl,
    introCopy,
  } = JSON.parse(page.variables);

  return (
    <StyledProspectSite>
      <Header customerName={customerName} pageIds={pageIds} nav={false} />
      <HeroBannerImage
        bannerVideo={bannerVideo}
        headline={headline}
        heroImageUrl={heroImageUrl}
        introCopy={introCopy}
      />
      <Section className="resources-and-videos">
        <SectionHeader>Your resources</SectionHeader>
        <div className="resources-video-content">
          <div className="resources-column">
            <FeaturedResources resources={resources} vertical hideDescription />
          </div>
          <div className="videos-column">
            <VideoEmbedGallery videos={page.video_embeds} />
          </div>
        </div>
      </Section>
      <Footer logo={srcCustomerLogo} bio={footerBio} />
    </StyledProspectSite>
  );
};

export default ProspectSite;

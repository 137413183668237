import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  defaultBorderColor,
  primaryColor,
  secondaryColor,
} from '../../config/theme';
import { buttonVariants } from '../../config/consts';

const StyledButton = styled.button`
  color: #fff;
  border: 1px solid ${primaryColor};
  background: ${primaryColor};
  font-size: 14px;
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  line-height: 38px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.full-width {
    width: 100%;

    span {
      margin: 0 auto;
    }
  }

  .button-icon {
    font-size: 20px;

    + span {
      margin-left: 16px;
    }
  }

  &.button-primary {
    &:hover:not(:disabled) {
      cursor: pointer;
      background-color: #fff;
      color: ${primaryColor};
    }
  }

  &.button-secondary {
    background-color: ${secondaryColor};
    border-color: ${secondaryColor};
    color: #fff;
  }

  &.button-clear {
    color: #262626;
    background-color: #fff;
    border-color: #ddd;

    &:hover:not(:disabled) {
      border-color: #2768aa;
    }
  }

  &:disabled {
    border-color: ${defaultBorderColor};
    color: ${defaultBorderColor};
    background-color: #fff;
    cursor: not-allowed;
  }
`;

const Button = ({
  children,
  disabled = false,
  fullWidth = false,
  icon: Icon = null,
  onClick,
  type = 'button',
  variant = buttonVariants.primary,
  testId
}) => (
  <StyledButton
    className={`${variant} ${fullWidth ? 'full-width' : ''}`}
    disabled={disabled}
    type={type}
    onClick={onClick}
    data-test-id={testId}
  >
    {!!Icon && <Icon className="button-icon" />}
    {!!children && <span>{children}</span>}
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  icon: PropTypes.object,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  variant: PropTypes.oneOf(Object.values(buttonVariants)),
};

export default Button;

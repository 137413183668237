import axios from 'axios';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
export const RESOURCES_FEATURE_KEY = 'resources';
export const resourcesAdapter = createEntityAdapter();
const baseUrl = `${process.env.NX_CMS_HOST}/resources`;

// @todo conform the CMS schema e.g. resources has .owner, microsites has .users_permissions_user
export const fetchResources = createAsyncThunk(
  `${RESOURCES_FEATURE_KEY}/fetch`,
  async (email = '') => {
    if (!email) {
      // no email specified. return system-owned resources i.e. with no owner
      const url = `${baseUrl}?owner_null=true&_sort=title:ASC`;
      const response = await axios.get(url);
      return Promise.resolve(response.data);
    }
    const urlSystemOwned = `${baseUrl}?owner_null=true&_sort=title:ASC`;
    const urlUserOwned = `${baseUrl}?_where%5Bowner.email%5D=${email}&_sort=title:ASC`;
    const responseSystemOwned = await axios.get(urlSystemOwned);
    const responseUserOwned = await axios.get(urlUserOwned);

    return Promise.resolve(
      responseSystemOwned.data.concat(responseUserOwned.data),
    );
  },
);

// @todo decide a convention for 'helpers' like this, and factor it out
const getUserByEmail = async (email) => {
  const response = await axios.get(
    `${process.env.NX_CMS_HOST}/users?_where[email]=${email}`,
  );
  const user = response.data[0];
  return user;
};

// when no email is supplied, leave the owner attribute blank to denote
// 'system-owned' resources. leave createResource() call in Admin as is => system-owned
// add user.email to parameter list in call from ResourceBlockConfig
export const createResource = createAsyncThunk(
  `${RESOURCES_FEATURE_KEY}/create`,
  async ({ description, file, link, resource_category, title, email = '' }) => {
    const response = await axios.post(baseUrl, {
      description,
      link,
      owner: email === '' ? null : (await getUserByEmail(email)).id,
      resource_category,
      title,
    });

    const newResource = {
      ...response.data,
    };

    if (file) {
      const newUpload = new FormData();
      newUpload.append('files', file);
      newUpload.append('ref', 'resources');
      newUpload.append('refId', response.data.id);
      newUpload.append('field', 'asset');

      const uploadResponse = await axios.post(
        `${process.env.NX_CMS_HOST}/upload`,
        newUpload,
      );

      newResource.asset = uploadResponse.data[0];
    }

    return Promise.resolve(newResource);
  },
);

export const updateResource = createAsyncThunk(
  `${RESOURCES_FEATURE_KEY}/update`,
  async ({
    id,
    formData: { description, file, link, resource_category, title },
  }) => {
    const response = await axios.put(`${baseUrl}/${id}`, {
      description,
      link,
      // updating a resource should not change its owner
      // owner: '608a512680ce4b7206eb8bf4',
      resource_category,
      title,
    });

    const updatedResource = {
      ...response.data,
    };

    if (file) {
      const newUpload = new FormData();
      newUpload.append('files', file);
      newUpload.append('ref', 'resources');
      newUpload.append('refId', response.data.id);
      newUpload.append('field', 'asset');

      const uploadResponse = await axios.post(
        `${process.env.NX_CMS_HOST}/upload`,
        newUpload,
      );

      updatedResource.asset = {
        ...uploadResponse.data[0],
      };
    }

    return Promise.resolve(updatedResource);
  },
);

export const deleteResource = createAsyncThunk(
  `${RESOURCES_FEATURE_KEY}/delete`,
  async (id) => {
    const response = await axios.delete(`${baseUrl}/${id}`);

    return Promise.resolve(response.data);
  },
);

export const initialResourcesState = resourcesAdapter.getInitialState({
  categories: [],
  loadingStatus: 'loading',
  error: null,
});

export const resourcesSlice = createSlice({
  name: RESOURCES_FEATURE_KEY,
  initialState: initialResourcesState,
  reducers: {
    add: resourcesAdapter.addOne,
    remove: resourcesAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResources.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchResources.fulfilled, (state, action) => {
        resourcesAdapter.setAll(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchResources.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(deleteResource.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(deleteResource.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(deleteResource.fulfilled, (state, action) => {
        resourcesAdapter.removeOne(state, action.payload.id);
        state.loadingStatus = 'loaded';
      })
      .addCase(createResource.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(createResource.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(createResource.fulfilled, (state, action) => {
        resourcesAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(updateResource.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(updateResource.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(updateResource.fulfilled, (state, action) => {
        resourcesAdapter.removeOne(state, action.payload.id);
        resourcesAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      });
  },
});

export const resourcesReducer = resourcesSlice.reducer;
export const resourcesActions = resourcesSlice.actions;

const { selectAll, selectEntities } = resourcesAdapter.getSelectors();

export const getResourcesState = (rootState) =>
  rootState[RESOURCES_FEATURE_KEY];

export const selectAllResources = createSelector(getResourcesState, selectAll);

export const selectResourcesEntities = createSelector(
  getResourcesState,
  selectEntities,
);

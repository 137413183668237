export const messageTypes = {
  error: 'error',
  info: 'info',
  success: 'success',
  warning: 'warning',
};

export const buttonVariants = {
  primary: 'button-primary',
  secondary: 'button-secondary',
  clear: 'button-clear',
};

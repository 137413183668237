import React from 'react';
import { Header, Footer, VideosTable } from '../../blocks';
import Section from '../../blocks/Section';
import SectionHeader from '../../blocks/SectionHeader';

export const AdditionalVideos = ({ data }) => {
  const page = data.pages.edges[0].node;
  const microsites = data.microsites.edges.map((e) => e.node);
  const pageId = page.id;
  const microsite = microsites.find(
    (ms) => ms.pages.find((p) => p.id === pageId) !== undefined,
  );
  const customerName = microsite.client_name;
  const pageIds = microsite.pages.map((p) => p.id);
  const videos = data.pages.edges[0].node.videos;
  let pageVideos = [];
  if (
    page.video_embeds &&
    Array.isArray(page.video_embeds) &&
    page.video_embeds.length
  ) {
    pageVideos = page.video_embeds.map((vid) => {
      const video = videos.find((v) => v.id === vid.id);

      return {
        ...video,
        category: video.video_category ? video.video_category.categoryName : '',
      };
    });
  }

  return (
    <>
      <Header customerName={customerName} pageIds={pageIds} nav={false} />
      <Section>
        <SectionHeader noPadding>Optum Video Library</SectionHeader>
        <p>You can preview, copy direct links or download videos below.</p>
        <VideosTable videos={pageVideos} />
      </Section>
      <Footer />
    </>
  );
};

export default AdditionalVideos;

import React from 'react';

// one small complication stems from needing
// this gatsby-provided graphql in order to be
// able to include the gatsby graphql query
// import { graphql } from "gatsby";

import {
  HeroBannerImage,
  FeaturedResources,
  Header,
  Footer,
} from '../../blocks';
import CustomerTeam from '../../blocks/CustomerTeam';
import Section from '../../blocks/Section';

// these components take a graphql query result
export const PartnerSite = ({ data }) => {
  const page = data.pages.edges[0].node;
  // this is a copy from fromStore.bioFromStore()
  const bios = page.bios.map((bio) => ({
    ...bio,
    name: bio.displayName,
    title: bio.titile,
    groupName: bio.group ? bio.group.groupName : '',
    image:
      bio.profilePicture && bio.profilePicture.formats
        ? bio.profilePicture.formats.thumbnail.url
        : '',
    srcImg:
      bio.profilePicture && bio.profilePicture.formats
        ? bio.profilePicture.formats.thumbnail.url
        : '',
  }));

  const resources = page.resources.map((resource) => ({
    ...resource,
    href: resource.link,
    target: '#',
    download: '#',
  }));

  // customer name comes from microsite.client_name
  const microsites = data.microsites.edges.map((e) => e.node);
  const pageId = page.id;
  const microsite = microsites.find(
    (microsite) =>
      microsite.pages.find((page) => page.id === pageId) !== undefined,
  );

  const customerName = microsite.client_name;
  const pageIds = microsite.pages.map((page) => page.id);
  const { bannerVideo, heroImageUrl, headline, introCopy } = JSON.parse(
    page.variables,
  );

  return (
    <div>
      <Header customerName={customerName} pageIds={pageIds} nav={true} />
      <HeroBannerImage
        bannerVideo={bannerVideo}
        headline={headline}
        heroImageUrl={heroImageUrl}
        introCopy={introCopy}
      />
      <Section backgroundColor="#efefee">
        <CustomerTeam customerName={customerName} bios={bios} />
      </Section>
      <Section>
        <FeaturedResources
          header="Your Featured Resources"
          resources={resources}
        />
      </Section>
      <Footer logo={microsite?.cobrand_logo?.url} />
    </div>
  );
};

export default PartnerSite;

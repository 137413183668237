import React from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  FormButtons,
  FormControl,
  Loader,
  TextControl,
} from '@clatter/ui';
import FileUpload from '../FileUpload/FileUpload';
import { addCobrandLogo } from '../../store';

const StyledCreateCompanyForm = styled.form`
  max-width: 480px;
`;

const CreateCompanyForm = ({ onSuccess, user }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    formState,
  } = useForm({
    mode: 'onChange',
  });

  const loading =
    useSelector((state) => state.cobrandLogos.loadingStatus) === 'loading';

  const onSubmit = async (formData) => {
    await dispatch(addCobrandLogo({ ...formData, email: user.email })).then(
      ({ payload }) => {
        onSuccess(payload);
      },
    );
  };

  return (
    <StyledCreateCompanyForm onSubmit={handleSubmit(onSubmit)}>
      {loading && <Loader />}
      <FormControl error={errors.file}>
        <Controller
          name="file"
          rules={{ required: { value: true, message: 'Field is required' } }}
          render={({ field: { onChange, value } }) => (
            <FileUpload onChange={onChange} value={value} />
          )}
          control={control}
          defaultValue={null}
        />
      </FormControl>
      <FormControl label="Company name" error={errors.company}>
        <TextControl
          {...register('company', {
            required: { value: true, message: 'Field is required' },
          })}
          placeholder="Enter company name"
        />
      </FormControl>
      <FormButtons>
        <Button disabled={!formState.isValid} type="submit">
          Upload
        </Button>
      </FormButtons>
    </StyledCreateCompanyForm>
  );
};

export default CreateCompanyForm;

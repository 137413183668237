import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '@clatter/ui';

const LogoutPageView = () => {
  const { logout } = useAuth0();

  logout({
    returnTo: window.location.origin,
  });

  return <Loader />;
};

export default LogoutPageView;

import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { linkColor, linkHoverColor } from '@clatter/ui';
import AppRoutes from './routes/AppRoutes';
import { themes } from '@clatter/ui';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 14px;
    font-family: 'Maven Pro', sans-serif;
  }

  body {
    overflow-y: scroll;
    background: #F4F7FC;
    margin: 0;
    line-height: 1.4;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 16px;
    padding: 0;
    font-weight: 400;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  p {
    margin: 0 0 16px;
  }

  a {
    text-decoration: none;
    color: ${linkColor};

    &:hover {
      color: ${linkHoverColor};
    }
  }
`;

const App = () => (
  <ThemeProvider theme={themes.default}>
    <GlobalStyle />
    <AppRoutes />
  </ThemeProvider>
);

export default App;

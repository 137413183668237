import React from 'react';
import styled from 'styled-components';
import { defaultBorderColor, primaryColor } from '../../config/theme';

const StyledStepCardHeader = styled.div`
  border-bottom: 1px solid ${defaultBorderColor};
  display: flex;
  padding: 16px 16px 16px 0;
  position: relative;
  align-items: center;

  .step-header-step {
    width: 120px;
    line-height: 46px;
    background-color: ${primaryColor};
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
    margin-left: -1px;
  }

  .step-header-content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const StepCardHeader = ({ children, step = 1 }) => (
  <StyledStepCardHeader>
    <div className="step-header-step">Step {step}</div>
    <div className="step-header-content">{children}</div>
  </StyledStepCardHeader>
);

export default StepCardHeader;

import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { Dialog } from '@clatter/ui';

const StyledVideoGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    ${(props) => `${props.videoSize.width}px`}
  );
  grid-auto-rows: minmax(
    ${(props) => `${props.videoSize.height + 48}px`},
    auto
  );
  gap: 16px;
  width: auto;
  height: auto;
  overflow-y: auto;
  justify-content: space-between;

  .video-gallery-item {
    .video {
      width: ${(props) => `${props.videoSize.width}px`};
      height: ${(props) => `${props.videoSize.height}px`};
      position: relative;
      background-color: #000;

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    .preview-video-button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        font-size: 18px;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.9);
        fill: #fff;
        width: 48px;
        height: 36px;
      }
    }

    .video-details {
      padding: 4px;

      .video-title {
        font-size: ${({ theme }) => theme.videoGallery.item.title.fontSize};
        line-height: 1.3;
        color: ${({ theme }) => theme.videoGallery.item.title.color};
        padding: 4px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .video-description {
        font-size: ${({ theme }) => theme.videoGallery.item.description.fontSize};
        color: ${({ theme }) => theme.videoGallery.item.description.color};
      }
    }
  }
`;

const VideoGallery = ({
  showDescription = false,
  urlProperty = 'link',
  videos = [],
  videoWidth = 200,
  videoAspectRatio = '16/9',
}) => {
  const [videoSize, setVideoSize] = useState(null);
  const [previewVideo, setPreviewVideo] = useState(null);

  useLayoutEffect(() => {
    const [widthRatio, heightRatio] = videoAspectRatio.split('/');
    const videoHeight = parseInt(
      (videoWidth / Number(widthRatio)) * Number(heightRatio),
    );
    setVideoSize({ width: videoWidth, height: videoHeight });
  }, [videoWidth, videoAspectRatio]);

  const handleVideoClick = (event) => {
    const clickedItemId = event.currentTarget.dataset.id;
    const nextPreviewVideo =
      videos.find((video) => video.id === clickedItemId) ||
      null;
    setPreviewVideo(nextPreviewVideo);
  };

  const renderVideos = () => {
    if (!videos || !videos.length) {
      return <p>No videos</p>;
    }

    return videos.map((video) => (
      <div className="video-gallery-item" key={video.id}>
        <div className="video">
          <div
            data-id={video.id}
            data-video-name={video.title}
            onClick={handleVideoClick}
            className="preview-video-button"
          >
            <PlayArrowIcon />
          </div>
          <iframe
            src={`${video[urlProperty]}?controls=0&transparent=0`}
            frameBorder="0"
            allow="fullscreen; picture-in-picture"
            allowFullScreen
          />
        </div>
        <div className="video-details">
          <div className="video-title">{video.title}</div>
          {showDescription && (
            <div className="video-description">
              {video.description || '(description not provided)'}
            </div>
          )}
        </div>
      </div>
    ));
  };

  if (!videoSize) {
    return null;
  }

  return (
    <StyledVideoGallery videoSize={videoSize}>
      {renderVideos()}
      <Dialog
        title={previewVideo?.title}
        onCloseDialog={handleVideoClick}
        open={!!previewVideo}
      >
        <iframe
          style={{ display: 'block' }}
          width="640"
          height="360"
          src={`${previewVideo?.link}?autoplay=1`}
          frameBorder="0"
          allow="fullscreen; picture-in-picture"
          allowFullScreen
        />
      </Dialog>
    </StyledVideoGallery>
  );
};

export default VideoGallery;

import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { EditorControl, getMaskedValue } from '@clatter/ui';

const StyledCustomerBio = styled.button`
  display: flex;
  align-self: baseline;
  outline: none;
  border: none;
  background-color: transparent;

  .team-bio-image {
    border-radius: 50%;
    width: 85px;
    height: 85px;
    background-size: cover;
    background-position: center center;
    background-image: ${({ image }) => (image ? `url(${image})` : 'none')};

    flex-shrink: 0;

    &.no-image {
      background-color: #f4f4f4;
      border: 1px solid #ddd;

      span {
        margin-top: 50%;
        display: block;
        transform: translateY(-50%);
      }
    }
  }

  .team-bio-details {
    text-align: left;
    padding-left: 12px;
    padding-top: 8px;

    .team-bio-name {
      font-size: 19px;
      font-weight: 600;
    }

    .team-bio-title {
      font-size: 14px;
      line-height: 1.2;
    }

    .team-bio-email {
      display: block;
      margin: 16px 0;
    }

    .team-bio-phone {
      display: block;
      margin: 16px 0;
    }

    .team-bio-description {
      display: block;
      margin: 16px 0 0;
      line-height: 1.4;
    }

    .team-bio-click {
      margin-top: 8px;
      color: #316bbe;
      text-decoration: underline;
    }
  }

  &.clickable {
    cursor: pointer;
  }
`;

const CustomerBio = ({
  description,
  email,
  name,
  image,
  onClickText,
  phone,
  title,
  onClick,
  ...dataAttributes
}) => (
  <StyledCustomerBio
    {...dataAttributes}
    type="button"
    image={image}
    onClick={onClick}
    className={`${typeof onClick === 'function' ? 'clickable' : ''}`}
  >
    <div className={classNames('team-bio-image', { 'no-image': !image })}>
      {!image && <span>No image</span>}
    </div>
    <div className="team-bio-details">
      <div className="team-bio-name">{name}</div>
      <div className="team-bio-title">{title}</div>
      {!!onClickText && <div className="team-bio-click">{onClickText}</div>}
      {!!email && (
        <a className="team-bio-email" href={`mailto: ${email}`}>
          {email}
        </a>
      )}
      {!!phone && (
        <p className="team-bio-phone">
          {getMaskedValue('xxx-xxx-xxxx', phone)}
        </p>
      )}
      {!!description && (
        <div className="team-bio-description">
          <EditorControl readOnly value={description} />
        </div>
      )}
    </div>
  </StyledCustomerBio>
);

export default CustomerBio;

import React from 'react';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';

const StyledResourcesBlock = styled.div`
  background: thistle;
`;

const StyledBlockHead = styled.p`
  font-size: large;
  font-weight: 500;
  color: black;
`;

const StyleResourceLink = styled.a`
  color: black;
`;

const placeholderStyle = {
  backgroundColor: 'lightblue',
  width: '100%',
  height: '100px',
  maxHeight: '100px',
  textAlign: 'center',
  display: 'inlineBlock',
  lineHeight: '100px',
  margin: '12px',
};

const BlockPlaceholder = (props) => {
  const { name } = props;
  return (
    <div style={placeholderStyle}>
      <div>
        {`${name} block is not configured`}. To add a Resource to the Resources
        Block, click one of the Available Resources
      </div>
    </div>
  );
};

const ResourceLink = styled.a`
  font-weight: 500;
  font-size: medium;
  color: black;
  margin-bottom: 0.1em;
  text-decoration: underline;
`;

const ResourceDescription = styled.p`
  font-weight: 400;
  font-size: small;
  color: gray;
`;

/*
const Resource = (props) => {
  let { href, title, description } = props;
  if (!description) {
    description = '[description]';
  }
  return (
    <div>
      <ResourceLink href={href} title={title} target="_blank" download="">
        {title}
      </ResourceLink>
      <ResourceDescription>{description}</ResourceDescription>
    </div>
    // <Card style={resourceStyle}>
    //   <Box textAlign="left" fontWeight="fontWeightMedium" fontSize={16}>
    //       {title}
    //   </Box>
    //   <Typography variant="body2" colory='textSecondary' noWrap>
    //     {description}
    //   </Typography>
    // </Card>
  );
};
*/

const Resources = (props) => {
  let { resources: _r } = props;
  return (
    <>
      {_resource.map((r, index) => (
        <Resource
          href={resource.href}
          title={resource.name}
          description={resource.description}
        />
      ))}
    </>
  );
};

const ResourcesBlock = ({ resources }) => {
  const blockName = 'Resources';
  return (
    <div style={{ flexGrow: 1, marginTop: '0px' }}>
      {resources.length > 0 ? (
        <Resources resources={resources} />
      ) : (
        <BlockPlaceholder name={blockName} />
      )}
    </div>
  );
};

const theme = {
  main: 'defaulttheme',
};

const Wrapper = styled.div`
  /*  width: 82.5%; */
  /*  margin: 0 auto; */
  padding: 5%;
  background: #efefee;
`;

//const StyledResourcesBlock = styled.div``;
const MoreResourcesButton = styled.div`
  text-align: end;
  margin-top: 27px;

  a {
    padding: 12px 29px;
    /* text-decoration: none !important; */
    border-bottom: none !important;
    color: white !important;
    /* font-size: 20px; */
    background-color: #316bbe;
    border-radius: 50px;
  }
`;

//background: papayawhip;

const StyledResourceBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  margin-top: 0;
  align-items: top;

  p {
    width: 54%;
    margin: 10px 0;
  }

  ul {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  li {
    width: 23%;
    margin-bottom: 50px;
  }

  a {
    display: inline-block;
    color: #006699;
    text-decoration: none;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 18px;
  }

  a span {
    border-bottom: 1px solid #006699;
  }

  h3 {
    font-weight: 500;
    margin-bottom: 0.75em;
  }
`;

const ResourceContainer = styled.div`
  border-bottom: 1px solid silver;
  padding-bottom: 10px;
  margin: 12px;
`;

const Resource = ({ href, title, target, download, description }) => (
  <>
    <a href={href} title={title} target={target} download={download}>
      <span>{title}</span>
    </a>
    <p>{description}</p>
  </>
);

const mockResources = [
  {
    href:
      'https://clatter-lpmaker-prod-0.s3.amazonaws.com/Optum_Rx_Subject_Matter_Experts_SME_Listing_bc653b3e55.pdf',
    title: 'OptumRx Subject Matter Experts (SME) Listing',
    target: '_blank',
    download: '',
    description: '[default description]',
  },
  {
    href:
      'https://clatter-lpmaker-prod-0.s3.amazonaws.com/Optum_Rx_Presentation_Support_Submission_Form_e1a58c5314.pdf',
    title: 'OptumRx Presentation Support Submission Form',
    target: '_blank',
    download: '',
    description: '[default description]',
  },
];

const ResourcesVertical = ({
  resources,
  header = 'Resources',
  moreResources = false,
}) => (
  <Wrapper>
    <StyledResourceBlock>
      {resources.map((resource, index) => (
        <ResourceContainer key={resource.link}>
          <Resource
            title={resource.title}
            href={resource.link}
            description={resource.description}
            target={resource.target}
            download={resource.download}
          />
        </ResourceContainer>
      ))}
      {moreResources && (
        <MoreResourcesButton>
          <a href="" className="more-resources">
            more resources
          </a>
        </MoreResourcesButton>
      )}
    </StyledResourceBlock>
  </Wrapper>
);

export default ResourcesVertical;

import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  FormButtons,
  FormControl,
  Loader,
  SelectControl,
  TextControl,
} from '@clatter/ui';
import AdminContentHead from '../AdminContentHead';
import {
  fetchMicrositesSettings,
  headerOptions,
  footerVersionOptions,
  updateMicrositesSettings,
} from '../../../store/microsites-settings.slice';

const MicrositesSettings = () => {
  const dispatch = useDispatch();
  const {
    control,
    formState: { errors },
    formState,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    dispatch(fetchMicrositesSettings());
  }, []);

  const micrositesSettings = useSelector(
    ({ micrositesSettings }) => micrositesSettings.config,
  );

  const micrositesSettingsLoading = useSelector(
    ({ micrositesSettings }) => micrositesSettings.loadingStatus === 'loading',
  );

  useEffect(() => {
    if (micrositesSettingsLoading || !micrositesSettings) {
      return;
    }

    reset(micrositesSettings);
  }, [micrositesSettingsLoading, micrositesSettings]);

  const handleFormSubmit = (formData) => {
    dispatch(updateMicrositesSettings({ config: formData }));
  };

  return (
    <>
      {micrositesSettingsLoading && <Loader />}
      <AdminContentHead>
        <h1>Microsites Settings</h1>
      </AdminContentHead>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <FormControl label="Header extra item">
          <Controller
            name="headerExtraItem"
            render={({ field: { onChange, value } }) => (
              <SelectControl
                onChange={(option) => {
                  onChange(option.value);

                  if (option.value === 'text') {
                    setValue('headerText', 'Some random text');
                  }
                }}
                options={headerOptions}
                value={headerOptions.find((option) => option.value === value)}
              />
            )}
            control={control}
          />
        </FormControl>
        {watch('headerExtraItem') === 'text' && (
          <FormControl label="Header text" error={errors.headerText}>
            <TextControl
              {...register('headerText', {
                required: { value: true, message: 'Field is required' },
              })}
              placeholder="Enter the header text"
              maxLength={120}
            />
          </FormControl>
        )}
        <FormControl label="Brand logo">
          <Checkbox
            text="Display brand logo in the footer (next to bio)"
            {...register('showBrandLogoInBrandFooter')}
          />
        </FormControl>
        <FormButtons>
          <Button disabled={!formState.isDirty} type="submit">
            Save
          </Button>
        </FormButtons>
      </form>
    </>
  );
};

export default MicrositesSettings;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import {
  Button,
  EditorControl,
  FormButtons,
  FormControl,
  IconButton,
  Loader,
  MaskedControl,
  SelectControl,
  TextControl,
} from '@clatter/ui';
import FileUpload from '../../FileUpload/FileUpload';
import { updateBio } from '../../../store/bios.slice';
import {
  fetchBioGroups,
  selectAllBioGroups,
} from '../../../store/bio-groups.slice';
import { patterns } from '../../../helpers';
import { acceptedFileTypes } from './config';
import { validateUploadedPhoto } from './validators';

const StyledEditBioForm = styled.form`
  width: 480px;

  .profile-picture {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-position: center center;
    background-size: cover;
    margin: 0 auto 16px;
  }
`;

const EditBioForm = ({ editableItem, onSuccess }) => {
  const dispatch = useDispatch();
  const [showUploadForm, setShowUploadForm] = useState(
    !editableItem?.profilePicture?.formats?.thumbnail?.url,
  );
  const {
    control,
    formState: { errors },
    formState,
    handleSubmit,
    register,
  } = useForm({
    mode: 'onChange',
    defaultValues: editableItem || {},
  });

  useEffect(() => {
    dispatch(fetchBioGroups());
  }, [dispatch]);

  const bioGroups = useSelector(selectAllBioGroups);

  const onSubmit = async (formData) => {
    dispatch(
      updateBio({
        ...formData,
        id: editableItem.id,
      }),
    );
    onSuccess();
  };

  const handleShowUploadForm = () => {
    setShowUploadForm(true);
  };

  if (!bioGroups || !bioGroups.length) {
    return <Loader />;
  }

  return (
    <StyledEditBioForm onSubmit={handleSubmit(onSubmit)}>
      {!showUploadForm ? (
        <div
          className="profile-picture"
          style={{
            backgroundImage: `url(${editableItem?.profilePicture?.formats?.thumbnail?.url})`,
          }}
        >
          <IconButton onClick={handleShowUploadForm}>
            <CloseOutlinedIcon />
          </IconButton>
        </div>
      ) : (
        <FormControl error={errors.file}>
          <Controller
            name="file"
            rules={{
              validate: validateUploadedPhoto,
            }}
            render={({ field: { onChange, value } }) => (
              <FileUpload
                acceptedFileTypes={acceptedFileTypes}
                onChange={onChange}
                value={value}
              />
            )}
            control={control}
            defaultValue={null}
          />
        </FormControl>
      )}
      <FormControl label="First name" error={errors.firstName}>
        <TextControl
          {...register('firstName', {
            required: { value: true, message: 'Field is required' },
          })}
          placeholder="Enter first name"
        />
      </FormControl>
      <FormControl label="Last name" error={errors.lastName}>
        <TextControl
          {...register('lastName', {
            required: { value: true, message: 'Field is required' },
          })}
          placeholder="Enter last name"
        />
      </FormControl>
      <FormControl label="Title" error={errors.title}>
        <TextControl
          {...register('title', {
            required: { value: true, message: 'Field is required' },
          })}
          placeholder="Enter title"
        />
      </FormControl>
      <FormControl label="E-mail" error={errors.email}>
        <TextControl
          {...register('email', {
            pattern: {
              value: patterns.email,
              message: 'Invalid e-mail address',
            },
          })}
          placeholder="Enter e-mail address"
          type="email"
        />
      </FormControl>
      <FormControl label="Phone" error={errors.phone}>
        <Controller
          name="phone"
          rules={{
            validate: (value) => {
              if (value && value.length !== 10) {
                return 'Invalid phone numer';
              }
            },
          }}
          render={({ field: { onChange, value } }) => (
            <MaskedControl
              onChange={onChange}
              value={value}
              type="tel"
              placeholder="Enter phone number"
              mask="xxx-xxx-xxxx"
            />
          )}
          control={control}
          defaultValue=""
        />
      </FormControl>
      <FormControl label="Description" error={errors.description}>
        <Controller
          name="description"
          render={({ field: { onChange, value } }) => (
            <EditorControl onChange={onChange} value={value} />
          )}
          control={control}
        />
      </FormControl>
      <FormControl label="Group">
        <Controller
          name="group"
          render={({ field: { onChange, value } }) => (
            <SelectControl
              onChange={onChange}
              options={bioGroups}
              value={value}
              getOptionLabel={(option) => option.groupName}
              getOptionValue={(option) => option.id}
            />
          )}
          control={control}
        />
      </FormControl>
      <FormButtons>
        <Button disabled={!formState.isValid} type="submit">
          Save
        </Button>
      </FormButtons>
    </StyledEditBioForm>
  );
};

export default EditBioForm;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import { compareIdentifiers } from '../../helpers';
import BioCard from '../BioCard/BioCard';
import { FormControl, TextControl, SelectControl } from '@clatter/ui';

const StyledSingleListBioPicker = styled.div`
  .bio-filters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
    gap: 16px;
  }

  .bio-list {
    display: grid;
    gap: 8px;
    max-height: 240px;
    overflow-y: auto;
    padding: 0 8px 0 0;
  }
`;

const SingleListBioPicker = ({
  multiple = false,
  onChange,
  options,
  value,
}) => {
  if (!options || !options.length) {
    return null;
  }

  const [filteredBios, setFilteredBios] = useState([]);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [groupFilter, setGroupFilter] = useState('');

  useEffect(() => {
    const nextAvailableGroups = [
      ...new Set(options.map((bio) => bio.groupName)),
    ]
      .filter((group) => !!group)
      .map((group) => ({
        label: group,
        value: group,
      }));
    setAvailableGroups(nextAvailableGroups);
  }, [options]);

  useEffect(() => {
    let nextFilteredOptions = options;

    if (groupFilter) {
      nextFilteredOptions = nextFilteredOptions.filter(
        (bio) => bio.groupName === groupFilter.value,
      );
    }

    if (searchTerm) {
      nextFilteredOptions = nextFilteredOptions.filter((bio) =>
        bio.name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    setFilteredBios(nextFilteredOptions);
  }, [value, searchTerm, groupFilter]);

  const isItemSelected = (item) => {
    if (multiple) {
      return (
        value &&
        Array.isArray(value) &&
        value.some((bio) => compareIdentifiers(bio.id, item.id))
      );
    }

    return value && 'id' in value && compareIdentifiers(value.id, item.id);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleGroupFilterChange = (option) => {
    setGroupFilter(option);
  };

  const handleItemClick = (event) => {
    const clickedOption = options.find((item) =>
      compareIdentifiers(item.id, event.currentTarget.dataset.id),
    );
    let nextValue;

    if (!clickedOption) {
      return;
    }

    if (multiple) {
      nextValue = cloneDeep(value);

      if (value.some((item) => compareIdentifiers(item.id, clickedOption.id))) {
        nextValue = nextValue.filter(
          (item) => !compareIdentifiers(item.id, clickedOption.id),
        );
      } else {
        nextValue.push(cloneDeep(clickedOption));
      }
    } else {
      nextValue = clickedOption;
    }
    onChange(cloneDeep(nextValue));
  };

  return (
    <StyledSingleListBioPicker>
      <div className="bio-filters">
        <FormControl>
          <TextControl
            onChange={handleSearchTermChange}
            placeholder="Search bios"
            value={searchTerm}
          />
        </FormControl>
        <FormControl>
          <SelectControl
            onChange={handleGroupFilterChange}
            options={availableGroups}
            placeholder="Select a group"
            value={groupFilter}
          />
        </FormControl>
      </div>
      <div className="bio-list">
        {filteredBios.map((bio) => (
          <BioCard
            selected={isItemSelected(bio)}
            key={bio.name}
            details={bio}
            onClick={handleItemClick}
          />
        ))}
      </div>
    </StyledSingleListBioPicker>
  );
};

export default SingleListBioPicker;

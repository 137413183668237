import React from 'react';
import styled from 'styled-components';

const StyledFormButtons = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;

  > * {
    margin-right: 16px;
  }
`;

const FormButtons = ({ children }) => (
  <StyledFormButtons>{children}</StyledFormButtons>
);

export default FormButtons;

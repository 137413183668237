import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Button, DataTable, Dialog, IconButton, Loader } from '@clatter/ui';
import AdminContentHead from '../AdminContentHead';
import ResourceForm from './ResourceForm';
import {
  fetchResources,
  deleteResource,
  selectAllResources,
} from '../../../store/resources.slice';
import { formatDate } from '../../../helpers';

const resourceColumns = [
  {
    label: 'Title',
    name: 'title',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Description',
    name: 'description',
    align: 'left',
  },
  {
    label: 'Group name',
    customRender: (row) => row.resource_category?.categoryName,
    align: 'left',
  },
  {
    label: 'Date added',
    name: 'createdAt',
    customRender: (row) => formatDate(row.createdAt, true),
    align: 'left',
    sortable: true,
  },
];

const Resources = () => {
  const [editableItem, setEditableItem] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchResources());
  }, [dispatch]);

  const resources = useSelector(selectAllResources);

  const loading = useSelector(
    (state) => state.resources.loadingStatus === 'loading',
  );

  const handleEditClick = (event) => {
    const clickedItemId = event.currentTarget?.dataset?.id;

    setEditableItem(
      (clickedItemId &&
        resources.find((resource) => resource.id === clickedItemId)) ||
        {},
    );
  };

  const handleDismissEdit = () => {
    setEditableItem(null);
  };

  const handleDeleteClick = (event) => {
    if (window.confirm('Are you sure you want to remove this resource?')) {
      dispatch(deleteResource(event.currentTarget.dataset.id));
    }
  };

  const isEdit = editableItem && 'title' in editableItem;

  return (
    <>
      {loading && <Loader />}
      <AdminContentHead>
        <h1>Resources</h1>
        <Button onClick={handleEditClick}>Add resource</Button>
      </AdminContentHead>
      <div>
        <DataTable
          autofit
          columns={resourceColumns}
          defaultSortField="title"
          filterColumns={['title']}
          name="adminResources"
          rows={resources}
          renderActionColumn={(row) => (
            <>
              <IconButton data-id={row.id} onClick={handleEditClick}>
                <EditOutlinedIcon />
              </IconButton>
              <IconButton data-id={row.id} onClick={handleDeleteClick}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </>
          )}
        />
      </div>
      <Dialog
        title={isEdit ? 'Edit resource' : 'Add resource'}
        onCloseDialog={handleDismissEdit}
        open={!!editableItem}
      >
        <ResourceForm
          isEdit={isEdit}
          editableItem={editableItem}
          onSuccess={handleDismissEdit}
        />
      </Dialog>
    </>
  );
};

export default Resources;

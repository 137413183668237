import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { messageTypes } from '../../config/consts';

const StyledFormMessage = styled.div`
  display: flex;
  align-items: center;
  line-height: 16px;
  font-size: 14px;
  padding: 8px 8px 8px 16px;
  color: #fff;
  border-radius: 2px;

  &.message-error {
    background-color: #fe615a;
  }

  &.message-info {
    background-color: #1890ff;
  }

  &.message-success {
    background-color: #51bc6a;
  }

  &.message-warning {
    background-color: #ffb103;
  }

  button {
    background-color: transparent;
    outline: none;
    border: none;
    margin: 0 0 0 24px;
    padding: 0;
    cursor: pointer;

    svg {
      display: block;
      font-size: 20px;
      fill: #fff;
    }
  }
`;

const FormMessage = ({ message: { type, text }, onDismiss }) => {
  useEffect(() => {
    return () => {
      onDismiss?.();
    };
  }, []);

  return (
    <StyledFormMessage className={`message-${type}`}>
      <span>{text}</span>
      {typeof onDismiss === 'function' && (
        <button type="button" onClick={onDismiss}>
          <ClearOutlinedIcon />
        </button>
      )}
    </StyledFormMessage>
  );
};

FormMessage.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.keys(messageTypes)).isRequired,
  }).isRequired,
  onDismiss: PropTypes.func,
};

export default FormMessage;

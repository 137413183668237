import axios from 'axios';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { messageTypes } from '@clatter/ui';
import { hasRole } from '@clatter/platform';
import { createMessage, messagesActions } from './messages.slice';
import { getCopyName } from '../helpers';
import { pagesAdapter } from './pages.slice';
import { userRolesMap } from '../constants';

export const MICROSITES_FEATURE_KEY = 'microsites';
export const micrositesAdapter = createEntityAdapter();
const apiBaseUrl = process.env.NX_CMS_HOST;

const getUserByEmail = async (email) => {
  const response = await axios.get(
    `${apiBaseUrl}/users?_where[email]=${email}`,
  );
  const user = response.data[0];
  return user;
};

const getNewSiteName = () => {
  return 'Default Microsite ' + Math.random().toString(36).slice(-4);
};

export const createMicrosite = createAsyncThunk(
  `${MICROSITES_FEATURE_KEY}/create`,
  async (email) => {
    const response = await axios.post(`${apiBaseUrl}/microsites`, {
      name: getNewSiteName(),
      published: false,
      pages: [],
      users_permissions_user: (await getUserByEmail(email))?.id,
    });

    return Promise.resolve(response.data);
  },
);

export const updateMicrosite = createAsyncThunk(
  `${MICROSITES_FEATURE_KEY}/update`,
  async (microsite, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${apiBaseUrl}/microsites/${microsite.id}`,
        microsite,
      );

      // dispatch(
      //   messagesActions.setMessage({
      //     message: createMessage(
      //       messageTypes.success,
      //       'Site has been saved.',
      //       'updateMicrosite',
      //     ),
      //   }),
      // );

      return response.data;
    } catch (error) {
      dispatch(
        messagesActions.setMessage({
          message: createMessage(
            messageTypes.error,
            'There was an error saving the microsite. Try changing the name and Save again.',
            'updateMicrosite',
          ),
        }),
      );
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchMicrosites = createAsyncThunk(
  `${MICROSITES_FEATURE_KEY}/fetch`,
  async (user = null, fetchAll = false) => {
    const url =
      (user && hasRole(userRolesMap.admin, user)) || fetchAll
        ? `${apiBaseUrl}/microsites`
        : `${apiBaseUrl}/microsites?_where%5Busers_permissions_user.email%5D=${user.email}`;
    const response = await axios.get(url);

    return Promise.resolve(response.data);
  },
);

export const fetchMicrositeById = createAsyncThunk(
  `${MICROSITES_FEATURE_KEY}/fetchSingle`,
  async (micrositeId) => {
    const response = await axios.get(`${apiBaseUrl}/microsites/${micrositeId}`);

    return Promise.resolve(response.data);
  },
);

export const deleteMicrosite = createAsyncThunk(
  `${MICROSITES_FEATURE_KEY}/delete`,
  async (microsite) => {
    const deletedPages = await Promise.all(
      microsite.pages.map((page) =>
        axios.delete(`${apiBaseUrl}/pages/${page.id}`),
      ),
    );
    const deletedMicrosite = await axios.delete(
      `${apiBaseUrl}/microsites/${microsite.id}`,
    );

    return Promise.resolve({
      pageIds: deletedPages.map((response) => response.data.id),
      micrositeId: deletedMicrosite.data.id,
    });
  },
);

export const deleteMicrosites = createAsyncThunk(
  `${MICROSITES_FEATURE_KEY}/deleteBulk`,
  async (microsites) => {
    const deletedPages = await Promise.all(
      microsites
        .reduce(
          (acc, microsite) => [...acc, ...microsite.pages.map(({ id }) => id)],
          [],
        )
        .map((pageId) => axios.delete(`${apiBaseUrl}/pages/${pageId}`)),
    );

    const deletedMicrosites = await Promise.all(
      microsites.map((microsite) =>
        axios.delete(`${apiBaseUrl}/microsites/${microsite.id}`),
      ),
    );

    return Promise.resolve({
      pageIds: deletedPages.map((response) => response.data.id),
      micrositeIds: deletedMicrosites.map((response) => response.data.id),
    });
  },
);

export const cloneMicrosite = createAsyncThunk(
  `${MICROSITES_FEATURE_KEY}/clone`,
  async (microsite) => {
    const newPagesRequest = await Promise.all(
      microsite.pages.map((page) =>
        axios.post(`${apiBaseUrl}/pages`, { ...page, blocks: '[]' }),
      ),
    );
    const micrositeClone = cloneDeep(microsite);
    micrositeClone.name = getCopyName(micrositeClone.name);
    micrositeClone.pages = newPagesRequest.map(({ data: page }) => page.id);
    const createdPage = await axios.post(
      `${apiBaseUrl}/microsites`,
      micrositeClone,
    );
    return Promise.resolve(createdPage.data);
  },
);

export const initialMicrositesState = micrositesAdapter.getInitialState({
  loadingStatus: 'loading',
  error: null,
});

export const micrositesSlice = createSlice({
  name: MICROSITES_FEATURE_KEY,
  initialState: initialMicrositesState,
  reducers: {
    add: micrositesAdapter.addOne,
    remove: micrositesAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMicrosites.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchMicrosites.fulfilled, (state, action) => {
        micrositesAdapter.setAll(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchMicrosites.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(updateMicrosite.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(updateMicrosite.fulfilled, (state, action) => {
        state.entities[action.payload.id] = action.payload;
        state.loadingStatus = 'loaded';
      })
      .addCase(updateMicrosite.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(deleteMicrosite.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(deleteMicrosite.fulfilled, (state, action) => {
        micrositesAdapter.removeOne(state, action.payload.micrositeId);
        pagesAdapter.removeMany(state, action.payload.pageIds);
        state.loadingStatus = 'loaded';
      })
      .addCase(deleteMicrosites.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(deleteMicrosites.fulfilled, (state, action) => {
        micrositesAdapter.removeMany(state, action.payload.micrositeIds);
        pagesAdapter.removeMany(state, action.payload.pageIds);
        state.loadingStatus = 'loaded';
      })
      .addCase(cloneMicrosite.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(cloneMicrosite.fulfilled, (state, action) => {
        state.entities[action.payload.id] = action.payload;
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchMicrositeById.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchMicrositeById.fulfilled, (state, action) => {
        micrositesAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      });
  },
});

export const micrositesReducer = micrositesSlice.reducer;
export const micrositesActions = micrositesSlice.actions;

const { selectAll, selectEntities } = micrositesAdapter.getSelectors();

export const getMicrositesState = (rootState) =>
  rootState[MICROSITES_FEATURE_KEY];

export const selectAllMicrosites = createSelector(
  getMicrositesState,
  selectAll,
);

export const selectMicrositesEntities = createSelector(
  getMicrositesState,
  selectEntities,
);

import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { generatePath, NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import PublishIcon from '@material-ui/icons/Publish';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { DataGrid, IconButton, Button, Loader } from '@clatter/ui';
import { hasRole } from '@clatter/platform';
import {
  fetchCobrandLogos,
  fetchMicrosites,
  fetchPages,
  fetchPageTemplates,
} from '../../store';
import {
  cloneMicrosite,
  createMicrosite,
  deleteMicrosite,
  deleteMicrosites,
} from '../../store/microsites.slice';
import { formatDate, isSiteComplete } from '../../helpers';
import { pageFromStore } from '../fromStore';
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import { userRolesMap } from '../../constants';
import routes from '../../routes/routes';

const StyledMyMicrosites = styled.div`
  max-width: 1280px;
  margin: 0 auto;

  .my-microsites-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin: 0;
    }
  }
`;

const MyMicrosites = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, isAuthenticated, isLoading } = useAuth0();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userIsAdmin = useMemo(() => hasRole(userRolesMap.admin, user), []);

  useEffect(() => {
    dispatch(fetchPageTemplates());
    dispatch(fetchPages());
    dispatch(fetchMicrosites(user));
    dispatch(fetchCobrandLogos());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const loading = useSelector(
    (state) =>
      state.microsites.loadingStatus === 'loading' ||
      state.pages.loadingStatus === 'loading',
  );

  const microsites = useSelector(({ microsites }) =>
    Object.values(microsites.entities),
  ).sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1));

  const pageTemplates = useSelector(selectAllPageTemplates);

  // @todo this useMemo is a recommended (by someone) technique (the MIT guys I think)
  // I'm not convinced that it's appropriate here, but I'm giving it a try.
  const micrositesMemo = React.useMemo(
    () =>
      microsites.map((microsite) => ({
        ...microsite,
        owner: microsite.users_permissions_user?.email,
        pages: (microsite.pages || []).map((page) =>
          pageFromStore(page, pageTemplates),
        ),
      })),
    [microsites, pageTemplates],
  );

  const handleDeleteItem = async (event) => {
    if (window.confirm('Are you sure you want to remove this microsite?')) {
      const micrositeToDelete = micrositesMemo.find(
        ({ id }) => id === event.currentTarget.dataset.id,
      );

      await dispatch(deleteMicrosite(micrositeToDelete));
    }
  };

  const handleDeleteItems = (items, clearSelection) => async () => {
    if (
      window.confirm('Are you sure you want to remove selected microsites?')
    ) {
      const micrositesToDelete = items.map((micrositeId) =>
        micrositesMemo.find(({ id }) => id === micrositeId),
      );

      await dispatch(deleteMicrosites(micrositesToDelete));
      clearSelection();
    }
  };

  const handleCloneItem = (event) => {
    const micrositeToClone = micrositesMemo.find(
      ({ id }) => id === event.currentTarget.dataset.id,
    );

    dispatch(cloneMicrosite(micrositeToClone));
  };

  const handlePublishItem = (event) => {
    history.push(generatePath(routes.publishSite, { siteId: event.currentTarget.dataset.id }));
  };

  const handleNavigateToPreview = (event) => {
    const microsite = micrositesMemo.find(
      ({ id }) => id === event.currentTarget.dataset.id,
    );

    window.open(
      `${process.env.NX_PREVIEW_HOST}/api/preview?secret=MY_SECRET_TOKEN&slug=sites/${microsite.name}/${microsite.pages[0].name}`,
      '_blank',
      'noopener',
    );
  };

  const handleNewClick = () => {
    dispatch(createMicrosite(user.email)).then((res) => {
      history.push(generatePath(routes.siteSettings, { siteId: res.payload.id }));
    });
  };

  const handleRowClick = (item, event) => {
    if (event.target.classList.contains('MuiDataGrid-cell')) {
      history.push(generatePath(routes.siteSettings, { siteId: item.row.id }));
    }
  };

  const micrositesColumns = useMemo(() => {
    let nextMicrositesColumns = [
      {
        field: 'name',
        headerName: 'Microsite Name',
        flex: 3,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 2,
        renderCell: (item) => {
          if (
            (!item.row.published || userIsAdmin) &&
            isSiteComplete(item.row, item.row.pages)
          ) {
            return (
              <NavLink to={`/${item.id}/publish`}>
                {item.row.published ? 'Published' : 'Not Published'}
              </NavLink>
            );
          }

          return item.row.published ? 'Published' : 'Not Published';
        },
        sortComparator: (v1, v2, param1, param2) =>
          param1.api.getCellValue(param1.id, 'published') -
          param2.api.getCellValue(param2.id, 'published'),
      },
      {
        field: 'numPages',
        headerName: '# of Pages',
        flex: 1,
        valueGetter: ({ row }) => row?.pages?.length,
        sortComparator: (v1, v2, param1, param2) =>
          (param1.api.getCellValue(param1.id, 'pages')?.length || 0) -
          (param2.api.getCellValue(param2.id, 'pages')?.length || 0),
      },
      {
        field: 'createdAt',
        headerName: 'Date Created',
        flex: 2,
        valueFormatter: (params) => formatDate(params.value),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 2,
        sortable: false,
        renderCell: (item) => (
          <>
            <IconButton
              disabled={!item.row?.pages?.length}
              data-id={item.id}
              onClick={handleNavigateToPreview}
              tooltip="Preview"
            >
              <VisibilityOutlinedIcon />
            </IconButton>
            <IconButton
              disabled={
                !item.row?.pages?.length ||
                !isSiteComplete(item.row, item.row.pages)
              }
              data-id={item.id}
              onClick={handlePublishItem}
              tooltip="Publish"
            >
              <PublishIcon />
            </IconButton>
            <IconButton
              data-id={item.id}
              onClick={handleCloneItem}
              tooltip="Clone"
            >
              <FileCopyOutlinedIcon />
            </IconButton>
            <IconButton
              data-id={item.id}
              onClick={handleDeleteItem}
              tooltip="Delete"
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </>
        ),
      },
    ];

    if (userIsAdmin) {
      nextMicrositesColumns = [
        ...nextMicrositesColumns.slice(0, 1),
        {
          field: 'owner',
          headerName: 'Owner',
          flex: 3,
        },
        ...nextMicrositesColumns.slice(1),
      ];
    }

    return nextMicrositesColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsAdmin, micrositesMemo]);

  const renderBulkActions = (selectedItems, clearSelection) => (
    <IconButton
      onClick={handleDeleteItems(selectedItems, clearSelection)}
      tooltip="Delete selected"
    >
      <DeleteOutlinedIcon />
    </IconButton>
  );

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }

  if (!isAuthenticated) {
    // shouldn't happen, as this component is only displayed in a ProtectedRoute
    return <div>Not Authorized.</div>;
  }

  return (
    <StyledMyMicrosites>
      {loading && <Loader />}
      <div className="my-microsites-head">
        <h1>Your Microsites</h1>
        <Button onClick={handleNewClick}>New</Button>
      </div>
      <p>
        Please select New to create a microsite or click the microsite name
        below to edit an existing microsite.
      </p>
      <DataGrid
        renderBulkActions={renderBulkActions}
        columns={micrositesColumns}
        rows={micrositesMemo}
        name="myMicrosites"
        onRowClick={handleRowClick}
        selectable
      />
    </StyledMyMicrosites>
  );
};
export default MyMicrosites;

import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
export const BIO_GROUPS_FEATURE_KEY = 'bioGroups';
export const bioGroupsAdapter = createEntityAdapter();
const baseUrl = `${process.env.NX_CMS_HOST}/groups`;

export const fetchBioGroups = createAsyncThunk(
  `${BIO_GROUPS_FEATURE_KEY}/fetch`,
  async () => {
    const response = await axios.get(`${baseUrl}?_sort=groupName:ASC`);

    return Promise.resolve(response.data);
  },
);

export const createBioGroup = createAsyncThunk(
  `${BIO_GROUPS_FEATURE_KEY}/create`,
  async (group) => {
    const response = await axios.post(baseUrl, group);

    return Promise.resolve(response.data);
  },
);

export const updateBioGroup = createAsyncThunk(
  `${BIO_GROUPS_FEATURE_KEY}/update`,
  async ({ id, formData }) => {
    const response = await axios.put(`${baseUrl}/${id}`, formData);

    return Promise.resolve(response.data);
  },
);

export const deleteBioGroup = createAsyncThunk(
  `${BIO_GROUPS_FEATURE_KEY}/delete`,
  async (id) => {
    const response = await axios.delete(`${baseUrl}/${id}`);

    return Promise.resolve(response.data);
  },
);

export const initialBioGroupsState = bioGroupsAdapter.getInitialState({
  loadingStatus: 'loading',
  error: null,
});

export const bioGroupsSlice = createSlice({
  name: BIO_GROUPS_FEATURE_KEY,
  initialState: initialBioGroupsState,
  reducers: {
    add: bioGroupsAdapter.addOne,
    remove: bioGroupsAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBioGroups.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchBioGroups.fulfilled, (state, action) => {
        bioGroupsAdapter.setAll(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchBioGroups.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(createBioGroup.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(createBioGroup.fulfilled, (state, action) => {
        bioGroupsAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(createBioGroup.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(updateBioGroup.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(updateBioGroup.fulfilled, (state, action) => {
        bioGroupsAdapter.removeOne(state, action.payload.id);
        bioGroupsAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(updateBioGroup.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(deleteBioGroup.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(deleteBioGroup.fulfilled, (state, action) => {
        bioGroupsAdapter.removeOne(state, action.payload.id);
        state.loadingStatus = 'loaded';
      })
      .addCase(deleteBioGroup.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      });
  },
});

export const bioGroupsReducer = bioGroupsSlice.reducer;
export const bioGroupsActions = bioGroupsSlice.actions;

const { selectAll, selectEntities } = bioGroupsAdapter.getSelectors();

export const getBioGroupsState = (rootState) =>
  rootState[BIO_GROUPS_FEATURE_KEY];

export const selectAllBioGroups = createSelector(getBioGroupsState, selectAll);

export const selectBioGroupsEntities = createSelector(
  getBioGroupsState,
  selectEntities,
);

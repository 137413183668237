import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { cloneDeep } from 'lodash';
import {
  Button,
  ControlError,
  Dialog,
  FormControl,
  IconButton,
  StepCard,
  StepCardAction,
  StepCardContent,
  StepCardHeader,
  StepCardTitle,
  TextControl,
} from '@clatter/ui';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ViewCompactOutlinedIcon from '@material-ui/icons/ViewCompactOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { addPage, updateMicrosite, updatePage } from '../../store';
import ImagePicker from '../../components/ImagePicker/ImagePicker';
import ListSelector from '../../components/ListSelector/ListSelector';
import CreateCompanyForm from '../CreateCompanyForm/CreateCompanyForm';
import { deleteCobrandLogo } from '../../store/cobrand-logos.slice';
import { compareIdentifiers, getNextPage, isSiteComplete } from '../../helpers';
import SiteMakerActions from '../SiteMaker/SiteMakerActions';
import { themePageTemplateMap } from '../../pages/mockBlocks';
import routes from '../../routes/routes';

const SITES_HOST = process.env.NX_SITES_HOST;

const StyledSiteSettingsForm = styled.form`
  .microsite-name {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .microsite-url {
      padding: 0 8px;
      font-size: 14px;
      color: #666;
    }

    .microsite-control {
      flex: 1;
    }
  }

  .logo-upload-picker {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(430px, 1fr));
  }
`;

const SiteSettingsForm = ({
  heroImages,
  microsite,
  pageTemplates,
  pages,
  cobrandLogos,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useAuth0();

  const [showCreateCompanyDialog, setShowCreateCompanyDialog] = useState(false);

  const {
    control,
    formState: { errors },
    formState,
    getValues,
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    // defaultValues: {cloneDeep(microsite),
    defaultValues: {
      id: microsite.id,
      name: microsite.name,
      client_name: microsite.client_name,
      // it is actually the underlying asset that is currently stored in the cobrand_logo
      // field. so, we have to lookup the cobrand logo object
      // default constructed microsites don't have .cobrand_logo attribute
      cobrand_logo: cobrandLogos
        .filter((logo) => logo.asset && logo.asset.id)
        .find(
          (logo) =>
            microsite.cobrand_logo &&
            logo.asset.id === microsite.cobrand_logo.id,
        ),
    },
  });

  const toggleShowCreateCompanyDialog = () => {
    setShowCreateCompanyDialog(!showCreateCompanyDialog);
  };

  const handleSelectLogo = (logo) => {
    setValue('cobrand_logo', logo, {
      shouldDirty: true,
      shouldValidate: true,
    });
    toggleShowCreateCompanyDialog();
  };

  const handleFormSubmit = async (formData) => {
    if (formState.isDirty) {
      const updatedMicrosite = {
        id: microsite.id,
        name: formData.name,
        client_name: formData.client_name,
        cobrand_logo: formData.cobrand_logo.asset.id,
      };

      await dispatch(updateMicrosite(updatedMicrosite)).then((response) => {
        if (!response.error) {
          history.push(getNextPage(microsite, microsite.pages));
        }
      });
    } else {
      history.push(getNextPage(microsite, microsite.pages));
    }
  };

  const getDefaultPage = (templateName) => {
    const defaultPage = {
      title: templateName,
      page_template: pageTemplates.find(
        ({ title }) => title === themePageTemplateMap[templateName].template,
      ),
    };

    const pageVariables = {
      theme: themePageTemplateMap[templateName].theme,
    };

    if (templateName !== 'Video Template') {
      const defaultHeroImage =
        heroImages && Array.isArray(heroImages) && heroImages.length
          ? heroImages[0]
          : null;

      pageVariables.headline = 'A better path to affordable benefits';
      pageVariables.introCopy =
        'Developing a lasting partnership with [client name]';
      pageVariables.heroImageId = defaultHeroImage?.id;
      pageVariables.heroImageUrl = defaultHeroImage?.url;
    }

    defaultPage.variables = JSON.stringify(pageVariables);

    return defaultPage;
  };

  const handleAddPage = async (templateName) => {
    const nextMicrosite = {
      ...microsite,
      ...getValues(),
      cobrand_logo: getValues('cobrand_logo')?.asset?.id,
    };
    const response = await dispatch(addPage(getDefaultPage(templateName)));
    // note how adding a page always PUTs a microsite with .pages[] an array
    // of string ids. deleting a page PUTs the micrsite with the array of
    // page {} objects... (We're not having problems with delete page...)
    nextMicrosite.pages = microsite.pages.map((page) => page.id);
    nextMicrosite.pages.push(response.payload.id);
    // updateMicrosite() will cause a render by updating the microsites slice
    await dispatch(updateMicrosite(nextMicrosite));
  };

  const handleDeletePage = (deletedValue) => {
    const nextMicrosite = {
      id: microsite.id,
      pages: microsite.pages.filter((page) => page.id !== deletedValue.id),
      ...getValues(),
      cobrand_logo: getValues('cobrand_logo')?.asset?.id,
    };
    dispatch(updateMicrosite(nextMicrosite));
  };

  const handleDeleteLogo = (event) => {
    const logoId = event.currentTarget.dataset.id;

    if (logoId) {
      dispatch(deleteCobrandLogo(logoId));
      const selectedCobrandLogo = watch('cobrand_logo');
      if (
        selectedCobrandLogo &&
        compareIdentifiers(selectedCobrandLogo.id, logoId)
      ) {
        setValue('cobrand_logo', null, { shouldValidate: true });
      }
    }
  };

  const handleItemChange = (updatedPage) => {
    const nextPage = {
      id: updatedPage.id,
      title: updatedPage?.title?.trim(),
    };

    dispatch(updatePage(nextPage)).then((data) => {
      const nextMicrosite = cloneDeep(microsite);
      const changedPageIndex = nextMicrosite.pages.findIndex(
        (page) => page.id === updatedPage.id,
      );
      nextMicrosite.pages[changedPageIndex] = data.payload;
      dispatch(updateMicrosite(nextMicrosite));
    });
  };

  const redirectToPublish = () => {
    history.push(generatePath(routes.publishSite, { siteId: microsite.id }));
  };

  const renderButtons = () => (
    <>
      <Button
        disabled={!formState.isValid || !pages || !pages.length}
        type="submit"
      >
        {formState.isDirty ? 'Save and continue' : 'Continue'}
      </Button>
      <Button
        disabled={!isSiteComplete(microsite, microsite.pages)}
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  return (
    <>
      <StyledSiteSettingsForm onSubmit={handleSubmit(handleFormSubmit)}>
        <StepCard>
          <StepCardHeader step={1}>
            <StepCardTitle text="Enter microsite name and customer name" />
          </StepCardHeader>
          <StepCardContent>
            <FormControl
              label="Give your microsite a name. We suggest the client or prospect name."
              error={errors.name}
            >
              <div className="microsite-name">
                <div className="microsite-url">{`${SITES_HOST}/sites/`}</div>
                <div className="microsite-control">
                  <TextControl
                    {...register('name', {
                      required: { value: true, message: 'Field is required' },
                    })}
                    placeholder="enter name for the microsite"
                  />
                </div>
              </div>
            </FormControl>
            <FormControl
              noPadding
              label="Enter a client or prospect name. This may be the same name as above."
              error={errors.client_name}
            >
              <TextControl
                {...register('client_name', {
                  required: { value: true, message: 'Field is required' },
                })}
                placeholder="enter a client or prospect name"
              />
            </FormControl>
          </StepCardContent>
        </StepCard>
        <StepCard>
          <StepCardHeader step={2}>
            <StepCardTitle text="Upload your client/prospect logo" />
            <StepCardAction
              onClick={toggleShowCreateCompanyDialog}
              label="Add company"
              icon={AddOutlinedIcon}
            />
          </StepCardHeader>
          <StepCardContent>
            <FormControl noPadding error={errors.cobrand_logo}>
              <Controller
                rules={{
                  required: { value: true, message: 'Field is required' },
                }}
                render={({ field: { onChange, value } }) => (
                  <ImagePicker
                    images={cobrandLogos}
                    titleProperty="company"
                    urlProperty="asset.url"
                    onChange={onChange}
                    value={value}
                    imageAspectRatio="10/4"
                    renderItemActions={(image) => (
                      <IconButton
                        data-id={image.id}
                        onClick={handleDeleteLogo}
                        tooltip="Delete"
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    )}
                  />
                )}
                control={control}
                name="cobrand_logo"
              />
            </FormControl>
          </StepCardContent>
        </StepCard>
        <StepCard>
          <StepCardHeader step={3}>
            <StepCardTitle text="Add page templates" />
          </StepCardHeader>
          <StepCardContent>
            <p>
              Add a page template for each page you plan to include in your
              microsite. (Sales, please choose the Prospect Site template).
            </p>
            <ListSelector
              disabled={!formState.isValid}
              itemIcon={ViewCompactOutlinedIcon}
              onAdd={handleAddPage}
              onDelete={handleDeletePage}
              onItemChange={handleItemChange}
              options={themePageTemplateMap}
              value={pages}
              assetDisplayName="Page Templates"
            />
            {(!pages || !pages.length) && (
              <ControlError message="Add at least one page" />
            )}
          </StepCardContent>
        </StepCard>
        <SiteMakerActions renderButtons={renderButtons} />
      </StyledSiteSettingsForm>
      <Dialog
        title="Create company"
        onCloseDialog={toggleShowCreateCompanyDialog}
        open={showCreateCompanyDialog}
      >
        <CreateCompanyForm onSuccess={handleSelectLogo} user={user} />
      </Dialog>
    </>
  );
};

export default SiteSettingsForm;

import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import getAxiosWithAuthorization from '../services/axiosFactory';
import requestStatus from './requestStatus';
import { PLATFORM_FEATURE_KEY } from './index';
export const TOOLS_FEATURE_KEY = 'tools';

export const toolsAdapter = createEntityAdapter({
  selectId: (row) => row._id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const fetchToolsConfig = createAsyncThunk(
  `${TOOLS_FEATURE_KEY}/fetch`,
  () => {
    return {
      data: [
        {
          _id: 4,
          name: 'Open XX Generator',
          url: 'http://localhost:3006',
          description: 'Summary Maker TOOL',
        },
        {
          _id: 2,
          name: 'Micro Site Maker',
          url: 'http://localhost:4300',
          description: 'MSM TOOL',
        },
        {
          _id: 1,
          name: 'Summary Maker',
          url: 'http://localhost:3000',
          description: 'Summary Maker TOOL',
        },
        {
          _id: 3,
          name: 'QUick Converted',
          url: 'http://localhost:3002',
          description: 'Summary Maker TOOL',
        },
      ],
    };
    // todo: uncomment and fix url once API is working
    // return await getAxiosWithAuthorization({
    //   method: 'get',
    //   url: '/summary/blockfunction',
    // });
  },
);

export const initialToolsState = toolsAdapter.getInitialState({
  loadingStatus: requestStatus.initial,
  error: null,
});

export const toolsSlice = createSlice({
  name: TOOLS_FEATURE_KEY,
  initialState: initialToolsState,
  reducers: {
    add: toolsAdapter.addOne,
    remove: toolsAdapter.removeOne,
    // ...
  },
  extraReducers: (builder) => {
    builder
      // fetch documents
      .addCase(fetchToolsConfig.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchToolsConfig.fulfilled, (state, { payload }) => {
        toolsAdapter.setAll(state, payload.data);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchToolsConfig.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      });
  },
});

const { selectAll } = toolsAdapter.getSelectors();
export const getToolsState = (rootState) =>
  rootState[PLATFORM_FEATURE_KEY][TOOLS_FEATURE_KEY];

export const selectAllTools = createSelector(getToolsState, selectAll);

import React, { useState } from 'react';
import styled from 'styled-components';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { Dialog } from '@clatter/ui';
import Section from './Section';

const StyledHeroBannerImage = styled.div`
  font-family: ${({ theme }) => theme.body.fontFamily};

  .banner-section {
    position: relative;
    background-color: ${({ theme }) => theme.heroBanner.backgroundColor};
  }

  .banner-content {
    padding: 64px 0;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    width: 82.5%;
    min-width: 320px;
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    align-items: flex-start;
    align-items: center;
  }

  .banner-video {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 300px;
    max-width: 500px;
    position: relative;

    .preview-video-button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        font-size: 24px;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.9);
        fill: #fff;
        width: 64px;
        height: 48px;
      }
    }

    .iframe-video {
      position: relative;
      overflow: hidden;
      padding-top: 56.25%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
  }

  .banner-details {
    flex-grow: 1;
    flex-basis: 300px;
    line-height: 1.4;
    min-width: 320px;
    max-width: 400px;
    margin: 32px 24px 32px 0;
    padding: ${({ theme }) => theme.heroBanner.details.padding};
    background-color: ${({ theme }) =>
      theme.heroBanner.details.backgroundColor};

    h1 {
      color: ${({ theme }) => theme.heroBanner.details.header.color};
      font-family: ${({ theme }) => theme.heroBanner.details.header.fontFamily};
      font-size: 32px;
      line-height: 1.2;
      margin: 0;
      padding: 0;
      font-weight: 700;
    }

    p {
      font-size: ${({ theme }) => theme.heroBanner.details.text.fontSize};
      color: ${({ theme }) => theme.heroBanner.details.text.color};
      font-family: ${({ theme }) => theme.heroBanner.details.text.fontFamily};
      font-weight: ${({ theme }) => theme.heroBanner.details.text.fontWeight};
      line-height: 1.4;
      margin-bottom: 0;
    }
  }

  .banner-background {
    display: none;
  }

  @media (min-width: 769px) {
    .banner-content {
      justify-content: space-between;
      text-align: left;
    }

    .banner-background {
      display: block;
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: right center;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      display: flex;
      justify-content: flex-end;
      background-position: top right;
      background-size: cover;
    }

    .banner-gradient {
      background: ${({ theme }) => theme.heroBanner.gradient};
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

const HeroBannerImage = ({
  bannerVideo,
  headline,
  heroImageUrl = '',
  introCopy,
  optionalSectionTitle,
  optionalCopy,
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const toggleShowPreview = () => {
    setShowPreview(!showPreview);
  };

  return (
    <StyledHeroBannerImage>
      <div className="banner-section">
        <div className="banner-content">
          <div className="banner-details">
            <h1>{headline}</h1>
            <p>{introCopy}</p>
          </div>
          <div className="banner-video">
            {bannerVideo && (
              <>
                <div className="iframe-video">
                  <iframe
                    src={`${bannerVideo.link}?controls=0&transparent=0`}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                  />
                </div>
                <div
                  className="preview-video-button"
                  onClick={toggleShowPreview}
                >
                  <PlayArrowIcon />
                </div>
                <Dialog
                  title={bannerVideo?.title}
                  onCloseDialog={toggleShowPreview}
                  open={showPreview}
                >
                  <iframe
                    style={{ display: 'block' }}
                    width="640"
                    height="360"
                    src={`${bannerVideo?.link}?autoplay=1`}
                    frameBorder="0"
                    allow="fullscreen; picture-in-picture"
                    allowFullScreen
                  />
                </Dialog>
              </>
            )}
          </div>
        </div>
        <div
          className="banner-background"
          style={{ backgroundImage: `url(${heroImageUrl})` }}
        >
          <div className="banner-gradient" />
        </div>
      </div>
      {!!optionalCopy && (
        <Section>
          {!!optionalSectionTitle && <h1>{optionalSectionTitle}</h1>}
          <p>{optionalCopy}</p>
        </Section>
      )}
    </StyledHeroBannerImage>
  );
};

export default HeroBannerImage;

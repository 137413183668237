import React from 'react';
import styled from 'styled-components';
import CustomerBio from './CustomerBio';
import Section from './Section';

const StyledFooterBrand = styled.footer`
  background-color: ${({ theme }) => theme.footerBrand.backgroundColor};

  .footer-brand-content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    padding: 16px;

    .main-bio {
      margin: 16px 0;

      > * {
        margin-top: 16px;
      }
    }

    .brand-logo {
      height: 64px;

      img {
        height: 100%;
      }
    }
  }

  @media (min-width: 800px) {
    .footer-brand-content {
      flex-direction: row;
      justify-content: space-between;

      .brand-logo {
        flex: 1;
      }

      .main-bio {
        display: grid;
        grid-template-columns: 1fr 1fr;
        direction: rtl;
        margin: 0;

        > * {
          direction: ltr;
          align-self: right;
          margin-top: 0;
          margin-left: 16px;
        }
      }
    }
  }
`;

const FooterBrand = ({ bio, logo, showLogo = true }) => {
  const renderBio = () => {
    if (!bio) {
      return null;
    }

    return (
      <div className="main-bio">
        {Array.isArray(bio) ? (
          bio.map((bioItem) => (
            <CustomerBio
              key={bioItem.srcImg}
              image={bioItem.srcImg}
              name={bioItem.displayName}
              title={bioItem.title}
            />
          ))
        ) : (
          <CustomerBio
            key={bio.srcImg}
            image={bio.srcImg}
            name={bio.displayName}
            title={bio.title}
          />
        )}
      </div>
    );
  };

  return (
    <StyledFooterBrand>
      <Section>
        <div className="footer-brand-content">
          <div className="brand-logo">
            {showLogo && !!logo && <img src={logo} alt="Co-Brand-Logo" />}
          </div>
          {renderBio()}
        </div>
      </Section>
    </StyledFooterBrand>
  );
};

export default FooterBrand;

import React from 'react';
import styled from 'styled-components';
import { ContentExpander } from '@clatter/ui';
import SectionHeader from './SectionHeader';

const StyledFeaturedResources = styled.div`
  padding: ${({ theme }) => theme.featuredResources.padding};

  .resources {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 24px;

    .resource-item {
      display: block;
      text-decoration: none;

      span {
        display: block;
        font-size: ${({ theme }) => theme.featuredResources.item.fontSize};
        color: ${({ theme }) => theme.featuredResources.item.color};
        text-transform: capitalize;
        font-weight: 500;
      }

      p {
        margin: 10px 0;
      }
    }
  }

  .more-resources-wrap {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;

    a {
      cursor: pointer;
      padding: 12px 24px;
      color: #fff;
      background-color: #316bbe;
      border: 1px solid #316bbe;
      border-radius: 50px;
    }
  }

  &.resources-vertical {
    min-width: 300px;
    max-width: 460px;
    background-color: ${({ theme }) =>
      theme.featuredResources.vertical.backgroundColor};
    padding: ${({ theme }) => theme.featuredResources.vertical.padding};

    .resources {
      grid-template-columns: 1fr;
      gap: 16px;

      .resource-item {
        span {
          font-size: ${({ theme }) => theme.featuredResources.item.fontSize};
          color: ${({ theme }) => theme.featuredResources.item.color};
          border-bottom: ${({ theme }) =>
            theme.featuredResources.item.separator};
        }
      }
    }
  }
`;

const FeaturedResources = ({
  disableExpander = false,
  header,
  hideDescription = false,
  resources,
  vertical = false,
}) => {
  const renderResource = (resource) => (
    <a
      className="resource-item"
      key={resource.id}
      href={resource.asset?.url || resource.link}
      title={resource.title}
      target="_blank"
      download={!!resource.asset?.url}
    >
      <span>{resource.title}</span>
      {!!resource.description && !hideDescription && (
        <p>{resource.description}</p>
      )}
    </a>
  );

  const renderResources = () => {
    if (!resources || (Array.isArray(resources) && !resources.length)) {
      return <p>No resources</p>;
    }

    if (disableExpander) {
      return <div className="resources">{resources.map(renderResource)}</div>;
    }

    return (
      <ContentExpander
        items={resources}
        limit={4}
        moreText="More resources"
        lessText="Less resources"
      >
        {(items) => (
          <div className="resources">{items.map(renderResource)}</div>
        )}
      </ContentExpander>
    );
  };

  return (
    <StyledFeaturedResources
      className={`${vertical ? 'resources-vertical' : ''}`}
    >
      {!!header && <SectionHeader as="h2">{header}</SectionHeader>}
      {renderResources(resources)}
    </StyledFeaturedResources>
  );
};

export default FeaturedResources;

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LandingPage } from '@clatter/ui';
import { LogoutPageView, ProtectedRoute } from '@clatter/platform';
import MyMicrosites from '../pages/MyMicrosites/MyMicrosites';
import SiteSettings from '../pages/SiteSettings/SiteSettings';
import PageSettings from '../pages/PageSettings/PageSettings';
import PagePreview from '../pages/PagePreview/PagePreview';
import PreviewModal from '../pages/PreviewModal/PreviewModal';
import ComponentsList from '../pages/ComponentsList/ComponentsList';
import VideosBlockConfig from '../pages/VideosBlockConfig/VideosBlockConfig';
import ResourcesBlockConfig from '../pages/ResourcesBlockConfig/ResourcesBlockConfig';
import BiosBlockConfig from '../pages/BiosBlockConfig/BiosBlockConfig';
import Publish from '../pages/Publish/Publish';
import FooterSettings from '../pages/FooterSettings/FooterSettings';
import AdditionalVideosBlock from '../pages/AdditionalVideosBlock/AdditionalVideosBlock';
import Admin from '../components/Admin/Admin';
import Page from '../components/Page/Page';
import logo from '../../assets/Logo-OptumRx-PNG-e1590785075499.png';
import { userRolesMap } from '../constants';
import routes from './routes';

const AppRoutes = () => (
  <Switch>
    <Route path={routes.index} exact>
      <LandingPage
        logoUrl={logo}
        backgroundImageUrl="https://clatter-static-asset.s3.amazonaws.com/images/hero__603fca80-69e6-11eb-b2ec-a7b429990760.jpeg"
        successfulLoginRedirectUrl="microsites"
      />
    </Route>
    <Route exact path={routes.logout} component={LogoutPageView} />
    <ProtectedRoute path="/admin" accessRoles={userRolesMap.admin}>
      <Page>
        <Admin />
      </Page>
    </ProtectedRoute>
    <ProtectedRoute
      path={routes.microsites}
      exact
      accessRoles={[userRolesMap.user, userRolesMap.admin]}
    >
      <Page>
        <MyMicrosites />
      </Page>
    </ProtectedRoute>
    <ProtectedRoute
      path={routes.siteSettings}
      accessRoles={[userRolesMap.user, userRolesMap.admin]}
    >
      <Page>
        <SiteSettings />
      </Page>
    </ProtectedRoute>
    <ProtectedRoute
      path={routes.pageSettings}
      accessRoles={[userRolesMap.user, userRolesMap.admin]}
      render={(props) => (
        <Page key={props.match.params.pageId}>
          <PageSettings />
        </Page>
      )}
    />
    <ProtectedRoute
      path={routes.publishSite}
      exact
      accessRoles={[userRolesMap.user, userRolesMap.admin]}
    >
      <Page>
        <Publish />
      </Page>
    </ProtectedRoute>
    <ProtectedRoute
      path={routes.pageResources}
      exact
      accessRoles={[userRolesMap.user, userRolesMap.admin]}
      render={(props) => (
        <Page key={props.match.params.pageId}>
          <ResourcesBlockConfig />
        </Page>
      )}
    />
    <ProtectedRoute
      path={routes.pageVideos}
      exact
      accessRoles={[userRolesMap.user, userRolesMap.admin]}
      render={(props) => (
        <Page key={props.match.params.pageId}>
          <VideosBlockConfig />
        </Page>
      )}
    />
    <ProtectedRoute
      path={routes.pageVideosTable}
      exact
      accessRoles={[userRolesMap.user, userRolesMap.admin]}
      render={(props) => (
        <Page key={props.match.params.pageId}>
          <AdditionalVideosBlock />
        </Page>
      )}
    />
    <ProtectedRoute
      path={routes.pageBios}
      exact
      accessRoles={[userRolesMap.user, userRolesMap.admin]}
      render={(props) => (
        <Page key={props.match.params.pageId}>
          <BiosBlockConfig />
        </Page>
      )}
    />
    <ProtectedRoute
      path={routes.pageFooter}
      exact
      accessRoles={[userRolesMap.user, userRolesMap.admin]}
      render={(props) => (
        <Page key={props.match.params.pageId}>
          <FooterSettings />
        </Page>
      )}
    />
    <ProtectedRoute
      path={routes.pagePreview}
      accessRoles={[userRolesMap.user, userRolesMap.admin]}
    >
      <Page clean>
        <PagePreview />
      </Page>
    </ProtectedRoute>
    <ProtectedRoute
      path={routes.pageBuildPreview}
      accessRoles={[userRolesMap.user, userRolesMap.admin]}
    >
      <Page>
        <PreviewModal />
      </Page>
    </ProtectedRoute>
    <ProtectedRoute
      path={routes.components}
      exact
      accessRoles={[userRolesMap.user, userRolesMap.admin]}
    >
      <Page>
        <ComponentsList />
      </Page>
    </ProtectedRoute>
  </Switch>
);

export default AppRoutes;

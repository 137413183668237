import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import AddIcon from '@material-ui/icons/Add';
import {
  FormControl,
  TextControl,
  SelectControl,
  DataTable,
  IconButton,
} from '@clatter/ui';
import { arrayMove } from '../../helpers';
import { deleteResource } from '../../store/resources.slice';

const StyledResourcePicker = styled.div`
  .filters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
    gap: 16px;
  }

  h3 {
    margin: 24px 0 8px;
  }
`;

const ResourcePicker = ({
  max = 0,
  onChange,
  options,
  tableColumns,
  value,
}) => {
  const dispatch = useDispatch();

  const [filteredOptions, setFilteredOptions] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');

  useEffect(() => {
    const nextAvailableCategories = [
      ...new Set(options.map((resource) => resource.category)),
    ]
      .filter((resource) => !!resource)
      .map((category) => ({
        label: category,
        value: category,
      }));

    nextAvailableCategories.unshift({
      label: 'All',
      value: null,
    });

    setAvailableCategories(nextAvailableCategories);
  }, [options, value]);

  useEffect(() => {
    let nextFilteredOptions = options.filter(
      ({ id: optionId }) =>
        !value.some(({ id: valueId }) => valueId === optionId),
    );

    if (categoryFilter && categoryFilter.value) {
      nextFilteredOptions = nextFilteredOptions.filter(
        (item) => item.category === categoryFilter.value,
      );
    }

    if (searchTerm) {
      nextFilteredOptions = nextFilteredOptions.filter((option) =>
        option.name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    if (nextFilteredOptions !== filteredOptions) {
      setFilteredOptions(nextFilteredOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options, searchTerm, categoryFilter]);

  if (!options || !options.length) {
    return null;
  }

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCategoryFilterChange = (option) => {
    setCategoryFilter(option);
  };

  const handleAdd = (event) => {
    const nextValue = cloneDeep(value);
    const clickedItemId = event.currentTarget.dataset.id;
    const newItem = cloneDeep(
      filteredOptions.find(({ id }) => id === clickedItemId),
    );
    nextValue.push(newItem);
    onChange(nextValue);
  };

  const handleDelete = (event) => {
    const resourceId = event.currentTarget.dataset.id;

    dispatch(deleteResource(resourceId)).then(() => {
      const isItemSelected = value.some(({ id }) => id === resourceId);

      if (isItemSelected) {
        const nextValue = cloneDeep(value).filter(
          ({ id }) => id !== resourceId,
        );

        onChange(nextValue);
      }
    });
  };

  const handleRemove = (event) => {
    const nextValue = value.filter(
      ({ id }) => id !== event.currentTarget.dataset.id,
    );
    onChange(nextValue);
  };

  const handleMoveItemUp = (event) => {
    const currentIndex = Number(event.currentTarget.dataset.index);
    const nextValue = arrayMove(value, currentIndex, currentIndex - 1);
    onChange(nextValue);
  };

  const handleMoveItemDown = (event) => {
    const currentIndex = Number(event.currentTarget.dataset.index);
    const nextValue = arrayMove(value, currentIndex, currentIndex + 1);
    onChange(nextValue);
  };

  return (
    <StyledResourcePicker>
      <div className="filters">
        <FormControl>
          <TextControl
            onChange={handleSearchTermChange}
            placeholder="Search resources"
            value={searchTerm}
          />
        </FormControl>
        <FormControl>
          <SelectControl
            onChange={handleCategoryFilterChange}
            options={availableCategories}
            placeholder="Select a category"
            value={categoryFilter}
          />
        </FormControl>
      </div>
      <DataTable
        columns={tableColumns}
        rows={filteredOptions}
        renderActionColumn={(row) => (
          <>
            <IconButton
              disabled={max && value.length >= max}
              data-id={row.id}
              onClick={handleAdd}
              tooltip="Add"
            >
              <AddIcon />
            </IconButton>
            {row.owner && (
              <IconButton
                data-id={row.id}
                onClick={handleDelete}
                tooltip="Delete"
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            )}
          </>
        )}
      />
      <h3>Your selected resources</h3>
      <DataTable
        columns={tableColumns}
        rows={value.map(({ id }) =>
          options.find((resource) => resource.id === id),
        )}
        noRowsMessage="No resources selected"
        renderActionColumn={(row, index) => (
          <>
            <IconButton
              disabled={!index}
              data-index={index}
              onClick={handleMoveItemUp}
              tooltip="Move up"
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              disabled={value.length <= index + 1}
              data-index={index}
              onClick={handleMoveItemDown}
              tooltip="Move down"
            >
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton
              data-id={row.id}
              onClick={handleRemove}
              tooltip="Remove"
            >
              <CloseIcon />
            </IconButton>
          </>
        )}
      />
    </StyledResourcePicker>
  );
};

export default ResourcePicker;

import { Dialog } from '@clatter/ui';
import React from 'react';

const VideoPreviewDialog = ({ title, videoUrl, onCloseDialog }) => {
  if (!videoUrl || !title) {
    return null;
  }

  return (
    <Dialog title={title} onCloseDialog={onCloseDialog} open>
      <iframe
        style={{ display: 'block' }}
        width="640"
        height="360"
        src={`${videoUrl}?autoplay=1`}
        frameBorder="0"
        allow="fullscreen; picture-in-picture"
        allowFullScreen
        title="Video Preview"
      />
    </Dialog>
  );
};

export default VideoPreviewDialog;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DataTable, Dialog, IconButton, Loader } from '@clatter/ui';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AdminContentHead from '../AdminContentHead';
import {
  deleteResourceCategory,
  fetchResourceCategories,
  selectAllResourceCategories,
} from '../../../store/resource-categories.slice';
import ResourceCategoryForm from './ResourceCategoryForm';
import { formatDate } from '../../../helpers';

const resourceCategoryColumns = [
  {
    label: 'Category name',
    name: 'categoryName',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Date added',
    name: 'createdAt',
    customRender: (row) => formatDate(row.createdAt, true),
    align: 'left',
    sortable: true,
  },
];

const ResourceCategories = () => {
  const dispatch = useDispatch();
  const [editableItem, setEditableItem] = useState(null);

  useEffect(() => {
    dispatch(fetchResourceCategories());
  }, [dispatch]);

  const resourceCategories = useSelector(selectAllResourceCategories);

  const loading = useSelector(
    (state) => state.resourceCategories.loadingStatus === 'loading',
  );

  const handleEditClick = (event) => {
    const clickedItemId = event.currentTarget?.dataset?.id;

    setEditableItem(
      (clickedItemId &&
        resourceCategories.find(
          (resourceCategory) => resourceCategory.id === clickedItemId,
        )) ||
        {},
    );
  };

  const handleDismissEdit = () => {
    setEditableItem(null);
  };

  const handleDeleteClick = (event) => {
    if (window.confirm('Are you sure you want to remove this category?')) {
      dispatch(deleteResourceCategory(event.currentTarget.dataset.id));
    }
  };

  const isEdit = editableItem && 'categoryName' in editableItem;

  return (
    <>
      {loading && <Loader />}
      <AdminContentHead>
        <h1>Resource categories</h1>
        <Button onClick={handleEditClick}>Add category</Button>
      </AdminContentHead>
      <div>
        <DataTable
          autofit
          columns={resourceCategoryColumns}
          defaultSortField="categoryName"
          filterColumns={['categoryName']}
          name="adminResourceCategories"
          rows={resourceCategories}
          renderActionColumn={(row) => (
            <>
              <IconButton data-id={row.id} onClick={handleEditClick}>
                <EditOutlinedIcon />
              </IconButton>
              <IconButton data-id={row.id} onClick={handleDeleteClick}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </>
          )}
        />
      </div>
      <Dialog
        title={isEdit ? 'Edit resource category' : 'Add resource category'}
        onCloseDialog={handleDismissEdit}
        open={!!editableItem}
      >
        <ResourceCategoryForm
          isEdit={isEdit}
          editableItem={editableItem}
          onSuccess={handleDismissEdit}
        />
      </Dialog>
    </>
  );
};

export default ResourceCategories;

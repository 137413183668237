import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useForm, FormProvider } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import AdjustIcon from '@material-ui/icons/Adjust';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import classNames from 'classnames';
import { buttonVariants } from '../../config/consts';

const StyledStepper = styled.div`
  height: calc(100% - 64px);
  display: grid;
  grid-template-areas:
    'steps'
    'stepContent';
  grid-template-rows: 56px 1fr;

  .steps {
    grid-area: steps;
    display: flex;
    align-items: center;
    background-color: #636363;
    color: #fff;
    padding: 0 24px;

    .steps-item {
      line-height: 56px;
      padding: 0 24px;
      display: flex;
      align-items: center;
      font-size: 14px;
      border: none;
      background-color: transparent;
      color: #fff;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
      }

      > span {
        margin-left: 8px;
      }

      &--active {
        background-color: #808080;
        font-weight: 700;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: -16px;
          border-width: 10px;
          border-style: solid;
          border-color: #0000 #0000 #0000 #808080;
          transform: translateY(-50%);
        }
      }
    }
  }

  .step-content {
    grid-area: stepContent;
    overflow-y: auto;
  }

  .step-form {
    height: 100%;
    display: grid;
    grid-template-rows: 93px 1fr;
    padding: 16px 24px;
    max-width: 1250px;
    margin: 0 auto;

    .step-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 0;
      border-bottom: 1px solid #9797973d;

      .step-title {
        h1 {
          font-size: 20px;
          margin: 0;
          color: #363636;
          opacity: 0.7;
          font-weight: 700;
        }
      }

      .step-buttons {
        display: flex;

        > * + * {
          margin-left: 16px;
        }
      }
    }

    .step-body {
      padding: 24px 0 0;
      min-height: 0;
    }
  }
`;

const Stepper = ({
  getSteps,
  data = {},
  initialStepIndex = 0,
  shouldUnregister = false,
}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(initialStepIndex);
  const [loading, setLoading] = useState(false);

  const resolver = () => (values) => {
    let errors = {};

    if (typeof steps[currentStepIndex]?.validate === 'function') {
      errors = {
        ...errors,
        ...steps[currentStepIndex].validate(values),
      };
    }

    return {
      values,
      errors,
    };
  };

  const formMethods = useForm({
    mode: 'onChange',
    shouldUnregister,
    defaultValues: data,
    resolver: resolver(),
  });

  const {
    clearErrors,
    control,
    formState: { isValid },
    handleSubmit,
    reset,
    trigger,
    watch,
  } = formMethods;

  const formValues = watch();

  const steps = useMemo(
    () => getSteps(formValues) || [],
    [getSteps, formValues],
  );

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  const hasPrevStep = () => currentStepIndex !== 0;

  const hasNextStep = () => steps?.length - 1 > currentStepIndex;

  const handleSave =
    (nextStepIndex, ignoreValidation = false) =>
    async (formData) => {
      setLoading(true);
      return new Promise((resolve) => {
        (async () => {
          await steps[currentStepIndex]?.onSave?.(formData, ignoreValidation);
          setLoading(false);
          !isNaN(nextStepIndex) && setCurrentStepIndex(Number(nextStepIndex));
          resolve(formData);
          if (ignoreValidation) {
            clearErrors();
            trigger();
          }
        })();
      });
    };

  const handlePrevClick = () => {
    handleSubmit(
      handleSave(currentStepIndex - 1),
      handleSave(currentStepIndex - 1, true),
    )();
  };

  const handleNextClick = () => {
    handleSubmit(handleSave(currentStepIndex + 1))();
  };

  const handleStepClick = (event) => {
    const nextCurrentStepIndex = +event?.currentTarget?.dataset?.index;

    // next step is lower than current - we are going back to previous step
    if (nextCurrentStepIndex < currentStepIndex) {
      return handleSubmit(
        handleSave(nextCurrentStepIndex),
        handleSave(nextCurrentStepIndex, true),
      )();
    }

    if (
      currentStepIndex < nextCurrentStepIndex &&
      steps[nextCurrentStepIndex]?.validPreviousStepsRequired
    ) {
      let errors = {};
      for (let i = 0; i < nextCurrentStepIndex; i++) {
        if (typeof steps[i].validate === 'function') {
          errors = {
            ...errors,
            ...steps[i]?.validate(formValues),
          };
        }
      }

      return (
        !Object.keys(errors).length &&
        handleSubmit(handleSave(nextCurrentStepIndex))()
      );
    }

    return handleSubmit(handleSave(nextCurrentStepIndex))();
  };

  const renderSteps = () => (
    <div className="steps">
      {steps.map(({ label, icon: Icon }, index) => (
        <button
          className={classNames('steps-item', {
            'steps-item--active': index === currentStepIndex,
          })}
          data-index={index}
          key={label}
          onClick={handleStepClick}
        >
          {Icon ? <Icon /> : <AdjustIcon />}
          <span>{label}</span>
        </button>
      ))}
    </div>
  );

  const renderStepPanel = () => {
    const StepPanel = steps[currentStepIndex]?.component;

    if (!StepPanel) {
      return null;
    }

    return <StepPanel {...(steps[currentStepIndex]?.componentProps || {})} />;
  };

  const renderStepHeader = () => {
    const currentStep = steps[currentStepIndex];

    return (
      <>
        <div className="step-title">
          <h1>{currentStep?.headerTitle || currentStep?.label}</h1>
          <h4>{currentStep?.headerDescription}</h4>
        </div>
        <div className="step-buttons">
          {currentStep?.extraActions?.length &&
            currentStep?.extraActions.map((action) => (
              <Button
                disabled={action.disabled}
                key={action.key}
                variant={buttonVariants.clear}
                icon={action.icon}
                onClick={() =>
                  action.callback(formValues, handleSubmit(handleSave(0)))
                }
              >
                {action.label}
              </Button>
            ))}
          {hasPrevStep() && <Button onClick={handlePrevClick} testId="prev-step">Prev</Button>}
          {hasNextStep() && (
            <Button disabled={!isValid} onClick={handleNextClick} testId="next-step">
              Next
            </Button>
          )}
        </div>
      </>
    );
  };

  return (
    <StyledStepper>
      {loading && <Loader />}
      {renderSteps()}
      <div className="step-content">
        <DevTool control={control} placement="bottom-left" />
        <FormProvider {...formMethods}>
          <form className="step-form">
            <div className="step-header">{renderStepHeader()}</div>
            <div className="step-body">{renderStepPanel()}</div>
          </form>
        </FormProvider>
      </div>
    </StyledStepper>
  );
};

Stepper.propTypes = {
  getSteps: PropTypes.func.isRequired,
  validationResolver: PropTypes.func.isRequired,
  data: PropTypes.object,
  initialStepIndex: PropTypes.number,
};

export default Stepper;

import React, { useEffect, useState } from 'react';
import {
  DataTable,
  Dialog,
  FormControl,
  IconButton,
  SelectControl,
  TextControl,
} from '@clatter/ui';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import styled from 'styled-components';

export const videoTableColumns = [
  {
    label: 'Title',
    name: 'title',
    align: 'left',
  },
  {
    label: 'Category',
    name: 'category',
    align: 'left',
  },
  {
    label: 'Link',
    name: 'link',
    align: 'left',
    customRender: (row, cell) => (
      <div>
        <span>{cell}</span>
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(cell);
          }}
        >
          <FileCopyOutlinedIcon />
        </IconButton>
      </div>
    ),
  },
];

const StyledVideosTable = styled.div`
  .filters {
    display: flex;

    > * {
      + * {
        margin-left: 16px;
      }
    }
  }
`;

const VideosTable = ({ videos }) => {
  const [previewVideo, setPreviewVideo] = useState(null);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredVideos, setFilteredVideos] = useState([]);

  useEffect(() => {
    const nextAvailableCategories = [
      ...new Set(videos.map(({ category }) => category)),
    ]
      .filter((resource) => !!resource)
      .map((category) => ({
        label: category,
        value: category,
      }));

    nextAvailableCategories.unshift({
      label: 'All',
      value: null,
    });

    setAvailableCategories(nextAvailableCategories);
  }, [videos]);

  useEffect(() => {
    let nextFilteredVideos = videos.map((video) => ({
      ...video,
      category: video.video_category.categoryName,
    }));

    if (categoryFilter && categoryFilter.value) {
      nextFilteredVideos = nextFilteredVideos.filter(
        (item) => item.category === categoryFilter.value,
      );
    }

    if (searchTerm) {
      nextFilteredVideos = nextFilteredVideos.filter((video) =>
        video.title.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    setFilteredVideos(nextFilteredVideos);
  }, [videos, searchTerm, categoryFilter]);

  const handleCategoryFilterChange = (option) => {
    setCategoryFilter(option);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePreviewClick = (event) => {
    const previewVideo = videos.find(
      (item) => item.id === event.currentTarget.dataset.id,
    );

    setPreviewVideo(previewVideo);
  };

  const handleDownloadClick = (event) => {
    const previewVideo = videos.find(
      (item) => item.id === event.currentTarget.dataset.id,
    );

    window.open(previewVideo.download_link, '_blank');
  };

  const handleClosePreviewDialog = () => {
    setPreviewVideo(null);
  };

  return (
    <StyledVideosTable>
      <div className="filters">
        <FormControl>
          <TextControl
            onChange={handleSearchTermChange}
            placeholder="Search videos"
            value={searchTerm}
          />
        </FormControl>
        <FormControl>
          <SelectControl
            onChange={handleCategoryFilterChange}
            options={availableCategories}
            placeholder="Select a category"
            value={categoryFilter}
          />
        </FormControl>
      </div>
      <DataTable
        columns={videoTableColumns}
        rows={filteredVideos}
        renderActionColumn={(item) => (
          <>
            <IconButton data-id={item.id} onClick={handleDownloadClick}>
              <GetAppOutlinedIcon />
            </IconButton>
            <IconButton data-id={item.id} onClick={handlePreviewClick}>
              <PlayArrowIcon />
            </IconButton>
          </>
        )}
      />
      <Dialog
        title={previewVideo?.title}
        onCloseDialog={handleClosePreviewDialog}
        open={!!previewVideo}
      >
        <iframe
          style={{ display: 'block' }}
          width="640"
          height="360"
          src={`${previewVideo?.link}?autoplay=1`}
          frameBorder="0"
          allow="fullscreen; picture-in-picture"
          allowFullScreen
        />
      </Dialog>
    </StyledVideosTable>
  );
};

export default VideosTable;

import React, { useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { useParams, useHistory, generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import {
  fetchMicrosites,
  fetchPageTemplates,
  fetchPages,
  fetchResources,
  updatePage,
} from '../../store';
import SiteMaker from '../../components/SiteMaker/SiteMaker';
import {
  Dialog,
  Button,
  StepCard,
  StepCardHeader,
  StepCardTitle,
  StepCardAction,
  StepCardContent,
  FormControl,
  Loader,
  themes,
} from '@clatter/ui';
import { FeaturedResources } from '@clatter/templates';
import { Section } from '@clatter/templates';
import ResourcePicker from '../../components/ResourcePicker/ResourcePicker';
import {
  pageFromStore,
  resourceFromStore,
  resourceCategoryFromStore,
} from '../fromStore';
import { resourcesTableColumns } from '../ComponentsList/mocks';
import ResourceForm from '../../components/Admin/Resources/ResourceForm';
import SiteMakerContentHead from '../../components/SiteMaker/SiteMakerContentHead';
import { getNextPage, isSiteComplete } from '../../helpers';
import SiteMakerActions from '../../components/SiteMaker/SiteMakerActions';
import PreviewButton from '../../components/SiteMaker/PreviewButton';
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import routes from '../../routes/routes';

// the problem we have with our current pattern of selectors is the combination of:
// 1. we refer to the microsites selector to get current pages. this is dependent on
//    the microsites slice being updated whenever a page is updated.
// 2. we rely on the pages selector subscription to force re-render when the pages
//    slice is updated, but the page slice update doesn't change the microsites slice
// effectively, whenever we update a page, the microsites site entry is stale and should
// be refreshed. but it isn't. we rely on an effect (post - render!) to dispatch a new
// fetch of the microsites, but these function components see the stale page value in
// the microsites entry.
//
// the core *requirement* is that the *current* page is guaranteed fresh
// this requires a subscription to the pages slice - specifically, to the current page
// element of that slice. so, we introduced a currentPage selector to subscribe
// specifically to that element.
//
// for the context rail, the requirement is that the currentPages array is fresh

const ResourcesBlockConfig = () => {
  const { pageId } = useParams();
  const history = useHistory();
  const { user, isAuthenticated, isLoading } = useAuth0();

  const [showResourcesDialog, setShowResourcesDialog] = useState(false);

  const toggleShowResourcesDialog = () => {
    setShowResourcesDialog(!showResourcesDialog);
  };

  const {
    control,
    formState: { errors },
    formState,
    handleSubmit,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const handleFormSubmit = async (formData) => {
    if (formState.isDirty) {
      await dispatch(
        updatePage({
          id: currentPage.id,
          ...formData,
        }),
      ).then(() => {
        history.push(getNextPage(currentMicrosite, currentMicrosite.pages));
      });
    } else {
      history.push(getNextPage(currentMicrosite, currentMicrosite.pages));
    }
  };

  // while this is *necessary* in that if a page is being rendered
  // without anything in the store, this effect dispatches all the
  // necessary api calls, what about the case when everything is
  // fresh? this dispatches all requests even if store is fresh.
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPages());
    dispatch(fetchMicrosites(user));
    dispatch(fetchPageTemplates());
    dispatch(fetchResources(user.email));
  }, [dispatch, user]);

  const loading = useSelector(
    (state) =>
      state.pages.loadingStatus === 'loading' ||
      state.pageTemplates.loadingStatus === 'loading' ||
      state.microsites.loadingStatus === 'loading' ||
      state.resources.loadingStatus === 'loading',
  );

  const pageTemplates = useSelector(selectAllPageTemplates);

  const resources = useSelector((state) =>
    Object.values(state.resources.entities).map(resourceFromStore),
  );

  const currentMicrosite = useSelector((state) => {
    if (state.pages.ids.indexOf[pageId] === -1) {
      return;
    }

    const siteId = state.microsites.ids.find((micrositeId) =>
      state.microsites.entities[micrositeId].pages.some(
        (page) => page.id === pageId,
      ),
    );

    if (!siteId || !state.microsites.entities[siteId]) {
      return;
    }

    return {
      ...state.microsites.entities[siteId],
      pages: state.microsites.entities[siteId].pages?.map((page) =>
        pageFromStore(page, pageTemplates),
      ),
    };
  });

  const currentPage = useMemo(() => {
    if (!currentMicrosite || !resources) {
      return null;
    }

    const nextCurrentPage = {
      ...currentMicrosite?.pages?.find(({ id }) => id === pageId),
    };

    nextCurrentPage.resources = nextCurrentPage.resources.length
      ? nextCurrentPage.resources
          .map((item) => {
            if (typeof item === 'string') {
              return resources.find(({ _id }) => item === _id);
            }

            return item;
          })
          .filter((resource) => !!resource)
      : [];

    return nextCurrentPage;
  }, [currentMicrosite, resources, pageId]);

  const resourceCategories = useSelector((state) =>
    state.resources.categories
      .map(resourceCategoryFromStore)
      .map(({ id, categoryName }) => ({
        value: id,
        label: categoryName,
      })),
  );

  if (loading) {
    return <Loader />;
  }

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }

  if (!isAuthenticated) {
    // shouldn't happen, as this component is only displayed in a ProtectedRoute
    return <div>Not Authorized.</div>;
  }

  if (
    !loading &&
    (!currentMicrosite || !currentMicrosite.pages || !currentPage)
  ) {
    return <p>Couldn't load current page</p>;
  }

  const templateResourcesRange = {
    'Prospect Site': {
      min: 0,
      max: 4,
    },
    'Prospect Site UHC ': {
      min: 0,
      max: 4,
    },
    'Prospect Site UHC - Optum': {
      min: 0,
      max: 4,
    },
  };

  const getResourcesRange = (templateName) =>
    templateResourcesRange[templateName] || { min: 0, max: 0 };

  const getSubhead = () => {
    const resourcesRange = getResourcesRange(currentPage?.templateName);

    const subheadText =
      'Upload {range} resources from your computer, and/or select other available resources. A preview of your featured resources will appear below.';

    if (resourcesRange.min && resourcesRange.max) {
      return subheadText.replace(
        '{range}',
        `between ${resourcesRange.min} to ${resourcesRange.max}`,
      );
    }

    if (resourcesRange.min) {
      return subheadText.replace(
        '{range}',
        `at least ${resourcesRange.min} ${
          resourcesRange.min === 1 ? 'resource' : 'resources'
        }`,
      );
    }

    if (resourcesRange.max) {
      return subheadText.replace(
        '{range}',
        `up to ${resourcesRange.max} ${
          resourcesRange.max === 1 ? 'resource' : 'resources'
        }`,
      );
    }

    return subheadText.replace('{range} ', '');
  };

  const redirectToPublish = () => {
    history.push(generatePath(routes.publishSite, { siteId: currentMicrosite.id }));
  };

  const previewBackgroundMapping = {
    'Additional Resources': '#f4f7fc',
    'Partner Site': '#f4f7fc',
    'Prospect Site': '#f4f7fc',
  };

  const previewHeaderMapping = {
    'Prospect Site UHC': 'Resources',
  };

  const renderPreview = () => {
    const variables = JSON.parse(currentPage.variables);

    return (
      <ThemeProvider theme={themes[variables?.theme || 'default']}>
        <Section
          backgroundColor={
            previewBackgroundMapping[currentPage?.templateName] || '#fff'
          }
        >
          <FeaturedResources
            disableExpander={[
              'Additional Resources',
              'Prospect Site',
              'Prospect Site UHC',
            ].includes(currentPage?.templateName)}
            header={previewHeaderMapping[currentPage?.templateName]}
            hideDescription={['Prospect Site UHC', 'Prospect Site'].includes(
              currentPage?.templateName,
            )}
            resources={watch('resources') || currentPage.resources}
            vertical={['Prospect Site UHC', 'Prospect Site'].includes(
              currentPage?.templateName,
            )}
          />
        </Section>
      </ThemeProvider>
    );
  };

  const renderButtons = () => (
    <>
      <Button disabled={!formState.isValid} type="submit">
        {formState.isDirty ? 'Save Resources and continue' : 'Continue'}
      </Button>
      <PreviewButton
        siteName={currentMicrosite.name}
        pageName={currentPage.name}
      />
      <Button
        disabled={!isSiteComplete(currentMicrosite, currentMicrosite.pages)}
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  return (
    <SiteMaker site={currentMicrosite}>
      <SiteMakerContentHead
        title="Select featured resources"
        subhead={getSubhead()}
      />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <StepCard>
          <StepCardHeader step={1}>
            <StepCardTitle text="Add available resources" />
            <StepCardAction
              label="Add new resource"
              icon={AddOutlinedIcon}
              onClick={toggleShowResourcesDialog}
            />
          </StepCardHeader>
          <StepCardContent>
            <FormControl error={errors.resources}>
              <Controller
                rules={{
                  validate: (value) => {
                    const itemsCount = value?.length;

                    if (!itemsCount) {
                      return 'Field is required';
                    }

                    const resourcesRange = getResourcesRange(
                      currentPage?.templateName,
                    );

                    if (resourcesRange.min && itemsCount < resourcesRange.min) {
                      return `Pick at least ${resourcesRange.min} ${
                        resourcesRange.min === 1 ? 'resource' : 'resources'
                      }`;
                    }

                    if (resourcesRange.max && itemsCount > resourcesRange.max) {
                      return `Pick up to ${resourcesRange.min} ${
                        resourcesRange.min === 1 ? 'resource' : 'resources'
                      }`;
                    }
                  },
                }}
                name="resources"
                render={({ field: { onChange, value } }) => (
                  <ResourcePicker
                    max={
                      [
                        'Prospect Site',
                        'Prospect Site UHC',
                        'Prospect Site UHC - Optum',
                      ].includes(currentPage?.templateName)
                        ? 4
                        : 0
                    }
                    onChange={onChange}
                    options={resources}
                    tableColumns={resourcesTableColumns}
                    value={value}
                  />
                )}
                control={control}
                defaultValue={currentPage.resources}
              />
            </FormControl>
          </StepCardContent>
        </StepCard>
        <SiteMakerActions
          renderPreview={renderPreview}
          renderButtons={renderButtons}
        />
      </form>
      <Dialog
        title="Add resource"
        onCloseDialog={toggleShowResourcesDialog}
        open={showResourcesDialog}
      >
        <ResourceForm
          categories={resourceCategories}
          onSuccess={toggleShowResourcesDialog}
          user={user}
        />
      </Dialog>
    </SiteMaker>
  );
};

export default ResourcesBlockConfig;

import React from 'react';
import styled, { css } from 'styled-components';

const StyledCheckbox = styled.label`
  ${({ withLabel }) =>
    withLabel &&
    css`
      display: flex;
      align-items: center;
    `}
  height: 24px;
  line-height: 24px;

  .checkbox-text {
    margin-left: 8px;
  }
`;

const Checkbox = React.forwardRef(({ text, ...inputProps }, ref) => (
  <StyledCheckbox withLabel={!!text?.length}>
    <input type="checkbox" {...inputProps} ref={ref} />
    {!!text && <span className="checkbox-text">{text}</span>}
  </StyledCheckbox>
));

export default Checkbox;

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { generatePath, useHistory } from 'react-router-dom';
import {
  Button,
  Checkbox,
  FormControl,
  StepCard,
  StepCardContent,
  StepCardHeader,
  StepCardTitle,
  TextareaControl,
  TextControl,
  themes,
} from '@clatter/ui';
import { HeroBannerImage } from '@clatter/templates';
import { updatePage } from '../../store';
import ImagePicker from '../../components/ImagePicker/ImagePicker';
import { videoTableColumns } from '../../pages/ComponentsList/mocks';
import SingleVideoPicker from '../SingleVideoPicker/SingleVideoPicker';
import SiteMakerContentHead from '../SiteMaker/SiteMakerContentHead';
import PreviewButton from '../SiteMaker/PreviewButton';
import SiteMakerActions from '../SiteMaker/SiteMakerActions';
import { getNextPage, isSiteComplete } from '../../helpers';
import routes from '../../routes/routes';

const PageSettingsForm = ({
  currentMicrosite,
  currentPage,
  heroImages,
  videos,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pageVariables = currentPage?.variables
    ? JSON.parse(currentPage.variables)
    : {
        headline: '[default headline]',
        showOptionalCopyBlock: false,
      };

  const {
    control,
    formState: { errors },
    formState,
    getValues,
    handleSubmit,
    register,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: currentPage
      ? {
          // @todo page needs owner attribute
          // owner: 'vishalsg@prdxn.com',
          // @todo get user id from authenticated user
          // owner : '5fd204fdee9dc9c74acefaac',
          bannerVideo: pageVariables.bannerVideo,
          pageName: currentPage.title?.trim(),
          headline: pageVariables.headline,
          introCopy: pageVariables.introCopy,
          heroImage: pageVariables.heroImageId
            ? heroImages.find((hero) => hero.id === pageVariables.heroImageId)
            : undefined,
          showOptionalCopyBlock: pageVariables.showOptionalCopyBlock,
          optionalCopy: pageVariables.optionalCopy,
          optionalSectionTitle: pageVariables.optionalSectionTitle,
        }
      : {},
  });

  const redirectToPublish = () => {
    history.push(generatePath(routes.publishSite, { siteId: currentMicrosite.id }));
  };

  const handleFormSubmit = async (formData) => {
    const nextPage = getNextPage(currentMicrosite, currentMicrosite.pages);

    if (formState.isDirty) {
      const pageSettings = {
        id: currentPage.id,
        title: formData.pageName,
        variables: JSON.stringify({
          ...pageVariables,
          bannerVideo: formData.bannerVideo,
          headline: formData.headline,
          introCopy: formData.introCopy,
          heroImageId: formData.heroImage?.id,
          heroImageUrl: formData.heroImage?.url,
          showOptionalCopyBlock: formData.showOptionalCopyBlock,
          optionalCopy: formData.optionalCopy,
          optionalSectionTitle: formData.optionalSectionTitle,
        }),
      };

      await dispatch(updatePage(pageSettings));
      history.push(nextPage);
    } else {
      history.push(nextPage);
    }
  };

  const onPreviewClick = async () => {
    const data = getValues();
    const pageSettings = {
      id: currentPage.id,
      title: data.pageName,
      variables: JSON.stringify({
        ...pageVariables,
        bannerVideo: data.bannerVideo,
        headline: data.headline,
        introCopy: data.introCopy,
        heroImageId: data.heroImage.id,
        heroImageUrl: data.heroImage.url,
      }),
      optionalCopy: data.optionalCopy,
      optionalSectionTitle: data.optionalSectionTitle,
    };

    return await dispatch(updatePage(pageSettings));
  };

  const renderPreview = () => {
    const variables = JSON.parse(currentPage.variables);

    return (
      <ThemeProvider theme={themes[variables?.theme || 'default']}>
        <HeroBannerImage
          bannerVideo={watch('bannerVideo')}
          headline={watch('headline')}
          heroImageUrl={watch('heroImage')?.url}
          introCopy={watch('introCopy')}
          optionalCopy={watch('optionalCopy')}
          optionalSectionTitle={watch('optionalSectionTitle')}
        />
      </ThemeProvider>
    );
  };

  const renderButtons = () => (
    <>
      <Button disabled={!formState.isValid} type="submit">
        {formState.isDirty ? 'Save Banner and continue' : 'Continue'}
      </Button>
      <PreviewButton
        siteName={currentMicrosite.name}
        pageName={currentPage.name}
        onPreviewClick={onPreviewClick}
      />
      <Button
        disabled={!isSiteComplete(currentMicrosite, currentMicrosite.pages)}
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  return (
    <>
      <SiteMakerContentHead
        title="Customize your page banner"
        subhead="A preview of your banner will appear below."
      />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <StepCard>
          <StepCardHeader step={1}>
            <StepCardTitle text="Enter headline and intro copy" />
          </StepCardHeader>
          <StepCardContent>
            <FormControl label="Headline" error={errors.headline}>
              <TextControl
                {...register('headline', {
                  required: { value: true, message: 'Field is required' },
                })}
                placeholder="Enter a headline"
                maxLength={45}
              />
            </FormControl>
            <FormControl label="Intro Copy" error={errors.introCopy}>
              <TextControl
                {...register('introCopy', {
                  required: { value: true, message: 'Field is required' },
                })}
                placeholder="Enter the intro copy"
                maxLength={120}
              />
            </FormControl>
          </StepCardContent>
        </StepCard>
        <StepCard>
          <StepCardHeader step={2}>
            <StepCardTitle text="Select banner background" />
          </StepCardHeader>
          <StepCardContent>
            <FormControl noPadding error={errors.heroImage}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <ImagePicker
                    images={heroImages}
                    onChange={onChange}
                    value={value}
                  />
                )}
                control={control}
                name="heroImage"
                defaultValue={null}
              />
            </FormControl>
          </StepCardContent>
        </StepCard>
        <StepCard>
          <StepCardHeader step={3}>
            <StepCardTitle text="Select banner video" />
          </StepCardHeader>
          <StepCardContent>
            <p>
              Video selection in the banner is optional. In the upcoming video
              step, you will be able to select additional video options.
            </p>
            <FormControl noPadding error={errors.bannerVideo}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <SingleVideoPicker
                    onChange={onChange}
                    options={videos}
                    tableColumns={videoTableColumns}
                    value={value}
                  />
                )}
                control={control}
                name="bannerVideo"
                defaultValue={null}
              />
            </FormControl>
          </StepCardContent>
        </StepCard>
        {currentPage.templateName === 'Partner Site' && (
          <StepCard>
            <StepCardHeader step={4}>
              <StepCardTitle text="Optional copy block" />
            </StepCardHeader>
            <StepCardContent>
              <Checkbox
                {...register('showOptionalCopyBlock')}
                text="Include Optional copy block"
              />

              {watch('showOptionalCopyBlock') && (
                <div>
                  <FormControl
                    label="Section title"
                    error={errors.optionalSectionTitle}
                  >
                    <TextControl
                      maxLength={100}
                      {...register('optionalSectionTitle')}
                      placeholder="Enter section title"
                      defaultValue={currentPage?.optionalSectionTitle || ''}
                    />
                  </FormControl>
                  <FormControl label="Copy" error={errors.optionalCopy}>
                    <TextareaControl
                      maxLength={500}
                      {...register('optionalCopy', {
                        required: { value: true, message: 'Field is required' },
                      })}
                      placeholder="Enter copy"
                      defaultValue={currentPage?.optionalCopy || ''}
                    />
                  </FormControl>
                </div>
              )}
            </StepCardContent>
          </StepCard>
        )}
        <SiteMakerActions
          renderPreview={renderPreview}
          renderButtons={renderButtons}
        />
      </form>
    </>
  );
};

export default PageSettingsForm;

export const blobToDataUri = async (data, type) => {
  return new Promise((resolve) => {
    const blob = new Blob([data], type);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      resolve(reader.result);
    });
    reader.readAsDataURL(blob);
  });
};

export const getImageSize = (uploadedImage) =>
  new Promise((resolve) => {
    const image = new Image();
    image.src = window.URL.createObjectURL(uploadedImage);
    image.onload = () => resolve({ width: image.width, height: image.height });
  });

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { MICROSITESSETTINGS_FEATURE_KEY, micrositesSettingsReducer } from './microsites-settings.slice';
import { VIDEO_CATEGORIES_FEATURE_KEY, videoCategoriesReducer } from './video-categories.slice';
import { BIO_GROUPS_FEATURE_KEY, bioGroupsReducer } from './bio-groups.slice';
import { RESOURCE_CATEGORIES_FEATURE_KEY, resourceCategoriesReducer } from './resource-categories.slice';
import { MESSAGES_FEATURE_KEY, messagesReducer } from './messages.slice';
import { HERO_IMAGES_FEATURE_KEY, heroImagesReducer } from './hero-images.slice';
import { USERS_FEATURE_KEY, usersReducer } from './users.slice';
import { COBRANDLOGOS_FEATURE_KEY, cobrandLogosReducer } from './cobrand-logos.slice';
import { BLOCKTEMPLATES_FEATURE_KEY, BlockTemplatesReducer } from './blocktemplates.slice';
import { VIDEO_EMBEDS_FEATURE_KEY, videoEmbedsReducer } from './video-embeds.slice';
import { RESOURCES_FEATURE_KEY, resourcesReducer } from './resources.slice';
import { BIOS_FEATURE_KEY, biosReducer } from './bios.slice';
import { MICROSITES_FEATURE_KEY, micrositesReducer } from './microsites.slice';
import { PAGES_FEATURE_KEY, pagesReducer } from './pages.slice';
import { PAGETEMPLATES_FEATURE_KEY, pageTemplatesReducer } from './page-templates.slice';
import { PLATFORM_FEATURE_KEY, platformReducers } from '@clatter/platform';

export { fetchPageTemplates, postPageTemplate } from './page-templates.slice';
export { pagesActions, fetchPages, addPage, updatePage } from './pages.slice';
export { fetchMicrosites, updateMicrosite } from './microsites.slice';
export { fetchBios } from './bios.slice';
export { fetchResources } from './resources.slice';
export { fetchVideoEmbeds } from './video-embeds.slice';
export { fetchBlockTemplates } from './blocktemplates.slice';
export { addCobrandLogo, fetchCobrandLogos } from './cobrand-logos.slice';
export { fetchUsers } from './users.slice';
export { fetchHeroImages } from './hero-images.slice';

export const storeConfig = {
  reducer: {
    [MICROSITESSETTINGS_FEATURE_KEY]: micrositesSettingsReducer,
    [VIDEO_CATEGORIES_FEATURE_KEY]: videoCategoriesReducer,
    [BIO_GROUPS_FEATURE_KEY]: bioGroupsReducer,
    [RESOURCE_CATEGORIES_FEATURE_KEY]: resourceCategoriesReducer,
    [MESSAGES_FEATURE_KEY]: messagesReducer,
    [HERO_IMAGES_FEATURE_KEY]: heroImagesReducer,
    [USERS_FEATURE_KEY]: usersReducer,
    [COBRANDLOGOS_FEATURE_KEY]: cobrandLogosReducer,
    [BLOCKTEMPLATES_FEATURE_KEY]: BlockTemplatesReducer,
    [VIDEO_EMBEDS_FEATURE_KEY]: videoEmbedsReducer,
    [RESOURCES_FEATURE_KEY]: resourcesReducer,
    [BIOS_FEATURE_KEY]: biosReducer,
    [MICROSITES_FEATURE_KEY]: micrositesReducer,
    [PAGES_FEATURE_KEY]: pagesReducer,
    [PAGETEMPLATES_FEATURE_KEY]: pageTemplatesReducer,
    [PLATFORM_FEATURE_KEY]: platformReducers,
  },
  // Additional middleware can be passed to this array
  middleware: [...getDefaultMiddleware()],
  devTools: process.env.NODE_ENV !== 'production',
  // Optional Redux store enhancers
  enhancers: [],
}

export const store = configureStore(storeConfig);

import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { defaultBorderColor, FormMessage, ToggleControl } from '@clatter/ui';
import MessageProvider from '../MessageProvider/MessageProvider';

const StyledSiteMakerActions = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid ${defaultBorderColor};

  .site-maker-actions-content {
    max-width: 1400px;
    padding-left: 312px;
    padding-right: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .site-maker-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;

    > * + * {
      margin-left: 8px;
    }
  }

  .preview-toggle {
    margin: 16px;
  }

  .site-maker-preview {
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
  }

  .site-maker-message {
    margin-top: 8px;
    margin-left: 8px;
  }
`;

const SiteMakerActions = ({ renderButtons, renderPreview }) => {
  const [showPreview, setShowPreview] = useState(false);
  const toggleShowPreview = () => {
    setShowPreview(!showPreview);
  };

  const hasPreview = typeof renderPreview === 'function';

  return (
    <StyledSiteMakerActions>
      {hasPreview && showPreview && (
        <div className="site-maker-preview">{renderPreview()}</div>
      )}
      <MessageProvider subscriber="updateMicrosite">
        {(message, handleDismiss) => (
          <div className="site-maker-message">
            <FormMessage message={message} onDismiss={handleDismiss} />
          </div>
        )}
      </MessageProvider>
      <div className="site-maker-actions-content">
        <div className="site-maker-buttons">{renderButtons()}</div>
        {hasPreview && (
          <ToggleControl
            className="preview-toggle"
            value={showPreview}
            onChange={toggleShowPreview}
            activeLabel="Hide preview"
            inactiveLabel="Show preview"
          />
        )}
      </div>
    </StyledSiteMakerActions>
  );
};

SiteMakerActions.propTypes = {
  renderPreview: PropTypes.func,
  renderButtons: PropTypes.func.isRequired,
};

export default SiteMakerActions;

import React, { useEffect, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import { useParams, useHistory, generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import {
  fetchMicrosites,
  fetchPageTemplates,
  fetchPages,
  updatePage,
  fetchVideoEmbeds,
} from '../../store';
import SiteMaker from '../../components/SiteMaker/SiteMaker';
import {
  Button,
  FormControl,
  Loader,
  StepCard,
  StepCardContent,
  StepCardHeader,
  StepCardTitle,
  themes,
} from '@clatter/ui';
import { VideoGallery } from '@clatter/templates';
import { Section } from '@clatter/templates';
import VideoPicker from '../../components/VideoPicker/VideoPicker';
import { pageFromStore } from '../fromStore';
import { videoTableColumns } from '../ComponentsList/mocks';
import SiteMakerContentHead from '../../components/SiteMaker/SiteMakerContentHead';
import { getNextPage, isSiteComplete } from '../../helpers';
import SiteMakerActions from '../../components/SiteMaker/SiteMakerActions';
import PreviewButton from '../../components/SiteMaker/PreviewButton';
import { selectAllVideoEmbeds } from '../../store/video-embeds.slice';
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import routes from '../../routes/routes';

const VideosBlockConfig = () => {
  const { pageId } = useParams();
  const history = useHistory();
  const { user, isAuthenticated, isLoading } = useAuth0();

  const {
    control,
    formState: { errors },
    formState,
    handleSubmit,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const handleFormSubmit = async (formData) => {
    if (formState.isDirty) {
      await dispatch(
        updatePage({
          id: currentPage.id,
          ...formData,
        }),
      ).then(() => {
        history.push(getNextPage(currentMicrosite, currentMicrosite.pages));
      });
    } else {
      history.push(getNextPage(currentMicrosite, currentMicrosite.pages));
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPages());
    dispatch(fetchMicrosites(user));
    dispatch(fetchPageTemplates());
    dispatch(fetchVideoEmbeds());
  }, [dispatch, user]);

  const videosLoading = useSelector((state) => state.videoEmbeds.loadingStatus);
  const pageTemplatesLoading = useSelector(
    (state) => state.pageTemplates.loadingStatus,
  );
  const micrositesLoading = useSelector(
    (state) => state.microsites.loadingStatus,
  );
  const pagesLoading = useSelector((state) => state.pages.loadingStatus);

  const loading =
    videosLoading === 'loading' ||
    pageTemplatesLoading === 'loading' ||
    micrositesLoading === 'loading' ||
    pagesLoading === 'loading';

  const pageTemplates = useSelector(selectAllPageTemplates);

  const videos = useSelector(selectAllVideoEmbeds);

  const currentMicrosite = useSelector((state) => {
    if (state.pages.ids.indexOf[pageId] === -1) {
      return;
    }

    const siteId = state.microsites.ids.find((micrositeId) =>
      state.microsites.entities[micrositeId].pages.some(
        (page) => page.id === pageId,
      ),
    );

    if (!siteId || !state.microsites.entities[siteId]) {
      return;
    }

    return {
      ...state.microsites.entities[siteId],
      pages: state.microsites.entities[siteId].pages?.map((page) =>
        pageFromStore(page, pageTemplates),
      ),
    };
  });

  const currentPage = useMemo(() => {
    if (!currentMicrosite || !videos || !videos.length) {
      return null;
    }

    const nextCurrentPage = {
      ...currentMicrosite?.pages?.find(({ id }) => id === pageId),
    };

    nextCurrentPage.video_embeds = nextCurrentPage.video_embeds.length
      ? nextCurrentPage.video_embeds
          .map((item) => {
            if (typeof item === 'string') {
              return videos.find(({ _id }) => item === _id);
            }

            return item;
          })
          .filter((video) => !!video)
      : [];

    return nextCurrentPage;
  }, [currentMicrosite, videos, pageId]);

  const redirectToPublish = () => {
    history.push(generatePath(routes.publishSite, { siteId: currentMicrosite.id }));
  };

  const templateVideosRange = {
    'Additional Resources with videos': {
      min: 0,
      max: 5,
    },
  };

  const getVideosRange = (templateName) =>
    templateVideosRange[templateName] || { min: 3, max: 6 };

  const getSubhead = () => {
    const videosRange = getVideosRange(currentPage?.templateName);

    const subheadText =
      'Add {range} videos to the content section of your page. A preview of your selected videos will appear below.';

    if (videosRange.min && videosRange.max) {
      return subheadText.replace(
        '{range}',
        `between ${videosRange.min} to ${videosRange.max}`,
      );
    }

    if (videosRange.min) {
      return subheadText.replace(
        '{range}',
        `at least ${videosRange.min} ${
          videosRange.min === 1 ? 'video' : 'videos'
        }`,
      );
    }

    if (videosRange.max) {
      return subheadText.replace(
        '{range}',
        `up to ${videosRange.max} ${
          videosRange.max === 1 ? 'video' : 'videos'
        }`,
      );
    }

    return subheadText.replace('{range} ', '');
  };

  const renderPreview = () => {
    const variables = JSON.parse(currentPage.variables);

    // @todo the hard-wired check for the templateName has to be replaced by proper
    // { page } X { template } block parameters
    return (
      <ThemeProvider theme={themes[variables?.theme || 'default']}>
        <Section name="videoGallery">
          <VideoGallery
            showDescription={[
              'Prospect Site UHC',
              'Prospect Site UHC - Optum',
            ].includes(currentPage?.templateName)}
            videos={watch('video_embeds') || currentPage?.video_embeds}
          />
        </Section>
      </ThemeProvider>
    );
  };

  const renderButtons = () => (
    <>
      <Button disabled={!formState.isValid} type="submit">
        {formState.isDirty ? 'Save Videos and continue' : 'Continue'}
      </Button>
      <PreviewButton
        siteName={currentMicrosite.name}
        pageName={currentPage?.name}
      />
      <Button
        disabled={!isSiteComplete(currentMicrosite, currentMicrosite.pages)}
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  if (loading) {
    return <Loader />;
  }

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }

  if (!isAuthenticated) {
    // shouldn't happen, as this component is only displayed in a ProtectedRoute
    return <div>Not Authorized.</div>;
  }

  if (
    !loading &&
    (!currentMicrosite || !currentMicrosite.pages || !currentPage)
  ) {
    return <p>Couldn't load current page</p>;
  }

  return (
    <SiteMaker site={currentMicrosite}>
      <SiteMakerContentHead
        title="Select your videos"
        subhead={getSubhead()}
        pageId={pageId}
        siteName={currentMicrosite?.name}
        pageName={currentPage?.name}
      />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <StepCard>
          <StepCardHeader step={1}>
            <StepCardTitle text="Select videos" />
          </StepCardHeader>
          <StepCardContent>
            <FormControl noPadding error={errors.video_embeds}>
              <Controller
                name="video_embeds"
                rules={{
                  validate: (value) => {
                    const itemsCount = value?.length;

                    if (!itemsCount) {
                      return 'Field is required';
                    }

                    const videosRange = getVideosRange(
                      currentPage?.templateName,
                    );

                    if (videosRange.min && itemsCount < videosRange.min) {
                      return `Pick at least ${videosRange.min} ${
                        videosRange.min === 1 ? 'video' : 'videos'
                      }`;
                    }

                    if (videosRange.max && itemsCount > videosRange.max) {
                      return `Pick up to ${videosRange.min} ${
                        videosRange.min === 1 ? 'video' : 'videos'
                      }`;
                    }

                    return true;
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <VideoPicker
                    max={getVideosRange(currentPage?.templateName).max}
                    onChange={onChange}
                    options={videos}
                    tableColumns={videoTableColumns}
                    value={value}
                  />
                )}
                control={control}
                defaultValue={currentPage?.video_embeds}
              />
            </FormControl>
          </StepCardContent>
        </StepCard>
        <SiteMakerActions
          renderPreview={renderPreview}
          renderButtons={renderButtons}
        />
      </form>
    </SiteMaker>
  );
};

export default VideosBlockConfig;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DataTable, Dialog, IconButton, Loader } from '@clatter/ui';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AdminContentHead from '../AdminContentHead';
import { formatDate } from '../../../helpers';
import VideoEmbedForm from './VideoEmbedForm';
import {
  deleteVideoEmbed,
  fetchVideoEmbeds,
  selectAllVideoEmbeds,
} from '../../../store/video-embeds.slice';
import VideoPreviewDialog from '../../VideoPreviewDialog/VideoPreviewDialog';

const videoCategoryColumns = [
  {
    label: 'Title',
    name: 'title',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Category',
    name: 'category',
    customRender: (row) => row?.video_category?.categoryName,
    align: 'left',
  },
  {
    label: 'Date added',
    name: 'createdAt',
    customRender: (row) => formatDate(row.createdAt, true),
    align: 'left',
    sortable: true,
  },
];

const VideoEmbeds = () => {
  const dispatch = useDispatch();
  const [editableItem, setEditableItem] = useState(null);
  const [previewVideo, setPreviewVideo] = useState(null);

  useEffect(() => {
    dispatch(fetchVideoEmbeds());
  }, [dispatch]);

  const videoEmbeds = useSelector(selectAllVideoEmbeds);

  const loading = useSelector(
    (state) => state.videoEmbeds.loadingStatus === 'loading',
  );

  const handleEditClick = (event) => {
    const clickedItemId = event.currentTarget?.dataset?.id;

    setEditableItem(
      (clickedItemId &&
        videoEmbeds.find(
          (videoCategory) => videoCategory.id === clickedItemId,
        )) ||
        {},
    );
  };

  const handleDismissEdit = () => {
    setEditableItem(null);
  };

  const handleDeleteClick = (event) => {
    if (window.confirm('Are you sure you want to remove this category?')) {
      dispatch(deleteVideoEmbed(event.currentTarget.dataset.id));
    }
  };

  const handlePreviewClick = (event) => {
    const previewVideo = videoEmbeds.find(
      (item) => item.id === event.currentTarget.dataset.id,
    );

    setPreviewVideo(previewVideo);
  };

  const handleClosePreviewDialog = () => {
    setPreviewVideo(null);
  };

  const isEdit = editableItem && 'link' in editableItem;

  return (
    <>
      {loading && <Loader />}
      <AdminContentHead>
        <h1>Video categories</h1>
        <Button onClick={handleEditClick}>Add video embed</Button>
      </AdminContentHead>
      <div>
        <DataTable
          autofit
          columns={videoCategoryColumns}
          defaultSortField="title"
          filterColumns={['title']}
          name="adminVideos"
          rows={videoEmbeds}
          renderActionColumn={(row) => (
            <>
              <IconButton data-id={row.id} onClick={handlePreviewClick}>
                <VisibilityOutlinedIcon />
              </IconButton>
              <IconButton data-id={row.id} onClick={handleEditClick}>
                <EditOutlinedIcon />
              </IconButton>
              <IconButton data-id={row.id} onClick={handleDeleteClick}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </>
          )}
        />
      </div>
      <VideoPreviewDialog
        title={previewVideo?.title}
        videoUrl={previewVideo?.link}
        onCloseDialog={handleClosePreviewDialog}
      />
      <Dialog
        title={isEdit ? 'Edit video embed' : 'Add video embed'}
        onCloseDialog={handleDismissEdit}
        open={!!editableItem}
      >
        <VideoEmbedForm
          isEdit={isEdit}
          editableItem={editableItem}
          onSuccess={handleDismissEdit}
        />
      </Dialog>
    </>
  );
};

export default VideoEmbeds;

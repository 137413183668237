import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import CryptoJS from 'crypto-js';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import {
  Button,
  Checkbox,
  FormButtons,
  FormControl,
  FormMessage,
  Loader,
  messageTypes,
  PasswordValidatorStatus,
  TextControl,
} from '@clatter/ui';
import {
  fetchPages,
  fetchMicrosites,
  fetchPageTemplates,
  updateMicrosite,
} from '../../store';
import SiteMaker from '../../components/SiteMaker/SiteMaker';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { pageFromStore } from '../fromStore';
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import { patterns } from '../../helpers';

const SITES_HOST = process.env.NX_SITES_HOST;
const pageBaseUrl = `${SITES_HOST}/sites`;
const clearPreviewUrl = `${SITES_HOST}/api/preview?clear`;

const StyledPublish = styled.div`
  .site-urls {
    background: #fff;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 16px;
    display: inline-block;
    margin-bottom: 16px;

    h3 {
      padding: 0;
      margin: 0;
    }

    .page {
      margin-top: 8px;

      &:first-child {
        margin-top: 0;
      }

      .page-title {
        font-size: 14px;
      }

      .page-url {
        font-size: 12px;
      }
    }
  }

  .require-password-miniform {
    width: 300px;
    margin-bottom: 16px;

    .password-inputs {
      padding: 16px 0;
    }
  }
`;

const Publish = () => {
  const { siteId } = useParams();
  const { user, isAuthenticated, isLoading } = useAuth0();

  const {
    formState,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    trigger,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const handlePublish = (formData) => {
    const siteName = currentMicrosite.name;
    const password = formData.password;
    // @todo we ideally use a distinct, randomly-generated salt
    // we should also ideally use >80,000 iterations and PBKDF2
    // but we're doing everything else right *except* hash-stretching
    const salt = CryptoJS.SHA256(siteName).toString();
    const site_password_hash = CryptoJS.SHA256(salt + password).toString();

    const updatedMicrosite = {
      id: siteId,
      password_hash: requirePassword ? site_password_hash : null,
      published: true,
    };

    dispatch(updateMicrosite(updatedMicrosite));
  };

  const handleUnpublish = () => {
    const updatedMicrosite = {
      id: siteId,
      published: false,
      password_hash: null,
    };

    dispatch(updateMicrosite(updatedMicrosite));

    setValue('password', '', { shouldValidate: true });
    setValue('repeatPassword', '', { shouldValidate: true });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPages());
    dispatch(fetchMicrosites(user));
    dispatch(fetchPageTemplates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const loading = useSelector(
    (state) =>
      state.pageTemplates.loadingStatus === 'loading' ||
      state.pages.loadingStatus === 'loading' ||
      state.microsites.loadingStatus === 'loading',
  );

  const pageTemplates = useSelector(selectAllPageTemplates);

  const currentMicrosite = useSelector((state) => {
    if (!siteId || !state.microsites.entities[siteId]) {
      return;
    }

    return {
      ...state.microsites.entities[siteId],
      pages: state.microsites.entities[siteId].pages?.map((page) =>
        pageFromStore(page, pageTemplates),
      ),
    };
  });

  const [requirePassword, setRequirePassword] = useState(true);
  const toggleRequirePassword = () => setRequirePassword(!requirePassword);

  useEffect(() => trigger(), [requirePassword, trigger]);

  const validators = [
    {
      pattern: /[A-Za-z\d#?!@$%^&*-]{10,}/,
      text: 'Password must be 10 characters',
    },
    {
      pattern: /(?=.*[A-Z])/,
      text: 'Password must have a capital letter',
    },
    {
      pattern: /(?=.*[a-z])/,
      text: 'Password must have a lower-case letter',
    },
    {
      pattern: /(?=.*[#?!@$%^&*-])/,
      text: 'Password must have a symbol (#?!@$%^&*-)',
    },
    {
      pattern: /(?=.*\d)/,
      text: 'Password must have a number',
    },
    {
      pattern: /^\S+$/,
      text: 'Password must not have spaces',
    },
    {
      pattern: `^((?!${currentMicrosite?.client_name}).)*$`,
      ignoreCase: true,
      text: 'Password cannot include client name',
    },
  ];

  const showPasswordFields =
    typeof currentMicrosite?.published === 'boolean' &&
    !currentMicrosite?.published &&
    requirePassword;

  const renderButtons = () => {
    if (currentMicrosite?.published) {
      return <Button type="submit">Unpublish</Button>;
    }

    // @todo correctly update isPublishable state then change to
    // const enablePublish = currentMicrosite.isPublishable
    const enablePublish = true;
    return (
      <FormButtons>
        <Button disabled={!enablePublish || !formState.isValid} type="submit">
          Publish
        </Button>
      </FormButtons>
    );
  };

  const handleFormSubmit = currentMicrosite?.published
    ? handleUnpublish
    : handlePublish;

  // warning: this is a lazy hack. what should be done is
  // to implement a click handler for the open-in-new link to call
  // this api *then*. i'm just trying to confirm the behavior of
  // the static page service first.
  // @todo modify to call in a click-handler for the open-in-new link
  axios.get(clearPreviewUrl);

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }

  if (!isAuthenticated) {
    // shouldn't happen, as this component is only displayed in a ProtectedRoute
    return <div>Not Authorized.</div>;
  }

  if (!loading && (!currentMicrosite || !currentMicrosite.pages)) {
    return <p>Couldn't load microsite</p>;
  }

  return (
    <SiteMaker site={currentMicrosite}>
      {loading && <Loader />}
      <StyledPublish>
        <h1 className="page-header">Publish your microsite</h1>
        <h3 className="page-header">URLs</h3>
        <div className="site-urls">
          {currentMicrosite?.pages?.map((page) => (
            <div className="page" key={page.id}>
              <div className="page-title">{page.title}</div>
              <div className="page-url">
                {currentMicrosite.published ? (
                  <a
                    href={`${pageBaseUrl}/${currentMicrosite.name}/${page.title}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {`${pageBaseUrl}/${currentMicrosite.name}/${page.title} `}
                    <OpenInNewIcon />
                  </a>
                ) : (
                  `${pageBaseUrl}/${currentMicrosite.name}/${page.title}`
                )}
              </div>
            </div>
          ))}
        </div>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="require-password-miniform">
            <Checkbox
              disabled={currentMicrosite?.published}
              text="Require Password"
              onChange={toggleRequirePassword}
              checked={requirePassword}
            />
            {showPasswordFields && (
              <div className="password-inputs">
                <FormControl label="Password" error={errors?.password}>
                  <TextControl
                    type="password"
                    autoComplete="false"
                    {...register('password', {
                      required: {
                        value: true,
                        message: 'Password is required',
                      },
                      pattern: {
                        value: patterns.password,
                        message: 'Invalid password',
                      },
                    })}
                    onBlur={() => {
                      trigger('repeatPassword');
                    }}
                  />
                </FormControl>
                <FormControl
                  label="Re-enter password"
                  error={errors?.repeatPassword}
                >
                  <TextControl
                    type="password"
                    autoComplete="false"
                    {...register('repeatPassword', {
                      validate: (value) => {
                        const password = watch('password');

                        if (value !== password) {
                          return 'Password and Re-enter password do not match';
                        }

                        return true;
                      },
                    })}
                  />
                </FormControl>
                <PasswordValidatorStatus
                  password={watch('password')}
                  validators={validators}
                />
              </div>
            )}
          </div>
          {showPasswordFields && (
            <FormMessage
              message={{
                type: messageTypes.info,
                text: 'Please save this password somewhere safe. Once the site is published and you leave this page, you\'ll no longer have access to this password. If you lose or forget the password, please "unpublish" then "publish" for a new password.',
              }}
            />
          )}
          {renderButtons()}
        </form>
      </StyledPublish>
    </SiteMaker>
  );
};

export default Publish;

import React, { useEffect, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import { useParams, useHistory, generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import {
  fetchMicrosites,
  fetchPageTemplates,
  fetchPages,
  updatePage,
  fetchVideoEmbeds,
} from '../../store';
import SiteMaker from '../../components/SiteMaker/SiteMaker';
import {
  Button,
  FormControl,
  Loader,
  StepCard,
  StepCardContent,
  StepCardHeader,
  StepCardTitle,
  themes,
} from '@clatter/ui';
import VideoPicker from '../../components/VideoPicker/VideoPicker';
import { pageFromStore } from '../fromStore';
import { videoTableColumns } from '../ComponentsList/mocks';
import SiteMakerContentHead from '../../components/SiteMaker/SiteMakerContentHead';
import { getNextPage, isSiteComplete } from '../../helpers';
import { VideosTable } from '@clatter/templates';
import Section from 'libs/templates/src/lib/blocks/Section';
import SiteMakerActions from '../../components/SiteMaker/SiteMakerActions';
import PreviewButton from '../../components/SiteMaker/PreviewButton';
import { selectAllVideoEmbeds } from '../../store/video-embeds.slice';
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import routes from '../../routes/routes';

const AdditionalVideosBlock = () => {
  const { pageId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }
  if (!isAuthenticated) {
    // shouldn't happen, as this component is only displayed in a ProtectedRoute
    return <div>Not Authorized.</div>;
  }

  const {
    formState,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const handleFormSubmit = (formData) => {
    if (formState.isDirty) {
      dispatch(
        updatePage({
          id: currentPage.id,
          ...formData,
          variables: '{}',
        }),
      ).then(() => {
        history.push(getNextPage(currentMicrosite, currentMicrosite.pages));
      });
    } else {
      history.push(getNextPage(currentMicrosite, currentMicrosite.pages));
    }
  };

  useEffect(() => {
    dispatch(fetchPages());
    dispatch(fetchMicrosites(user));
    dispatch(fetchPageTemplates());
    dispatch(fetchVideoEmbeds());
  }, [dispatch]);

  const videosLoading = useSelector((state) => state.videoEmbeds.loadingStatus);

  const pageTemplatesLoading = useSelector(
    (state) => state.pageTemplates.loadingStatus,
  );

  const micrositesLoading = useSelector(
    (state) => state.microsites.loadingStatus,
  );

  const pagesLoading = useSelector((state) => state.pages.loadingStatus);

  const loading =
    videosLoading === 'loading' ||
    pageTemplatesLoading === 'loading' ||
    micrositesLoading === 'loading' ||
    pagesLoading === 'loading';

  const pageTemplates = useSelector(selectAllPageTemplates);

  const videos = useSelector(selectAllVideoEmbeds);

  const currentMicrosite = useSelector((state) => {
    if (state.pages.ids.indexOf[pageId] === -1) {
      return;
    }

    const siteId = state.microsites.ids.find((micrositeId) =>
      state.microsites.entities[micrositeId].pages.some(
        (page) => page.id === pageId,
      ),
    );

    if (!siteId || !state.microsites.entities[siteId]) {
      return;
    }

    return {
      ...state.microsites.entities[siteId],
      pages: state.microsites.entities[siteId].pages?.map((page) =>
        pageFromStore(page, pageTemplates),
      ),
    };
  });

  const currentPage = useMemo(() => {
    if (!currentMicrosite || !videos || !videos.length) {
      return null;
    }

    const nextCurrentPage = {
      ...currentMicrosite?.pages?.find(({ id }) => id === pageId),
    };

    nextCurrentPage.video_embeds = videos.filter(({ id }) =>
      nextCurrentPage.video_embeds.includes(id),
    );

    return nextCurrentPage;
  }, [currentMicrosite, videos]);

  const redirectToPublish = () => {
    history.push(generatePath(routes.publishSite, { siteId: currentMicrosite.id }));
  };

  const renderPreview = () => {
    const variables = JSON.parse(currentPage.variables);

    return (
      <ThemeProvider theme={themes[variables.theme || 'default']}>
        <Section backgroundColor="#f4f7fc">
          <VideosTable videos={watch('video_embeds') || pageVideos} />
        </Section>
      </ThemeProvider>
    );
  };

  const renderButtons = () => (
    <>
      <Button disabled={!formState.isValid} type="submit">
        {formState.isDirty ? 'Save Videos Table and continue' : 'Continue'}
      </Button>
      <PreviewButton
        siteName={currentMicrosite.name}
        pageName={currentPage.name}
      />
      <Button
        disabled={!isSiteComplete(currentMicrosite, currentMicrosite.pages)}
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  if (loading) {
    return <Loader />;
  }

  if (
    !loading &&
    (!currentMicrosite || !currentMicrosite.pages || !currentPage)
  ) {
    return <p>Couldn't load current page</p>;
  }

  return (
    <SiteMaker site={currentMicrosite}>
      <SiteMakerContentHead
        title="Select your videos"
        subhead="Please select your videos. A preview of your selected videos will appear below."
        pageId={pageId}
        siteName={currentMicrosite?.name}
        pageName={currentPage?.name}
      />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <StepCard>
          <StepCardHeader step={1}>
            <StepCardTitle text="Select videos" />
          </StepCardHeader>
          <StepCardContent>
            <FormControl noPadding error={errors.video_embeds}>
              <Controller
                name="video_embeds"
                rules={{
                  validate: (value) => {
                    if (!value || !value.length) {
                      return 'Field is required';
                    }

                    return true;
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <VideoPicker
                    onChange={onChange}
                    options={videos}
                    tableColumns={videoTableColumns}
                    value={value}
                  />
                )}
                control={control}
                defaultValue={currentPage?.video_embeds || []}
              />
            </FormControl>
          </StepCardContent>
        </StepCard>
        <SiteMakerActions
          renderPreview={renderPreview}
          renderButtons={renderButtons}
        />
      </form>
    </SiteMaker>
  );
};

export default AdditionalVideosBlock;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ClearOutlined as ClearOutlinedIcon,
  InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
} from '@material-ui/icons';
import CircularProgress from '../ProgressBar/CircularProgressBar';
import IconButton from '../IconButton/IconButton';

export const formatFileSize = (fileSize, format = 'KB') => {
  switch (format) {
    case 'KB':
      return `${Math.floor(fileSize / 1000)} KB`;
    case 'MB':
      return `${Math.floor(fileSize / 1000000)} MB`;
    default:
      return `${fileSize} B`;
  }
};

const StyledFileUpload = styled.div.attrs((props) => {
  const styles = {
    borderColor: '#1890ff',
    backgroundColor: '#fff',
  };

  if (props.disabled) {
    styles.borderColor = props.theme.palette.darkGrey;
    styles.backgroundColor = props.theme.palette.grey;
  } else if (props.error) {
    styles.borderColor = props.theme.palette.error;
  }

  return styles;
})`
  .uploader {
    background-color: ${({ backgroundColor }) => backgroundColor};
    position: relative;
    border: ${({ borderColor }) => `1px dashed ${borderColor}`};
    border-radius: 4px;
    padding: 48px 24px;
    text-align: center;

    input[type='file'] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    &:hover {
      border-style: solid;
    }

    p {
      margin: 0;
    }

    .progressbar {
      margin-top: 1em;
    }
  }

  .upload-text {
    line-height: 24px;
  }

  .file {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 8px;
    padding: 8px 12px;
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;

    .file-icon {
      fill: #1890ff;
      font-size: 16px;
    }

    .file-name {
      padding: 0 8px;
      font-size: 14px;
      max-width: 60%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .file-size {
      padding: 0 8px;
      font-size: 12px;
    }

    .file-remove {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const FileUpload = ({
  error,
  acceptedFileTypes = '*/*',
  multiple = false,
  onChange,
  previewOnTop = false,
  renderCustomPreview,
  value = null,
  disabled = false,
  showAcceptedFileTypes = false,
  showProgressbar = false,
  progressbarPercent = 0,
  customText,
}) => {
  const [files, setFiles] = useState(value);

  const handleChange = (event) => {
    if (event.target.files.length) {
      let nextFiles;

      if (multiple) {
        nextFiles = [...files, ...event.target.files];
      } else {
        nextFiles = event.target.files[0];
      }

      setFiles(nextFiles);
      typeof onChange === 'function' && onChange(nextFiles);
    }
  };

  const handleClick = (event) => {
    event.target.value = null;
  };

  const handleRemoveFileClick = (event) => {
    let nextFiles = null;
    if (multiple) {
      const fileIndex = Number(event.currentTarget.dataset.fileIndex);
      nextFiles = files.filter((file, index) => index !== fileIndex);
    }
    setFiles(nextFiles);
    typeof onChange === 'function' && onChange(nextFiles);
  };

  const renderPreviewItem = (file, index = 0) => (
    <div className="file" key={`${file.name}-${file.size}`}>
      <InsertDriveFileOutlinedIcon className="file-icon" />
      <div className="file-name">{file.name}</div>
      <div className="file-size">{formatFileSize(file.size)}</div>
      <IconButton
        className="file-remove"
        data-file-index={index}
        onClick={handleRemoveFileClick}
      >
        <ClearOutlinedIcon />
      </IconButton>
    </div>
  );

  const renderPreview = () => {
    if (!files || (Array.isArray(files) && files.length === 0)) {
      return null;
    }

    if (typeof renderCustomPreview === 'function') {
      return renderCustomPreview(files, handleRemoveFileClick);
    }

    if (multiple || Array.isArray(files)) {
      return files.map(renderPreviewItem);
    }

    return renderPreviewItem(files);
  };

  return (
    <StyledFileUpload error={error & !disabled} disabled={disabled}>
      {previewOnTop && renderPreview()}
      <div className="uploader">
        <p className="upload-text">
          {customText
            ? customText
            : `Drop file${multiple ? 's' : ''} here or click to upload`}
        </p>
        {showAcceptedFileTypes && (
          <p className="upload-text">
            {`Accepted file types: ${acceptedFileTypes}`}
          </p>
        )}
        {showProgressbar && <CircularProgress value={progressbarPercent} />}
        <input
          disabled={disabled}
          type="file"
          id="file"
          name="file"
          onChange={handleChange}
          onClick={handleClick}
          accept={acceptedFileTypes}
          multiple={multiple}
        />
      </div>
      {!previewOnTop && renderPreview()}
    </StyledFileUpload>
  );
};

const FileType = PropTypes.shape({
  name: PropTypes.string,
  size: PropTypes.number,
});

FileUpload.propTypes = {
  acceptedFileTypes: PropTypes.string,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.arrayOf(FileType), FileType]),
};

export default FileUpload;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  FormButtons,
  FormControl,
  SelectControl,
  TextControl,
} from '@clatter/ui';
import { fetchVideoCategories } from '../../../store/video-categories.slice';
import {
  createVideoEmbed,
  updateVideoEmbed,
} from '../../../store/video-embeds.slice';

const StyledCreateVideoCategoryForm = styled.form`
  width: 480px;
`;

const CreateVideoCategoryForm = ({ editableItem, isEdit, onSuccess }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVideoCategories());
  }, []);

  const videoCategories = useSelector((state) =>
    Object.values(state.videoCategories.entities).sort((a, b) =>
      a.updatedAt > b.updatedAt ? -1 : 1,
    ),
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: editableItem,
  });

  const onSubmit = async (formData) => {
    console.debug({ formData });
    dispatch(
      isEdit
        ? updateVideoEmbed({ id: editableItem.id, formData })
        : createVideoEmbed(formData),
    ).then(() => {
      onSuccess();
    });
  };

  return (
    <StyledCreateVideoCategoryForm onSubmit={handleSubmit(onSubmit)}>
      <FormControl label="Category name" error={errors.title}>
        <TextControl
          {...register('title', {
            required: { value: true, message: 'Field is required' },
          })}
          placeholder="Enter title"
        />
      </FormControl>
      <FormControl label="Description" error={errors.description}>
        <TextControl
          {...register('description', {
            required: { value: true, message: 'Field is required' },
          })}
          placeholder="Enter description"
        />
      </FormControl>
      <FormControl label="Video url" error={errors.link}>
        <TextControl
          {...register('link', {
            required: { value: true, message: 'Field is required' },
          })}
          placeholder="Enter video url"
        />
      </FormControl>
      <FormControl label="Video download url" error={errors.download_link}>
        <TextControl
          {...register('download_link', {
            required: { value: true, message: 'Field is required' },
          })}
          placeholder="Enter download url"
        />
      </FormControl>
      <FormControl label="Category">
        <Controller
          name="video_category"
          render={({ field: { onChange, value } }) => (
            <SelectControl
              onChange={onChange}
              options={videoCategories}
              value={value}
              getOptionLabel={(option) => option.categoryName}
              getOptionValue={(option) => option.id}
            />
          )}
          control={control}
        />
      </FormControl>
      <FormButtons>
        <Button
          disabled={!formState.isValid || !formState.isDirty}
          type="submit"
        >
          {isEdit ? 'Save' : 'Create'}
        </Button>
      </FormButtons>
    </StyledCreateVideoCategoryForm>
  );
};

export default CreateVideoCategoryForm;

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import {
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
} from '@material-ui/icons';
import { primaryColor } from '../../config/theme';
import DropDown from '../DropDown/DropDown';

const StyledSidebar = styled.div`
  .sidebar-trigger {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin: 0 16px 0 0;
    width: 32px;
    height: 32px;

    svg {
      font-size: 30px;
    }
  }

  .sidebar-overlay {
    transition: all 0.3s ease-in;
    position: fixed;
    top: ${({ theme }) => theme.pageHeader.height};
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(17, 17, 17, 0.36);
    opacity: 0;
    visibility: hidden;

    .sidebar-content {
      transition: all 0.3s ease-in;
      width: 250px;
      height: 100%;
      background-color: #fff;
      transform: translateX(-100%);
      padding: 20px 0;

      .tool-selector {
        padding: 0 30px 20px;
      }

      .sidebar-nav {
        display: flex;
        flex-direction: column;

        a {
          border-left: 2px solid transparent;
          color: rgba(54, 54, 54, 0.54);
          display: flex;
          padding: 0 16px 0 32px;
          height: 40px;
          align-items: center;

          svg {
            margin-right: 12px;
          }

          span {
            font-size: 12px;
            font-weight: 700;
          }

          + a {
            margin-top: 10px;
          }

          &:hover,
          &.active {
            background-color: rgba(37, 119, 137, 0.09);
            border-left-color: ${primaryColor};
          }
        }
      }
    }

    &.sidebar-opened {
      opacity: 1;
      visibility: visible;

      .sidebar-content {
        transform: translateX(0);
      }
    }
  }
`;

const Sidebar = ({ menuItems, toolItems }) => {
  const [opened, setOpened] = useState(false);
  const overlayRef = useRef(null);
  const activeToolItem = toolItems.find((tool) =>
    tool?.url?.includes(window.location.origin),
  );

  const toggleOpened = () => setOpened(!opened);

  const handleOverlayClick = (event) => {
    if (overlayRef.current && overlayRef.current === event.target && opened) {
      toggleOpened();
    }
  };

  if (!menuItems || menuItems.length < 2) {
    return null;
  }

  return (
    <StyledSidebar>
      <button className="sidebar-trigger" onClick={toggleOpened}>
        {opened ? <ArrowBackIcon /> : <MenuIcon />}
      </button>
      <div
        className={classNames('sidebar-overlay', {
          'sidebar-opened': opened,
        })}
        ref={overlayRef}
        onClick={handleOverlayClick}
      >
        <div className="sidebar-content">
          <div className="tool-selector">
            <DropDown hideActive options={toolItems} value={activeToolItem} />
          </div>
          <div className="sidebar-nav">
            {menuItems.map(({ label, url, icon: Icon, exact }) => (
              <NavLink exact={exact} key={url} to={url}>
                <Icon />
                <span>{label}</span>
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </StyledSidebar>
  );
};

Sidebar.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      icon: PropTypes.object.isRequired,
      exact: PropTypes.bool,
    }),
  ),
  toolItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
};

export default Sidebar;

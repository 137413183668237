import React, { useEffect, useMemo } from 'react';
import { useParams, useHistory, generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import {
  fetchMicrosites,
  fetchPageTemplates,
  fetchPages,
  fetchBios,
  updatePage,
} from '../../store';
import SiteMaker from '../../components/SiteMaker/SiteMaker';
import {
  themes,
  Button,
  Loader,
  StepCard,
  StepCardContent,
  StepCardHeader,
  StepCardTitle,
} from '@clatter/ui';
import { CustomerTeam, Section } from '@clatter/templates';
import { pageFromStore, bioFromStore } from '../fromStore';
import SiteMakerContentHead from '../../components/SiteMaker/SiteMakerContentHead';
import { getNextPage, isSiteComplete } from '../../helpers';
import BioPicker from '../../components/BioPicker/BioPicker';
import SiteMakerActions from '../../components/SiteMaker/SiteMakerActions';
import PreviewButton from '../../components/SiteMaker/PreviewButton';
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import { ThemeProvider } from 'styled-components';
import routes from '../../routes/routes';

// at the moment, the *BlockConfig pages are at urls that
// contain only the :pageId.
// so in order to set the currentMicrosite, we have to
// consult the microsites slice to determine it.
// an alternative is for us to parameterize the urls with
// /:siteid/:pageId/ so these pages have both params
const BiosBlockConfig = () => {
  const { pageId } = useParams();
  const history = useHistory();
  const { user, isAuthenticated, isLoading } = useAuth0();

  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const handleFormSubmit = async (formData) => {
    if (isDirty) {
      await dispatch(
        updatePage({
          id: currentPage.id,
          ...formData,
        }),
      ).then(() => {
        history.push(getNextPage(currentMicrosite, currentMicrosite.pages));
      });
    } else {
      history.push(getNextPage(currentMicrosite, currentMicrosite.pages));
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPages());
    dispatch(fetchMicrosites(user));
    dispatch(fetchPageTemplates());
    dispatch(fetchBios());
  }, [dispatch, user]);

  const loading = useSelector(
    (state) =>
      state.pageTemplates.loadingStatus === 'loading' ||
      state.microsites.loadingStatus === 'loading' ||
      state.pages.loadingStatus === 'loading' ||
      state.bios.loadingStatus === 'loading',
  );

  const pageTemplates = useSelector(selectAllPageTemplates);

  const bios = useSelector((state) =>
    Object.values(state.bios.entities).map(bioFromStore),
  );

  const currentMicrosite = useSelector((state) => {
    if (state.pages.ids.indexOf[pageId] === -1) {
      return;
    }

    const siteId = state.microsites.ids.find((micrositeId) =>
      state.microsites.entities[micrositeId].pages.some(
        (page) => page.id === pageId,
      ),
    );

    if (!siteId || !state.microsites.entities[siteId]) {
      return;
    }

    return {
      ...state.microsites.entities[siteId],
      pages: state.microsites.entities[siteId].pages?.map((page) =>
        pageFromStore(page, pageTemplates),
      ),
    };
  });

  const currentPage = useMemo(() => {
    if (!currentMicrosite || !bios || !bios.length) {
      return null;
    }

    const nextCurrentPage = {
      ...currentMicrosite?.pages?.find(({ id }) => id === pageId),
    };

    nextCurrentPage.bios = nextCurrentPage.bios.length
      ? nextCurrentPage.bios
          .map((item) => {
            if (typeof item === 'string') {
              return bios.find(({ _id }) => item === _id);
            }

            return item;
          })
          .filter((bio) => !!bio)
      : [];

    return nextCurrentPage;
  }, [currentMicrosite, bios, pageId]);

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }
  if (!isAuthenticated) {
    // shouldn't happen, as this component is only displayed in a ProtectedRoute
    return <div>Not Authorized.</div>;
  }

  const redirectToPublish = () => {
    history.push(generatePath(routes.publishSite, { siteId: currentMicrosite.id }));
  };

  const renderPreview = () => {
    const variables = JSON.parse(currentPage.variables);

    return (
      <ThemeProvider theme={themes[variables?.theme || 'default']}>
        <Section>
          <CustomerTeam
            bios={watch('bios')}
            customerName={currentMicrosite.client_name}
          />
        </Section>
      </ThemeProvider>
    );
  };

  const renderButtons = () => (
    <>
      <Button disabled={!isValid} type="submit">
        {isDirty ? 'Save Bios and continue' : 'Continue'}
      </Button>
      <PreviewButton
        siteName={currentMicrosite.name}
        pageName={currentPage.name}
      />
      <Button
        disabled={!isSiteComplete(currentMicrosite, currentMicrosite.pages)}
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  if (loading) {
    return <Loader />;
  }

  if (
    !loading &&
    (!currentMicrosite || !currentMicrosite.pages || !currentPage)
  ) {
    return <p>Couldn't load current page</p>;
  }

  return (
    <SiteMaker site={currentMicrosite}>
      <SiteMakerContentHead title="Select bios to include" />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <StepCard>
          <StepCardHeader step={1}>
            <StepCardTitle text="Add bios" />
          </StepCardHeader>
          <StepCardContent>
            <Controller
              name="bios"
              render={({ field: { onChange, value } }) => (
                <BioPicker onChange={onChange} options={bios} value={value} />
              )}
              control={control}
              defaultValue={currentPage.bios}
            />
          </StepCardContent>
        </StepCard>
        <SiteMakerActions
          renderPreview={renderPreview}
          renderButtons={renderButtons}
        />
      </form>
    </SiteMaker>
  );
};

export default BiosBlockConfig;

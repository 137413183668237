import routes from '../routes/routes';

const mockBlocks = {
  'Partner Site': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Bios',
      path: routes.pageBios,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
  ],
  // Prospect Landing Page is a synonym for Prospect Site
  'Prospect Landing Page': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
    {
      name: 'Videos',
      path: routes.pageVideos,
      isComplete: false,
    },
  ],
  'Prospect Site': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
    {
      name: 'Videos',
      path: routes.pageVideos,
      isComplete: false,
    },
    {
      name: 'Footer',
      path: routes.pageFooter,
      isComplete: false,
    },
  ],
  'Prospect Site UHC': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
    {
      name: 'Videos',
      path: routes.pageVideos,
      isComplete: false,
    },
    {
      name: 'Footer',
      path: routes.pageFooter,
      isComplete: false,
    },
  ],
  'Prospect Site UHC - Optum': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
    {
      name: 'Videos',
      path: routes.pageVideos,
      isComplete: false,
    },
    {
      name: 'Footer',
      path: routes.pageFooter,
      isComplete: false,
    },
  ],
  'Additional Resources': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
  ],
  'Additional Resources with videos': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Videos',
      path: routes.pageVideos,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
  ],
  'Additional Videos': [
    {
      name: 'Videos Table',
      path: routes.pageVideosTable,
      isComplete: false,
    },
  ],
  'Video Template': [
    {
      name: 'Videos Table',
      path: routes.pageVideosTable,
      isComplete: false,
    },
  ],
};

export const themePageTemplateMap = {
  'Prospect Site': {
    displayName: 'Prospect site (for Prospect and Clients)',
    theme: 'default',
    template: 'Prospect Site',
    hidden: false,
  },
  'Prospect Site UHC': {
    displayName: 'Prospect site UHC (for Prospect and Clients)',
    theme: 'uhc',
    template: 'Prospect Site UHC',
    hidden: true,
  },
  'Prospect Site UHC - Optum': {
    displayName: 'Prospect site UHC [Optum version] (for Prospect and Clients)',
    theme: 'uhc',
    template: 'Prospect Site UHC - Optum',
    hidden: false,
  },
  'Partner Site': {
    displayName: 'Partner Site (Marketing only)',
    theme: 'default',
    template: 'Partner Site',
    hidden: false,
  },
  'Partner Site Blue': {
    displayName: 'Partner Site Blue (Marketing only)',
    theme: 'blue',
    template: 'Partner Site',
    hidden: false,
  },
  'Additional Resources': {
    displayName: 'Additional Resources (Marketing only)',
    theme: 'default',
    template: 'Additional Resources',
    hidden: false,
  },
  'Additional Resources Blue': {
    displayName: 'Additional Resources Blue (Marketing only)',
    theme: 'blue',
    template: 'Additional Resources',
    hidden: false,
  },
  'Additional Resources with videos': {
    displayName: 'Additional Resources with videos (Marketing only)',
    theme: 'default',
    template: 'Additional Resources with videos',
    hidden: false,
  },
  'Additional Videos': {
    displayName: 'Additional Videos (Admin only)',
    theme: 'default',
    template: 'Additional Videos',
    hidden: true,
  },
  'Video Template': {
    displayName: 'Video Template (Admin only)',
    theme: 'default',
    template: 'Video Template',
    hidden: false,
  },
};

export default mockBlocks;

import React, { useState } from 'react';
import ResourcePicker from '../../components/ResourcePicker/ResourcePicker';
import BioPicker from '../../components/BioPicker/BioPicker';
import SingleListBioPicker from '../../components/SingleListBioPicker/SingleListBioPicker';
import {
  mockedResources,
  resourcesTableColumns,
  mockedBios,
  mockedVideos,
  videoTableColumns,
} from './mocks';
import VideoPicker from '../../components/VideoPicker/VideoPicker';
import { FormControl } from '@clatter/ui';

const ComponentsList = () => {
  const [data, setData] = useState({
    resources: [],
    bios: [],
    videos: [],
    singleBio: null,
    multipleBios: [],
  });

  const handleChange = (name) => (value) => {
    console.log('handleChange ', { name, value });
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <>
      <h2>Videos</h2>
      <VideoPicker
        onChange={handleChange('videos')}
        options={mockedVideos}
        tableColumns={videoTableColumns}
        value={data.videos}
      />
      <h2>Resources</h2>
      <ResourcePicker
        onChange={handleChange('resources')}
        options={mockedResources}
        tableColumns={resourcesTableColumns}
        value={data.resources}
      />
      <h2>Bio</h2>
      <BioPicker
        onChange={handleChange('bios')}
        options={mockedBios}
        value={data.bios}
      />
      <FormControl label="Single Bios picker">
        <SingleListBioPicker
          onChange={handleChange('singleBio')}
          options={mockedBios}
          value={data.singleBio}
        />
      </FormControl>
      <FormControl label="Multiple Bios picker">
        <SingleListBioPicker
          multiple
          onChange={handleChange('multipleBios')}
          options={mockedBios}
          value={data.multipleBios}
        />
      </FormControl>
    </>
  );
};

export default ComponentsList;

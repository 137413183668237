import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
export const RESOURCE_CATEGORIES_FEATURE_KEY = 'resourceCategories';
export const resourceCategoriesAdapter = createEntityAdapter();
const apiBaseUrl = process.env.NX_CMS_HOST;

export const fetchResourceCategories = createAsyncThunk(
  'resourceCategories/fetch',
  async () => {
    const response = await axios.get(
      `${apiBaseUrl}/resource-categories?_sort=categoryName:ASC`,
    );

    return Promise.resolve(response.data);
  },
);

export const createResourceCategory = createAsyncThunk(
  'resourceCategories/create',
  async (formData) => {
    const response = await axios.post(
      `${apiBaseUrl}/resource-categories`,
      formData,
    );

    return Promise.resolve(response.data);
  },
);

export const deleteResourceCategory = createAsyncThunk(
  'resourceCategories/delete',
  async (id) => {
    const response = await axios.delete(
      `${apiBaseUrl}/resource-categories/${id}`,
    );

    return Promise.resolve(response.data);
  },
);

export const updateResourceCategory = createAsyncThunk(
  'resourceCategories/update',
  async ({ id, formData }) => {
    const response = await axios.put(
      `${apiBaseUrl}/resource-categories/${id}`,
      formData,
    );

    return Promise.resolve(response.data);
  },
);

export const initialResourceCategoriesState = resourceCategoriesAdapter.getInitialState(
  {
    loadingStatus: 'loading',
    error: null,
  },
);

export const resourceCategoriesSlice = createSlice({
  name: RESOURCE_CATEGORIES_FEATURE_KEY,
  initialState: initialResourceCategoriesState,
  reducers: {
    add: resourceCategoriesAdapter.addOne,
    remove: resourceCategoriesAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResourceCategories.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchResourceCategories.fulfilled, (state, action) => {
        resourceCategoriesAdapter.setAll(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchResourceCategories.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(createResourceCategory.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(createResourceCategory.fulfilled, (state, action) => {
        resourceCategoriesAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(createResourceCategory.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(updateResourceCategory.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(updateResourceCategory.fulfilled, (state, action) => {
        resourceCategoriesAdapter.removeOne(state, action.payload.id);
        resourceCategoriesAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(updateResourceCategory.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(deleteResourceCategory.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(deleteResourceCategory.fulfilled, (state, action) => {
        resourceCategoriesAdapter.removeOne(state, action.payload.id);
        state.loadingStatus = 'loaded';
      })
      .addCase(deleteResourceCategory.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      });
  },
});

export const resourceCategoriesReducer = resourceCategoriesSlice.reducer;
export const resourceCategoriesActions = resourceCategoriesSlice.actions;

const { selectAll, selectEntities } = resourceCategoriesAdapter.getSelectors();

export const getResourceCategoriesState = (rootState) =>
  rootState[RESOURCE_CATEGORIES_FEATURE_KEY];

export const selectAllResourceCategories = createSelector(
  getResourceCategoriesState,
  selectAll,
);

export const selectResourceCategoriesEntities = createSelector(
  getResourceCategoriesState,
  selectEntities,
);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DataGrid as MaterialDataGrid } from '@material-ui/data-grid';
import { makeStyles, StylesProvider } from '@material-ui/core/styles';
import useSortModel from '../../hooks/useSortModel';

const StyledDataGrid = styled.div`
  .datagrid-bulk-actions {
    padding: 16px 24px;
    background-color: #fff;
    border-width: 1px 1px 0;
    border-color: #e0e0e0;
    border-style: solid;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 600,
    width: '100%',
    background: 'white',
    '& .MuiDataGrid-root': {
      fontFamily: ['Maven Pro', 'Arial', 'sans-serif'].join(','),
      fontSize: '1.0rem',
      borderRadius: 0,
    },
    '& .MuiDataGrid-colCellTitle': {
      fontWeight: 600,
    },
    '& .MuiFormGroup-options': {
      alignItems: 'center',
      paddingBottom: theme.spacing(1),
      '& > div': {
        minWidth: 100,
        margin: theme.spacing(2, 2, 2, 0),
      },
    },
  },
}));

const DataGrid = ({
  columns,
  getRowId,
  loading = false,
  name,
  onRowClick,
  renderBulkActions,
  rows,
  selectable,
}) => {
  const classes = useStyles();
  const { sortModel, handleSortModelChange } = useSortModel(name);
  const [selectionModel, setSelectionModel] = useState();

  const handleClearSelection = () => {
    setSelectionModel([]);
  };

  return (
    <StylesProvider injectFirst>
      <StyledDataGrid>
        {selectable &&
          !!selectionModel?.length &&
          typeof renderBulkActions === 'function' && (
            <div className="datagrid-bulk-actions">
              {renderBulkActions(selectionModel, handleClearSelection)}
            </div>
          )}
        <div className={classes.root}>
          <MaterialDataGrid
            autoPageSize={false}
            checkboxSelection={selectable}
            onSelectionModelChange={(nextSelectionModel) => {
              setSelectionModel(nextSelectionModel);
            }}
            selectionModel={selectionModel}
            columns={columns}
            getRowId={getRowId}
            loading={loading}
            onRowClick={onRowClick}
            onSortModelChange={handleSortModelChange}
            pageSize={25}
            pagination
            rows={rows}
            sortModel={sortModel ? [sortModel] : []}
          />
        </div>
      </StyledDataGrid>
    </StylesProvider>
  );
};

DataGrid.propTypes = {
  name: PropTypes.string.isRequired,
};

export default DataGrid;
